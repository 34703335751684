import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { fetchGet, fetchPost } from "../../toolbox/fetchlib";

import logo from "./../../assets/img/solesta-logo.png";
import {
  CustomButton,
  CustomTextField,
} from "../../components/custom_components";

const getAccountInfo = "/api/get_account_info";
const pushAcc = "/api/post_user_credentials";

const maxWidth = 210;

const styles = {
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    width: "98%",
    maxWidth: 300,
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "2vh",
  },
  new_acc_btn: {
    marginTop: "5vh",
  },
  message_sent: {
    height: 338,
    display: "flex",
    alignItems: "center",
  },
  err_msg: {
    maxWidth: 500,
    marginTop: 30,
    height: 50,
    color: "red",
  },
};

export default function Login(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const [create_err, setCreateErr] = useState(0);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [uname, setUname] = useState("");
  const [pword, setPword] = useState("");
  const [repword, setRepword] = useState("");

  useEffect(() => {
    fetchGet(
      getAccountInfo,
      (data) => {},
      (er) => {}
    );
  }, []);

  const createAccount = () => {
    if (
      name === "" ||
      uname === "" ||
      pword === "" ||
      repword === "" ||
      pword !== repword
    )
      setError(true);
    else {
      setLoading(true);
      fetchPost(
        pushAcc,
        {
          email: uname.toLowerCase(),
          password: pword,
          name: name,
          phone_number: "",
          verify_url: "monitor.solesta.nl/verify_account",
        },
        (data) => {
          setSent(true);
        },
        (er) => {
          setCreateErr(er.status);
          setLoading(false);
        }
      );
    }
  };

  return (
    <div style={styles.root}>
      <img src={logo} style={styles.logo} alt="logo" />
      <div style={styles.err_msg}>
        {create_err === 450 && (
          <Typography variant="body2">
            Wachtwoord moet minstens 8 tekens lang zijn waarvan tenminste 1
            hoofdletter, 1 cijfer en een speciaal teken.
          </Typography>
        )}
        {create_err >= 300 && create_err !== 450 && (
          <Typography variant="body2" style={{ textAlign: "center" }}>
            Account aanmaken niet gelukt. Probeer het later opnieuw.
          </Typography>
        )}
      </div>
      {!sent && (
        <div style={styles.fields}>
          <CustomTextField
            sx={{ maxWidth: maxWidth, marginBottom: 2 }}
            className={error && name === "" ? "is-invalid" : ""}
            placeholder="Naam"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <CustomTextField
            sx={{ maxWidth: maxWidth, marginBottom: 2 }}
            className={error && uname === "" ? "is-invalid" : ""}
            placeholder="Emailadres"
            type="text"
            value={uname}
            onChange={(e) => setUname(e.target.value)}
          />
          <CustomTextField
            sx={{ maxWidth: maxWidth, marginBottom: 2 }}
            className={error && pword === "" ? "is-invalid" : ""}
            placeholder="Wachtwoord"
            type="password"
            value={pword}
            onChange={(e) => setPword(e.target.value)}
          />
          <CustomTextField
            sx={{ maxWidth: maxWidth, marginBottom: 2 }}
            className={error && repword === "" ? "is-invalid" : ""}
            placeholder="Herhaal wachtwoord"
            type="password"
            value={repword}
            onChange={(e) => setRepword(e.target.value)}
          />

          <CustomButton
            variant="contained"
            color="warning"
            type="button"
            disabled={loading}
            style={{ width: "100%" }}
            onClick={() => createAccount()}
          >
            {loading ? (
              <CircularProgress style={{ width: 21, height: 21 }} />
            ) : (
              "Account aanmaken"
            )}
          </CustomButton>
        </div>
      )}
      {sent && (
        <div style={styles.message_sent}>
          Email verstuurd. Klik op de link om uw account te bevestigen.
        </div>
      )}
    </div>
  );
}
