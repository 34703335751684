import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

/**
 *
 * @param {*} options List of options to choose from, must each contain label & value
 * @param {*} label
 * @param {*} value
 * @param {*} handleChange
 * @param {*} name
 * @returns
 */

function CustomSelect({
  options,
  label,
  value,
  refValue,
  handleChange,
  name,
  sx,
  disabled,
  size,
}) {
  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    setLocalValue(refValue);
  }, []);

  if (typeof value !== "undefined")
    return (
      <FormControl
        size="small"
        sx={{ ...(sx ?? {}), minWidth: sx?.maxWidth >= 150 ? 150 : 0 }}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          sx={sx}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          size={size ?? "small"}
          label={label}
          name={name}
          disabled={disabled}
          onChange={(e) => handleChange(e)}
        >
          {options.map((opt) => (
            <MenuItem value={opt.value} dense key={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  return (
    <FormControl size="small" sx={{ minWidth: sx?.maxWidth >= 150 ? 150 : 0 }}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        sx={sx}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        size={size ?? "small"}
        value={localValue}
        name={name}
        disabled={disabled}
        onChange={(e) => handleChange(e) & setLocalValue(e.target.value)}
      >
        {options.map((opt) => (
          <MenuItem value={opt.value} dense key={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomSelect;
