import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { fetchPost } from "../../toolbox/fetchlib";
import { failedNotification } from "../../toolbox/notification-center";
import { jsonEmpty } from "../../toolbox/utils";

function MonitoringData(props) {
  const data = useSelector((state) => state?.system_data ?? []);
  const installers = useSelector(
    (state) => state?.installers?.installers ?? []
  );
  const installers_dict = useSelector(
    (state) => state?.installers?.installer_dict ?? {}
  );

  const [extra_data, setExtraData] = useState({});

  useEffect(() => {
    if (!jsonEmpty(data) && jsonEmpty(extra_data)) fetchData();
  }, [data]);

  const fetchData = () => {
    const ids = data.map((item) => item.client_id);
    fetchPost(
      "/api/get_multiple_additional_system",
      { client_ids: ids },
      (data) => {
        setExtraData(data);
      },
      (er) => {
        failedNotification("Niet gelukt", "Kan extra data niet ophalen");
      }
    );
  };

  return { data, installers, installers_dict, extra_data };
}

export default MonitoringData;
