import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { refresh_all_data } from "../../../redux/actions";
import { fetchPost } from "../../../toolbox/fetchlib";
import { failedNotification } from "../../../toolbox/notification-center";

const disconnect = "/api/disconnect_smartline";
const unlink_manual = "/api/delete_manual_installer_link";
const unlink_manual_external = "/api/delete_manual_external_link";

function useAdminFunctions() {
  const dispatch = useDispatch();

  const fetchData = () => {
    //dispatch(refresh_all_data(true));
  };

  const unlink = (client) => {
    fetchPost(
      disconnect,
      {
        client_id: client.client_id,
        id: client.user_id,
      },
      (data) => {
        fetchData();
      },
      (er) => {
        failedNotification("Unlink mislukt.", "Probeer het later opnieuw.");
      }
    );
  };

  const unlinkManual = (client, callback) => {
    fetchPost(
      unlink_manual,
      { client_id: client.client_id },
      (data) => {
        if (callback ?? null !== null) callback();
        fetchData();
      },
      (er) => {
        failedNotification("Unlink mislukt.", "Probeer het later opnieuw.");
      }
    );
  };

  const unlinkManualExternal = (client, callback) => {
    fetchPost(
      unlink_manual_external,
      { client_id: client.client_id },
      (data) => {
        if (callback ?? null !== null) callback();
        fetchData();
      },
      (er) => {
        failedNotification("Unlink mislukt.", "Probeer het later opnieuw.");
      }
    );
  };

  return { unlink, unlinkManual, unlinkManualExternal };
}

export default useAdminFunctions;
