import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { jsonEmpty } from "./../../../../toolbox/utils";
import {
  CustomButton,
  CustomDropdown,
  CustomTextField,
} from "../../../../components/custom_components";
import { useSelector } from "react-redux";
import { findByValue } from "../../../../utils/utils";

export default function ClientLinker(props) {
  const [open, setOpen] = useState(false);

  const customers = useSelector((state) => state?.cust_data ?? []);

  const [name, setName] = useState("");
  const [installer, setInstaller] = useState("");
  const [userId, setUserId] = useState("");

  useMemo(() => {
    if (userId === "") return;
    const customer = findByValue(customers, "id", userId, null);
    if (customer === null) return;
    let base = "";
    if ((customer?.zip ?? "") !== "") base += customer?.zip + " ";
    if (base !== "" && (customer?.number ?? "") !== "") base += " - ";
    if ((customer?.number ?? "") !== "") base += customer?.number;
    setName(base);
  }, [userId]);

  const saveClient = () => {
    props.save(
      {
        name,
        installer,
        client_id: props?.client?.client_id,
        id: userId,
      },
      () => handleClose()
    );
  };

  useEffect(() => {
    if (props.open && !open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.close(), 200);
  };

  console.log(props);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Wijzig klantgegevens</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {(props?.installers ?? []).length > 0 && (
            <CustomDropdown
              options={(props?.installers ?? [])
                .sort((a, b) => {
                  if (a.name < b.name) return -1;
                  return 1;
                })
                .map((inst) => {
                  return { label: inst.name, value: inst.id };
                })}
              onChange={(e) => setInstaller(e.target.value)}
              value={installer}
              style={{ width: "100%", marginBottom: 20 }}
              label="Installateur"
            />
          )}
          <CustomDropdown
            options={customers
              .sort((a, b) => {
                if (a.name < b.name) return -1;
                return 1;
              })
              .map((cust) => ({
                label: cust.name,
                value: cust.id,
              }))}
            onChange={(e) => setUserId(e.target.value)}
            value={userId}
            label="Klant"
          />
          <CustomTextField
            placeholder={"Systeemnaam"}
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <CustomButton color="primary" onClick={saveClient} size="small">
            Opslaan
          </CustomButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <CustomButton color="error" onClick={handleClose} size="small">
          Sluiten
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
