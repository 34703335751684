import React, { useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import ConfirmationScreen from "../../../../assets/components/ConfirmationScreen";
import {
  get_local,
  get_session,
  store_local,
  store_session,
} from "../../../../toolbox/local_storage";
import {
  CustomMenuButton,
  CustomTable,
} from "../../../../components/custom_components";
import { UnassignedColumns } from "./UnassignedColumns";

function filter(data, search, hidden, installers_dict) {
  return data.filter((dp) => {
    if (dp.hide !== hidden) return false;
    if (search === "") return true;
    if (
      (
        dp.client_id +
        dp.assigned +
        dp.city +
        dp.street +
        dp.number +
        dp.zip +
        (installers_dict[dp.installer] || { name: "" }).name
      )
        .toLowerCase()
        .includes(search.replace(/\s/g, "").toLowerCase())
    )
      return true;
    var k = Object.keys(dp.trace_number || {});
    for (var i = 0; i < k.length; i++)
      if (
        dp.trace_number[k[i]]
          .toLowerCase()
          .includes(search.replace(/\s/g, "").toLowerCase())
      )
        return true;
  });
}

function descendingComparator(a, b, orderBy, order) {
  if (b[orderBy] === "-") return order === "asc" ? 1 : -1;
  if (a[orderBy] === "-") return order === "asc" ? -1 : 1;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const styles = {
  trace_number: {},
  trace_cell: {
    display: "flex",
    width: 190,
    justifyContent: "space-between",
  },
};

export default function UnassignedTable(props) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState(null);
  const [confirm, setConfirm] = React.useState({
    open: false,
    client: {},
    callback: null,
  });

  useEffect(() => {
    var local_sort = get_session("systems_sort", {});
    if (local_sort !== null && typeof local_sort !== "undefined") {
      setOrder(local_sort.dir || "desc");
      setOrderBy(local_sort.key || "registered_stamp");
    } else {
      setOrder("desc");
      setOrderBy("registered_stamp");
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getInstaller = (id) => {
    for (var i = 0; i < props.installers.length; i++) {
      if (props.installers[i].id === id) {
        if (props.installers[i].name.length < 15)
          return props.installers[i].name;
        return props.installers[i].name.slice(0, 13) + "...";
      }
    }
  };

  const sortClick = (key) => {
    if (key === orderBy && order === "asc") {
      setOrder("desc");
      store_session("systems_sort", { key: key, dir: "desc" });
    } else {
      setOrderBy(key);
      setOrder("asc");
      store_session("systems_sort", { key: key, dir: "asc" });
    }
  };

  return (
    <>
      <CustomTable
        data={filter(
          stableSort(props?.data, getComparator(order, orderBy)),
          props?.search,
          props?.hidden,
          props?.installers_dict
        ).slice(
          (page - 1) * rowsPerPage,
          (page - 1) * rowsPerPage + rowsPerPage
        )}
        columns={UnassignedColumns}
        page={page}
        setPage={setPage}
        count={props?.data?.length}
        pageAmount={rowsPerPage}
        labelRenderProps={{
          sortClick,
          orderBy,
          order,
        }}
        renderProps={{
          installers_dict: props.installers_dict,
          showUserInfo: (client_id) =>
            window.open("/systeem-status#" + client_id, "_blank"),
          chartData: (a, b) => props.chartData(a, b),
          toggleHide: (a, b) => props.toggleHide(a, b),
        }}
      />
    </>
  );
}
