const default_state = {
    street: "",
    email: "",
    number: "",
    zip: "",
    city: "",
    installer: { name: "", id: "" },
    client_ids: [],
};

const customerAccountReducer = (state = null, action) => {
    switch (action.type) {
        case 'SET_CUSTOMER_ACCOUNT_INFO':
            state = { ...action.payload }
            return state;
        case 'RESET_CUSTOMER_ACCOUNT_INFO':
            return default_state;
        default:
            return state;
    }
}

export default customerAccountReducer;