import React, { useEffect, useState } from "react";
import Banner from "../../../assets/components/Banner";
import Footer from "../../../assets/components/Footer";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { fetchGet, fetchPost } from "../../../toolbox/fetchlib";
import { failedNotification } from "../../../toolbox/notification-center";
import {
  CustomButton,
  CustomDropdown,
  CustomTextField,
} from "../../../components/custom_components";
import { findByValue } from "../../../utils/utils";

const post_creds = "/api/connect_smartline",
  get_installers = "/api/get_installers";

const styles = {
  creds: {
    margin: "0 auto",
    width: "98%",
    maxWidth: 500,
  },
  creds_fields: {
    width: "100%",
    maxWidth: 300,
    margin: "30px auto 0",
  },
  nr: {
    width: "48%",
    marginRight: "2%",
    display: "inline-block",
  },
  add: {
    width: "48%",
    marginLeft: "2%",
    display: "inline-block",
  },
  system_name: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  name: {
    width: 180,
  },
  tn_fields: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tn_1: {
    width: 65,
  },
  tn_2: {
    width: 60,
  },
  tn_3: {
    width: 75,
  },
  b2: {
    fontSize: "15px !important",
  },
};

export default function BoilerSetup(props) {
  const [installers, setInstallers] = useState([]);
  const [error, setError] = useState(false);

  const [client_id, setClientId] = useState("");
  const [installer, setInstaller] = useState("");
  const [loading, setLoading] = useState(false);
  const [tn_1_col, setTn1Col] = useState("");
  const [tn_2_col, setTn2Col] = useState("");
  const [tn_3_col, setTn3Col] = useState("");
  const [tn_1_op, setTn1Op] = useState("");
  const [tn_2_op, setTn2Op] = useState("");
  const [tn_3_op, setTn3Op] = useState("");
  const [tn_1_pomp, setTn1Pomp] = useState("");
  const [tn_2_pomp, setTn2Pomp] = useState("");
  const [tn_3_pomp, setTn3Pomp] = useState("");

  useEffect(() => {
    fetchGet(
      get_installers,
      (data) => {
        setInstallers(data.installers);
      },
      (er) => {}
    );
  }, []);

  const postCreds = () => {
    if (client_id === "" || installer === "") {
      setError(true);
      return;
    }
    const inst = findByValue(installers, "id", installer);
    if (inst === null || typeof inst === "undefined") {
      failedNotification("Installer niet gevonden", "error");
      setError(true);
      return;
    }
    setLoading(true);
    fetchPost(
      post_creds,
      {
        name:
          typeof props.name !== "undefined" && props.name !== null
            ? props.name
            : "",
        client_id: client_id.includes("SOL_")
          ? client_id.replace(/\s/g, "")
          : "SOL_" + client_id.replace(/\s/g, ""),
        installer: inst?.id,
        trace_number: {
          collector:
            tn_1_col !== "" && tn_2_col !== "" && tn_3_col !== ""
              ? `${tn_1_col}/${tn_2_col}-${tn_3_col}`
              : "",
          opslag:
            tn_1_op !== "" && tn_2_op !== "" && tn_3_op !== ""
              ? `${tn_1_op}/${tn_2_op}-${tn_3_op}`
              : "",
          pomp:
            tn_1_pomp !== "" && tn_2_pomp !== "" && tn_3_pomp !== ""
              ? `${tn_1_pomp}/${tn_2_pomp}-${tn_3_pomp}`
              : "",
        },
      },
      (data) => {
        if (data.success)
          props.fetchInfo(
            client_id.includes("SOL_")
              ? client_id.replace(/\s/g, "")
              : "SOL_" + client_id.replace(/\s/g, "")
          );
        else {
          setLoading(false);
          if (data.status === 1)
            failedNotification(
              "Apparaat ID niet gevonden.",
              "Apparaat ID bestaat niet. Controleer of dit veld juist is ingevuld."
            );
          else if (data.status === 2)
            failedNotification(
              "Apparaat ID al gekoppeld.",
              "Apparaat ID al gekoppeld aan een ander apparaat. Neem contact op met Solesta."
            );
          else
            failedNotification(
              "Koppelen mislukt.",
              "Probeer het later opnieuw."
            );
        }
      },
      (er) => {
        failedNotification("Koppelen mislukt.", "Probeer het later opnieuw.");
      }
    );
  };

  return (
    <div className="full-page">
      <Banner />
      <div id="system_info">
        <div style={styles.creds}>
          <Typography variant="body1" className="b1" sx={{ marginBottom: 2 }}>
            Geen systeem gekoppeld aan uw account. Voer uw gegevens in om een
            systeem te koppelen.
          </Typography>
          <Stack spacing={2} alignItems={"center"}>
            {installers.length > 0 && (
              <CustomDropdown
                options={installers.map((inst) => {
                  return { label: inst.name, value: inst.id };
                })}
                onChange={(v) => setInstaller(v.target.value)}
                value={installer}
                color="primary"
                sx={{ width: "250px" }}
                label="Installateur"
              />
            )}
            <CustomTextField
              sx={{ marginBottom: 2 }}
              id="client_id"
              placeholder="Apparaat ID"
              type="text"
              className={error && client_id === "" ? "is-invalid" : ""}
              onChange={(e) => setClientId(e.target.value)}
              value={client_id}
            />
            <Stack spacing={1}>
              <Typography variant="body2" className={`b2`} style={styles.b2}>
                Collector (garantievoorwaarde)
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
                spacing={1}
              >
                <CustomTextField
                  placeholder="AAA"
                  type="text"
                  style={styles.tn_1}
                  onChange={(e) => setTn1Col(e.target.value)}
                  value={tn_1_col}
                />
                <Typography>/</Typography>
                <CustomTextField
                  placeholder="00"
                  type="text"
                  style={styles.tn_2}
                  onChange={(e) => setTn2Col(e.target.value)}
                  value={tn_2_col}
                />
                <Typography>-</Typography>
                <CustomTextField
                  placeholder="000000"
                  type="text"
                  style={styles.tn_3}
                  onChange={(e) => setTn3Col(e.target.value)}
                  value={tn_3_col}
                />
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" className={`b2`} style={styles.b2}>
                Opslagmodule (garantievoorwaarde)
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
                spacing={1}
              >
                <CustomTextField
                  placeholder="AAA"
                  type="text"
                  style={styles.tn_1}
                  onChange={(e) => setTn1Op(e.target.value)}
                  value={tn_1_op}
                />
                <Typography>/</Typography>
                <CustomTextField
                  placeholder="00"
                  type="text"
                  style={styles.tn_2}
                  onChange={(e) => setTn2Op(e.target.value)}
                  value={tn_2_op}
                />
                <Typography>-</Typography>
                <CustomTextField
                  placeholder="000000"
                  type="text"
                  style={styles.tn_3}
                  onChange={(e) => setTn3Op(e.target.value)}
                  value={tn_3_op}
                />
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" className={`b2`} style={styles.b2}>
                Pompmodule (garantievoorwaarde)
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
                spacing={1}
              >
                <CustomTextField
                  placeholder="AAA"
                  type="text"
                  style={styles.tn_1}
                  onChange={(e) => setTn1Pomp(e.target.value)}
                  value={tn_1_pomp}
                />
                <Typography>/</Typography>
                <CustomTextField
                  placeholder="00"
                  type="text"
                  style={styles.tn_2}
                  onChange={(e) => setTn2Pomp(e.target.value)}
                  value={tn_2_pomp}
                />
                <Typography>-</Typography>
                <CustomTextField
                  placeholder="000000"
                  type="text"
                  style={styles.tn_3}
                  onChange={(e) => setTn3Pomp(e.target.value)}
                  value={tn_3_pomp}
                />
              </Stack>
            </Stack>
            <CustomButton
              variant="contained"
              color="warning"
              type="button"
              style={{ width: "200px", marginTop: 20 }}
              onClick={() => postCreds()}
            >
              {loading ? (
                <CircularProgress style={{ width: 21, height: 21 }} />
              ) : (
                "Koppelen"
              )}
            </CustomButton>
          </Stack>
        </div>
      </div>
      <Footer logout_btn />
    </div>
  );
}
