import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";

function TableWrapper(props) {
  return (
    <TableContainer
      component={props.noPaper ? null : Paper}
      sx={{ ...props.sx }}
    >
      <Container maxWidth={false}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ paddingTop: 4, paddingBottom: 3 }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: "1rem" }}>
            {props.title}
          </Typography>
          {props.elements}
        </Stack>
        {props.children}
      </Container>
    </TableContainer>
  );
}

export default TableWrapper;
