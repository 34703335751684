import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { queryGet } from "../../../toolbox/queryfetch";
import { fetchPost } from "../../../toolbox/fetchlib";
import { useSelector } from "react-redux";
import {
  failedNotification,
  successNotification,
} from "../../../toolbox/notification-center";

function ClientsNewModel() {
  const installers = useSelector(
    (state) => state?.installers?.installers ?? []
  );

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [hasSystem, setHasSystem] = useState("all");

  const [searchParams, setSearchParams] = useSearchParams({ search: "" });

  const search = useMemo(
    () => searchParams.get("search") ?? "" & setPage(1),
    [searchParams]
  );

  const [debouncedSearch, loading] = useDebounce(search, 700);

  const { data, status, refetch } = useQuery({
    queryKey: ["get_clients_table", { debouncedSearch, page, hasSystem }],
    queryFn: () => getClientsTable(debouncedSearch, page, hasSystem),
  });

  const clients = useMemo(() => data?.clients ?? [], [data]);
  const count = useMemo(() => data?.count ?? 0, [data]);

  const createNewClient = (data, cb) => {
    fetchPost(
      "/api/post_manual_user_credentials",
      data,
      (resp) => {
        refetch();
        cb();
      },
      (err) => {}
    );
  };

  const savePw = (pw, id) => {
    fetchPost(
      "/api/change_password_external",
      { new_password: pw, id: id },
      (data) => {
        successNotification("Wachtwoord gewijzigd", "");
      },
      (er) => {
        if (er.status === 450)
          failedNotification(
            "Niet gelukt",
            "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer."
          );
        else failedNotification("Niet gelukt", "Probeer het later opniew.");
      }
    );
  };

  const updateClient = (client) => {
    fetchPost(
      "/api/post_user_info",
      client,
      (data) => {
        //dispatch(refresh_all_data(true));
        refetch();
      },
      (er) => {
        failedNotification(
          "Opslaan mislukt",
          "Kan nu niet opslaan. Probeer het later opnieuw."
        );
      }
    );
  };

  const saveEmail = (pw, id) => {
    fetchPost(
      "/api/change_email_external",
      { new_email: pw, id: id },
      (data) => {
        successNotification("Email gewijzigd", "");
        refetch();
      },
      (er) => {
        if (er.status === 450)
          failedNotification(
            "Niet gelukt",
            "Email adres bestaat al of is niet geldig."
          );
        else
          failedNotification(
            "Niet gelukt",
            "Email adres bestaat al of is niet geldig."
          );
      }
    );
  };

  const deleteUser = (id) => {
    fetchPost(
      "/api/delete_users",
      { ids: [id] },
      () => {
        refetch();
      },
      (er) =>
        failedNotification("Niet gelukt", "Kon gebruiker niet verwijderen")
    );
  };

  return {
    clients,
    count,
    loading: status === "loading" || loading,
    searchParams,
    setSearchParams,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    createNewClient,
    hasSystem,
    setHasSystem,
    installers,
    saveEmail,
    savePw,
    updateClient,
    deleteUser,
  };
}

export default ClientsNewModel;

//get_clients_table
function getClientsTable(search, page, has_system) {
  return queryGet("get_clients_table", { search, page, has_system });
}
