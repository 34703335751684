const default_state = [];

const systemDataReducer = (state = default_state, action) => {
  switch (action.type) {
    case "SET_SYSTEM_DATA":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default systemDataReducer;
