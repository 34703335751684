import { Box, Card, CardActionArea, Stack, Typography } from "@mui/material";
import { pogona_colors } from "../../assets/themes/pogona_colors";

const color_map = {
  orange: pogona_colors.orange,
  purple: pogona_colors.royal_purple_80,
  dark: pogona_colors.dark_purple,
  yellow: pogona_colors.yellow,
  red: pogona_colors.red,
};

const iconSize = 72;

const styles = {
  width: "100%",
  height: "100%",
  pt: 2.5,
  pb: 0.5,
  px: 3.5,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
};

function LinkCard({
  paperProps,
  onClick = null,
  title,
  children = null,
  titleWidth = 100,
  icon,
  color = "orange",
}) {
  return (
    <Card
      elevation={0}
      sx={{
        border: "1px solid #DBDBDB",
        height: 190,
        position: "relative",
        ...(paperProps?.sx ?? {}),
      }}
      {...paperProps}
    >
      {(onClick ?? null) !== null ? (
        <CardActionArea sx={styles} onClick={onClick}>
          <CardContent
            {...{ title, children, titleWidth, icon, color }}
            isLink
          />
        </CardActionArea>
      ) : (
        <Box sx={styles}>
          <CardContent
            {...{ title, children, titleWidth, icon, color }}
            isLink={false}
          />
        </Box>
      )}
    </Card>
  );
}

export default LinkCard;

const CardContent = ({
  title,
  children = null,
  titleWidth = 100,
  icon,
  color = "orange",
  isLink,
}) => (
  <>
    {children !== null && (
      <Typography
        variant="h6"
        sx={{ position: "absolute", top: 100, left: 26, color: "#888888" }}
      >
        {children}
      </Typography>
    )}
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ width: "100%" }}
    >
      <Typography
        variant="h3"
        fontWeight="800"
        fontSize="1.2rem"
        textTransform="uppercase"
        sx={{ width: titleWidth }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: iconSize,
          height: iconSize,
          borderRadius: 1,
          backgroundColor: color_map[color],
          borderBottomLeftRadius: 22,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
    </Stack>
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      justifyContent={isLink ? "space-between" : "flex-start"}
      alignItems="center"
    >
      <Box
        sx={{
          width: 44,
          height: 4,
          backgroundColor: color_map[color],
          borderRadius: 1,
          mb: isLink ? 0 : 2.9,
        }}
      />
    </Stack>
  </>
);
