import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomButton } from "../../components/custom_components";

function ConfirmDialog(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.dial_open && !open) setOpen(true);
  }, [props.dial_open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.close(), 200);
  };

  const handleConfirm = () => {
    props.onConfirm();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <CustomButton color="error" size="small" onClick={handleClose}>
          Annuleren
        </CustomButton>
        <CustomButton size="small" onClick={handleConfirm}>
          Bevestigen
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
