import { useRef, useState } from "react";
import { pogona_colors } from "../../assets/themes/pogona_colors";
import DashboardButton from "./DashboardButton";
import CustomIconButton from "./CustomIconButton";
import { Stack, Typography } from "@mui/material";
import { jsonEmpty } from "../../utils/utils";

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50000000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const styles = {
  section: {
    position: "relative",
    margin: "25px 0 15px",
    border: `1px solid ${pogona_colors.orange}`,
    padding: "35px 20px",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    "&:hover": {
      cursor: "pointer",
      brightness: 0.95,
    },
  },
  input: {
    fontSize: 18,
    display: "block",
    width: "100%",
    border: "none",
    textTransform: "none",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,

    "&:hover": {
      cursor: "pointer",
    },

    "&:focus": {
      outline: "none",
    },
  },
  label: {
    top: -21,
    fontSize: 13,
    color: "black",
    left: 0,
    position: "absolute",
  },
};

const CustomDragNDrop = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size < maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    setFiles((old) => {
      delete old[fileName];
      return { ...old };
    });

    //callUpdateFilesCb({ ...files });
  };

  return (
    <>
      <section style={styles.section}>
        <label style={styles.label}>{label}</label>
        <Typography
          variant="body1"
          color="#777777"
          fontWeight="500"
          fontSize=".95rem"
          textAlign={"center"}
        >
          Drag and drop your files or click to select
        </Typography>

        <input
          style={styles.input}
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </section>

      <article>
        <Typography
          fontWeight="600"
          variant="body2"
          color="#888888"
          sx={{ marginBottom: 2 }}
        >
          To Upload
        </Typography>

        {jsonEmpty(files) ? (
          <Typography variant="body2">No files selected</Typography>
        ) : (
          <section>
            {Object.keys(files).map((fileName, index) => {
              let file = files[fileName];
              return (
                <section key={fileName}>
                  <div>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography variant="body2">{file.name}</Typography>
                      <aside>
                        <CustomIconButton
                          type="clear"
                          color="secondary"
                          onClick={() => removeFile(fileName)}
                        />
                      </aside>
                    </Stack>
                  </div>
                </section>
              );
            })}
          </section>
        )}
      </article>
      {(otherProps?.existingFiles ?? []).length > 0 && (
        <article>
          <Typography
            fontWeight="600"
            variant="body2"
            color="#888888"
            sx={{ marginBottom: 2 }}
          >
            Uploaded earlier
          </Typography>
          <section>
            {(otherProps?.existingFiles ?? []).map((file, index) => {
              return (
                <section key={index}>
                  <div>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography variant="body2">
                        {file?.document_name ?? "bla"}
                      </Typography>
                      <aside></aside>
                    </Stack>
                  </div>
                </section>
              );
            })}
          </section>
        </article>
      )}
    </>
  );
};

export default CustomDragNDrop;
