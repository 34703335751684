import moment from "moment";

/**
 * Check if given json is empty
 * @param {*} input
 * @returns
 */
export const jsonEmpty = (input) => {
  if (input === null || typeof input === "undefined") return false;
  return Object.keys(input).length === 0 && input.constructor === Object;
};

/**
 * Find object in list of objects by value of given key
 * @param {*} list Input list
 * @param {*} key Key that yields value
 * @param {*} value Value that is looked for
 * @param {*} def Default value in case key is not found (optional)
 */
export const findByValue = (list, key, value, def) => {
  for (let i = 0; i < list?.length; i++)
    if (list[i][key] === value) return list[i];
  if (typeof def !== "undefined") return def;
  return {};
};

/**
 * Get dimensions of image from url and use these dimensions in a callback method
 * @param {*} url
 */
export const getImageDimensions = (url, callback) => {
  var img = new Image();
  img.onload = function () {
    callback(this.width, this.height);
  };
  img.src = url;
};

export const isInt = (str) => {
  if (isNaN(parseInt(str))) return false;
  return true;
};

export const isFloat = (str) => {
  if (isNaN(parseFloat(str))) return false;
  return true;
};

export const unixToDateAndTime = (unix) => {
  let date = new Date(unix * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${day}-${month}-${year} ${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`; // :${seconds < 10 ? "0" : ""}${seconds}
};

/**
 *
 * @param {*} stamp Unix timestamp
 * @returns
 */
export const unixToDate = (stamp) => {
  if (stamp === "" || stamp === 0 || stamp === "0") return "";
  if (!isInt(stamp)) {
    return stamp ?? "";
  }
  var date = new Date(stamp * 1000);
  date.toLocaleString("en-GB", {
    timeZone: "Europe/Amsterdam",
  });

  var year = date.toLocaleString("default", { year: "numeric" });
  var month = date.toLocaleString("default", { month: "2-digit" });
  var day = date.toLocaleString("default", { day: "2-digit" });

  const full_date = `${day}-${month}-${year}`;
  return full_date;
};

export const dateToUnix = (date) => {
  if (date === "" || date === 0 || date === "0") return 0;
  var dateMomentObject = moment(date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
  var dateObject = dateMomentObject.toDate();
  return Math.round(dateObject.getTime() / 1000);
};
