import { Typography } from "@mui/material";

function CustomLabel(props) {
  return (
    <Typography
      fontWeight={props?.fontWeight ?? "400"}
      variant={props?.labelVariant ?? "body2"}
      color="#888888"
      {...props}
    >
      {props.children}
    </Typography>
  );
}

export default CustomLabel;
