const default_state = [];

const topicsReducer = (state = default_state, action) => {
  switch (action.type) {
    case "SET_TOPICS":
      state = [...action.payload];
      return state;
    default:
      return state;
  }
};

export default topicsReducer;
