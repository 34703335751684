import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  reset_origin,
  set_cust_acc_info,
  set_user_info,
} from "./redux/actions";
import { fetchGet } from "./toolbox/fetchlib";

const getAccountInfo = "/api/get_account_info";
const customerAccInfo = "/api/customer_account_info";

export default function Main(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const origin = useSelector((state) => state.origin);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchGet(
      getAccountInfo,
      (data) => {
        setLoading(false);
        dispatch(set_user_info(data));
        if (data.user_type === "customer") getCustomerAccountInfo();
        else if (origin !== "") {
          dispatch(reset_origin());
          navigate("/" + origin);
        } else if (data.user_type === "admin") navigate("/admin");
        else if (data.user_type === "installer") navigate("/installer");
        else if (data.user_type === "external") navigate("/external");
      },
      (data, status) => {
        setLoading(false);
        navigate(`/login`);
      }
    );
  }, []);

  const getCustomerAccountInfo = () => {
    fetchGet(
      customerAccInfo,
      (data) => {
        dispatch(set_cust_acc_info(data));
        if (origin !== "") {
          dispatch(reset_origin());
          navigate("/" + origin);
        } else navigate("/systeem-status");
      },
      (er) => {}
    );
  };

  return <div></div>;
}
