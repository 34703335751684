function descendingComparator(a, b, orderBy, order) {
  let var_a =
    typeof a[orderBy] !== "undefined" ? (a[orderBy] + "").replace(" ", "") : "";
  let var_b =
    typeof b[orderBy] !== "undefined" ? (b[orderBy] + "").replace(" ", "") : "";
  if (var_b === "") return order === "asc" ? 1 : -1;
  if (var_a === "") return order === "asc" ? -1 : 1;

  if (var_b < var_a) {
    return -1;
  }
  if (var_b > var_a) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
