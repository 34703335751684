import React, { useEffect, useState } from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {
  CustomButton,
  CustomDatepicker,
} from "../../../../components/custom_components";
import { Stack } from "@mui/material";
import TabTopBar from "../../../../assets/components/TabTopBar";

export function Toolbar(props) {
  const [state, setState] = useState({});

  useEffect(() => {
    setState({
      startDate: moment.unix(props.startDate),
      endDate: moment.unix(props.endDate),
    });
  }, []);

  return (
    <TabTopBar
      title="Systeeminformatie"
      subtitle={`Systeem ${props.client_id}`}
      breadcrumbs={[{ label: "Systemen", link: "systemen-nieuw" }]}
    >
      {props.userType === "admin" && (
        <Stack direction="row" spacing={2}>
          <CustomDatepicker
            value={state.startDate}
            onChange={(e) =>
              setState({ ...state, startDate: e }) &
              props.setDates(e, state.endDate)
            }
          />

          <CustomDatepicker
            value={state.endDate}
            onChange={(e) =>
              setState({ ...state, endDate: e }) &
              props.setDates(state.startDate, e)
            }
          />

          <CustomButton
            color="primary"
            type="button"
            onClick={() => props.updateData()}
          >
            Update
          </CustomButton>
        </Stack>
      )}
    </TabTopBar>
  );
}
