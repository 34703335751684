import React from "react";
import logo from "./../img/solesta-logo-white.png";
import collector from "./../img/collector.png";
import "./style/Banner.scss";

export default function Banner(props) {
  return (
    <div className="banner">
      <div className="banner-img">
        <div className="banner-layer">
          <img src={logo} alt="" />
        </div>
      </div>
      <img src={collector} alt="" className="banner-col" />
    </div>
  );
}
