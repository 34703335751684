import React from "react";
import "./style/Footer.scss";
import { Button } from "@mui/material";
import { fetchPost } from "../../toolbox/fetchlib";
import { useNavigate } from "react-router";
import { CustomButton } from "../../components/custom_components";

const logout_call = "/api/logout";

export default function Footer(props) {
  const navigate = useNavigate();

  const logout = () => {
    fetchPost(
      logout_call,
      {},
      (data) => {
        navigate("/login");
      },
      (er) => {}
    );
  };

  return (
    <div id="footer">
      <div id="footer-content">
        <CustomButton
          sx={{ marginRight: "1rem" }}
          variant="contained"
          className="footer-btn"
          type="button"
          onClick={() =>
            window
              .open("https://service.solesta.nl/smartline/", "_blank")
              .focus()
          }
        >
          Informatie
        </CustomButton>
        <CustomButton
          className="footer-btn"
          variant="contained"
          type="button"
          onClick={() =>
            props.logout_btn
              ? logout()
              : "changeUserInfo" in props
              ? props.changeUserInfo()
              : ""
          }
        >
          {props.logout_btn
            ? "Uitloggen"
            : props.back
            ? "Mijn systeem"
            : "Mijn profiel"}
        </CustomButton>
      </div>
    </div>
  );
}
