import React, { useMemo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HelpIcon from "@mui/icons-material/Help";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SendIcon from "@mui/icons-material/Send";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import PasswordIcon from "@mui/icons-material/Password";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const button_map = (type, size) => {
  const style = {
    fontSize: size === "small" ? "1.15rem" : "unset",
  };
  const buttons = {
    add: <AddIcon style={style} />,
    invoice: <ReceiptIcon style={style} />,
    edit: <EditIcon style={style} />,
    delete: <DeleteIcon style={style} />,
    menu: <MoreVertIcon style={style} />,
    eyes: <VisibilityIcon style={style} />,
    clear: <ClearIcon style={style} />,
    check: <PlaylistAddCheckIcon style={style} />,
    dark: <DarkModeIcon style={style} />,
    light: <LightModeIcon style={style} />,
    settings: <SettingsIcon style={style} />,
    list: <FormatListBulletedIcon style={style} />,
    camera: <PhotoCameraIcon style={style} />,
    collapse: <ExpandMoreIcon style={style} />,
    notification: <NotificationsOutlinedIcon style={style} />,
    logout: <LogoutOutlinedIcon style={style} />,
    download: <DownloadIcon style={style} />,
    driver: <SportsMotorsportsIcon style={style} />,
    copy: <ContentCopyIcon style={style} />,
    filter: <FilterAltIcon style={style} />,
    chat: <ChatOutlinedIcon style={style} />,
    send: <SendIcon style={style} />,
    password: <PasswordIcon style={style} />,
    attach: <AttachFileIcon style={style} />,
    chart: <ShowChartIcon style={style} />,
    back: <ArrowBackIcon style={style} />,
    next: <ArrowForwardIcon style={style} />,
  };
  return buttons[type];
};

/**
 *
 * @param {*} type - edit, delete, menu, eyes, clear, check, dark, light, settings, logout, list
 * @param {} size - Same as MUI
 * @param {} fwdProps - Same as MUI
 * @param {} tooltip - Tooltip text (optional)
 * @param {} disableHover - Boolean if you want to disable hover effect (optional)
 * @returns
 */
export default function CustomIconButton(props) {
  const theme = useTheme();

  const fwdProps = useMemo(() => {
    let fwd = { ...props, theme };
    delete fwd.disableHover;
    delete fwd.badgeColor;
    return fwd;
  }, [props]);

  if ("badge" in fwdProps) return <BadgeButton {...fwdProps} />;
  if (fwdProps.disabled || !("tooltip" in fwdProps)) {
    if ("link" in fwdProps)
      return (
        <Link href={fwdProps.link} passHref>
          <DefaultButton {...fwdProps} />
        </Link>
      );
    return <DefaultButton {...fwdProps} />;
  }
  if ("link" in fwdProps)
    return (
      <Tooltip title={fwdProps.tooltip}>
        <Link href={fwdProps.link} passHref>
          <DefaultButton {...fwdProps} />
        </Link>
      </Tooltip>
    );

  if ("tooltip" in fwdProps)
    return (
      <Tooltip title={fwdProps.tooltip}>
        <DefaultButton {...fwdProps} />
      </Tooltip>
    );
  return <DefaultButton {...fwdProps} />;
}

const getProps = (fwdProps) => {
  let props = { ...fwdProps };
  delete props.disableHover;
  delete props.badgeColor;
  delete props.tooltip;
  delete props.theme;
  return props;
};

const BadgeButton = React.forwardRef((fwdProps, ref) => (
  <IconButton
    {...getProps(fwdProps)}
    size={fwdProps.size || "small"}
    ref={ref}
    sx={{
      "&:hover": {
        backgroundColor: fwdProps.disableHover ? "rgba(0,0,0,0)" : "",
      },
    }}
  >
    <Badge
      badgeContent={fwdProps.badge}
      color={fwdProps.badgeColor ?? "primary"}
    >
      {button_map(
        fwdProps.type === "theme"
          ? fwdProps.theme.palette.mode === "dark"
            ? "light"
            : "dark"
          : fwdProps.type,
        fwdProps.size || "small"
      )}
    </Badge>
  </IconButton>
));

const DefaultButton = React.forwardRef((fwdProps, ref) => (
  <IconButton
    {...getProps(fwdProps)}
    size={fwdProps.size || "small"}
    ref={ref}
    sx={{
      ...(fwdProps?.sx ?? {}),
      "&:hover": {
        backgroundColor: fwdProps.disableHover ? "rgba(0,0,0,0)" : "",
      },
    }}
  >
    {button_map(
      fwdProps.type === "theme"
        ? fwdProps.theme.palette.mode === "dark"
          ? "light"
          : "dark"
        : fwdProps.type,
      fwdProps.size || "small"
    )}
  </IconButton>
));
