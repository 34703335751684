import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryGet } from "../../../toolbox/queryfetch";
import useDebounce from "../../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import { fetchPost } from "../../../toolbox/fetchlib";

function SystemsModel() {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("registered");
  const [hidden, setHidden] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({
    search: "",
    page: 1,
  });

  const search = useMemo(
    () =>
      searchParams.get("search") ??
      "" &
        setSearchParams(
          (prev) => {
            prev.set("page", 1);
            return prev;
          },
          { replace: true }
        ),
    [searchParams]
  );

  const page = useMemo(() => searchParams.get("page") ?? 1, [searchParams]);

  const [debouncedSearch, loading] = useDebounce(search, 700);

  const { data, status, refetch } = useQuery({
    queryKey: [
      "get_systems_table",
      { debouncedSearch, page, order, orderBy, hidden },
    ],
    queryFn: () =>
      getSystemsTable(debouncedSearch, page, order, orderBy, hidden),
  });

  const systems = useMemo(() => data?.systems ?? [], [data]);
  const count = useMemo(() => data?.count ?? 0, [data]);

  const toggleHide = (id, hide) => {
    fetchPost(
      "/api/hide_client_ids",
      { client_ids: { [id]: hide } },
      (data) => {
        refetch();
      },
      (er) => {}
    );
  };

  return {
    systems,
    count,
    loading: status === "loading" || loading,
    searchParams,
    setSearchParams,
    page,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    toggleHide,
    hidden,
    setHidden,
  };
}

export default SystemsModel;

function getSystemsTable(search, page, order, order_by, show_hidden) {
  return queryGet("get_systems_table", {
    search,
    page,
    order,
    order_by,
    show_hidden,
  });
}
