import React, { useEffect, useState } from "react";
import { failedNotification } from "../../../../toolbox/notification-center";
import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CustomSelect from "../../../../assets/components/CustomSelect";
import {
  CustomButton,
  CustomDropdown,
} from "../../../../components/custom_components";
import { findByValue } from "../../../../utils/utils";

export default function ExternalLinker(props) {
  const [exter, setExter] = useState(-1);
  const [open, setOpen] = useState(false);

  const save = () => {
    if (exter !== -1) {
      const e = findByValue(props.external, "id", exter);
      var args = {
        client_id: props.client_id,
        external: e?.id,
      };
      props.save(args);
    } else {
      failedNotification("Niet gelukt.", "Selecteer een installateur.");
    }
  };

  useEffect(() => {
    if (props.open && !open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.close(), 200);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Extern koppelen</DialogTitle>
      <DialogContent>
        {props.external.length > 0 && (
          <CustomDropdown
            options={props.external.map((ext) => {
              return { label: ext.name, value: ext.id };
            })}
            onChange={(e) => setExter(e.target.value)}
            value={exter}
            style={{ width: "100%", marginBottom: 20 }}
            label="Extern"
          />
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="error" onClick={handleClose} size="small">
          Annuleren
        </CustomButton>
        <CustomButton color="primary" onClick={save} size="small">
          Opslaan
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
