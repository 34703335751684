const default_state = {
  installer: [],
  installer_dict: {},
};

const installerReducer = (state = default_state, action) => {
  switch (action.type) {
    case "SET_INSTALLER":
      state = { ...action.payload };
      return state;
    default:
      return state;
  }
};

export default installerReducer;
