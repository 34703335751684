import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { pogona_colors } from "../../assets/themes/pogona_colors";

const dropdownStyles = {
  padding: 0.5,
  "& .MuiSvgIcon-root": { color: pogona_colors.orange },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e6e6e6",
    },
    "&.Mui-hover fieldset": {
      borderColor: "pink",
    },
    "&.Mui-focused fieldset": {
      borderColor: `${pogona_colors.orange}80`,
    },
  },
};

const acStyles = {
  "&:hover": { borderColor: "red" },
  "& .MuiSvgIcon-root": { color: pogona_colors.orange },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e6e6e6",
    },
    "&.Mui-focused fieldset": {
      borderColor: `${pogona_colors.orange}80`,
    },
  },
};

/**
 *
 * @param {*} options List of options to choose from, must each contain label & value
 * @param {*} label
 * @param {*} value
 * @param {*} handleChange Has e as attribute with target; name and value
 * @param {*} onChange (Same as handleChange, can do both)
 * @param {*} name
 * @returns
 */

function RegularCustomSelect({
  options,
  label,
  value,
  refValue,
  handleChange,
  onChange,
  name,
  sx,
  disabled,
  InputProps,
  fullWidth,
}) {
  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    setLocalValue(refValue);
  }, []);

  const handleLocalChange = (e) => {
    if (typeof onChange !== "undefined") {
      onChange(e);
      return;
    }
    handleChange(e);
  };

  if (typeof value !== "undefined")
    return (
      <FormControl
        size="small"
        sx={{
          ...(sx ?? {}),
          "&:hover": { borderColor: "red" },
          minWidth: sx?.maxWidth >= 150 ? 150 : 0,
          width: fullWidth ? "100%" : "auto",
        }}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          sx={{
            ...dropdownStyles,
            width: fullWidth ? "100%" : "auto",
            ...(sx ?? {}),
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          name={name}
          disabled={disabled}
          onChange={(e) => handleLocalChange(e)}
          IconComponent={ExpandMoreIcon}
        >
          {options.map((opt) => (
            <MenuItem value={opt.value} dense key={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  return (
    <FormControl
      size="small"
      sx={{
        minWidth: sx?.maxWidth >= 150 ? 150 : 0,
        width: fullWidth ? "100%" : "auto",
      }}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        sx={{
          ...dropdownStyles,
          width: fullWidth ? "100%" : "auto",
          ...(sx ?? {}),
        }}
        InputProps={InputProps}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={label}
        value={localValue}
        name={name}
        disabled={disabled}
        onChange={(e) => handleLocalChange(e) & setLocalValue(e.target.value)}
      >
        {options.map((opt) => (
          <MenuItem value={opt.value} dense key={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function SearchSelect({
  options,
  label,
  value,
  refValue,
  handleChange,
  onChange,
  name,
  sx,
  disabled,
  InputProps,
  fullWidth,
}) {
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ ...acStyles, ...sx }}
      options={options}
      autoHighlight
      disabled={disabled}
      getOptionLabel={(option) => option.label ?? ""}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography variant="body2">{option.label}</Typography>
        </Box>
      )}
      //value={value}
      onChange={(e, n) =>
        onChange({ target: { value: n !== null ? n.value : "", name } })
      }
      name={name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          disabled={disabled}
          fullWidth={fullWidth}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

function CustomSelect({
  search,
  options,
  label,
  value,
  refValue,
  handleChange,
  onChange,
  name,
  sx,
  disabled,
  InputProps,
  fullWidth,
}) {
  if (search)
    return (
      <SearchSelect
        options={options}
        label={label}
        value={value}
        refValue={refValue}
        handleChange={handleChange}
        onChange={onChange}
        name={name}
        sx={sx}
        disabled={disabled}
        InputProps={InputProps}
        fullWidth={fullWidth}
      />
    );

  return (
    <RegularCustomSelect
      options={options}
      label={label}
      value={value}
      refValue={refValue}
      handleChange={handleChange}
      onChange={onChange}
      name={name}
      sx={sx}
      disabled={disabled}
      InputProps={InputProps}
      fullWidth={fullWidth}
    />
  );
}

export default CustomSelect;
