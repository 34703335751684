import {
  Autocomplete,
  Avatar,
  Box,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { pogona_colors } from "../../assets/themes/pogona_colors";

const acStyles = {
  "&:hover": { borderColor: "red" },
  "& .MuiSvgIcon-root": { color: pogona_colors.orange },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e6e6e6",
    },
    "&.Mui-focused fieldset": {
      borderColor: `${pogona_colors.orange}80`,
    },
  },
};

/**
 *
 * @param {*} value
 * @param {*} onChange
 * @param {*} label
 * @param {*} placeholder
 * @param {*} clear
 * @param {*} name
 * @param {*} options
 * @returns
 */

function CustomAutocomplete({
  value,
  onChange,
  label,
  name = "",
  options,
  clear,
  placeholder,
  newHc = false,
  disabled,
  width,
  fullWidth,
  search,
}) {
  return (
    <Autocomplete
      disablePortal
      autoHighlight
      freeSolo
      value={value}
      onChange={(e, n) => {
        if (e.type === "click" && n === null && typeof clear !== "undefined")
          clear();
      }}
      onInputChange={
        newHc
          ? (e, n) => onChange({ target: { value: n, name } })
          : (e, n) => onChange(n, name)
      }
      options={options}
      sx={{ ...acStyles, width: fullWidth ? "100%" : width ?? 150 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? ""}
          placeholder={placeholder ?? ""}
          disabled={disabled ?? false}
          InputProps={
            search
              ? {
                  ...params.InputProps,
                  type: "search",
                }
              : { ...params.InputProps }
          }
        />
      )}
      renderOption={(props, option, { selected }) => {
        if (option !== "" && props?.["data-option-index"] <= 50)
          return (
            <MenuItem dense {...props}>
              {option}
            </MenuItem>
          );
      }}
    />
  );
}

export default CustomAutocomplete;
