import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { fetchPost } from "../../toolbox/fetchlib";

const logout = "/api/logout";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    fetchPost(
      logout,
      {},
      (data) => {
        navigate("/login");
      },
      (er) => {}
    );
  }, []);

  return <div></div>;
}
