import React, { useEffect, useState } from "react";
import { fetchGet, fetchPost } from "../../toolbox/fetchlib";
import "./boiler_status/style/BoilerStatus.scss";
import BoilerStatus from "./boiler_status/BoilerStatus";
import BoilerLoading from "./boiler_status/BoilerLoading";
import BoilerSetup from "./boiler_status/BoilerSetup";
import BoilerDisconnected from "./boiler_status/BoilerDisconnected";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useNavigate } from "react-router";
import { set_cust_acc_info, set_origin } from "../../redux/actions";
import BoilerAccount from "./boiler_status/BoilerAccount";
import InfoEditor from "./boiler_status/InfoEditor";

const getSystemInfo = "/api/get_system_info",
  postUser = "/api/post_user_info";
const customerAccInfo = "/api/customer_account_info";

export default function SystemInfo(props) {
  const user_info = useSelector((state) => state.user_info);
  const customer_info = useSelector((state) => state.customer_info);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [info, setInfo] = useState({
    street: "",
    number: "",
    zip: "",
    city: "",
  });
  const [system_info, setSystemInfo] = useState({});
  const [screen, setScreen] = useState("loading");
  const [name, setName] = useState("");

  useEffect(() => {
    var url = window.location.href,
      id = "";
    var index = url.indexOf("#");
    if (index >= 0) id = url.substring(index + 1, url.length);

    if (!user_info.initialized) {
      dispatch(set_origin("systeem-status" + (id === "" ? "" : `#${id}`)));
      navigate("/");
    } else if (
      user_info.user_type === "admin" ||
      user_info.user_type === "installer"
    ) {
      fetchInfo(id);
    } else {
      if (user_info.user_type === "customer" && customer_info === null) {
        dispatch(set_origin("systeem-status" + (id === "" ? "" : `#${id}`)));
        navigate("/");
      } else if (customer_info.city === null) setScreen("account_change");
      else {
        if (customer_info.client_ids.length > 0)
          fetchInfo(customer_info.client_ids[0].client_id);
        else setScreen("setup");
      }
    }
  }, []);

  useEffect(() => {
    if (customer_info !== null)
      setInfo({
        street: customer_info.street || "",
        number: customer_info.number || "",
        zip: customer_info.zip || "",
        city: customer_info.city || "",
      });
  }, [customer_info]);

  const fetchAccInfo = (no_fetch) => {
    fetchGet(
      customerAccInfo,
      (data) => {
        dispatch(set_cust_acc_info(data));
        if (customer_info.client_ids.length && !no_fetch)
          fetchInfo(customer_info.client_ids[0].client_id);
      },
      (er) => {}
    );
  };

  const fetchInfo = (id) => {
    setScreen("loading");
    fetchGet(
      `${getSystemInfo}?client_id=${id}`,
      (data) => {
        setSystemInfo(data);
        setScreen("");
      },
      (er) => {
        setScreen("setup");
      }
    );
  };

  const dataChanged = (name) => {
    setName(name);
    setScreen("setup");
  };

  const saveClient = (v) => {
    fetchPost(
      postUser,
      v,
      (data) => {},
      (er) => {}
    );
  };

  if (screen === "loading") return <BoilerLoading />;
  else if (screen === "account_change")
    return <BoilerAccount dataChanged={dataChanged.bind(this)} />;
  else if (screen === "setup")
    return <BoilerSetup fetchInfo={(id) => fetchInfo(id)} name={name} />;
  else if (screen === "disconnected") return <BoilerDisconnected />;
  else if (screen === "editor")
    return (
      <InfoEditor
        customer_info={customer_info}
        fetchAccInfo={fetchAccInfo.bind(this)}
        changeUserInfo={() => setScreen("")}
      />
    );
  return (
    <BoilerStatus
      system_info={system_info}
      client={info}
      changeUserInfo={() => setScreen("editor")}
      saveClient={saveClient.bind(this)}
    />
  );
}
