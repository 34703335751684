import { Grid, Stack } from "@mui/material";
import {
  CustomDialog,
  CustomFormComponent,
  CustomTextField,
  DashboardTextField,
} from "../../../../components/custom_components";
import { useState } from "react";

function NewClientDialog({ dial_open, onClose, onSave }) {
  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (cb) => {
    if (data?.name === "" || data?.email === "" || data?.password === "") {
      return;
    }
    onSave(data, cb);
  };

  return (
    <CustomDialog
      dial_open={dial_open}
      onClose={onClose}
      title="Nieuwe klant"
      onSave={handleSubmit}
    >
      <Grid spacing={2} container>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Naam">
            <CustomTextField
              name="name"
              value={data?.name ?? ""}
              onChange={handleChange}
            />
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="E-mail">
            <CustomTextField
              name="email"
              value={data?.email ?? ""}
              onChange={handleChange}
            />
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Telefoonnummer">
            <CustomTextField
              name="phone_number"
              value={data?.phone_number ?? ""}
              onChange={handleChange}
            />
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomFormComponent label="Straatnaam">
            <CustomTextField
              name="street"
              value={data?.street ?? ""}
              onChange={handleChange}
            />
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={2}>
          <CustomFormComponent label="Huisnummer">
            <CustomTextField
              name="number"
              value={data?.number ?? ""}
              onChange={handleChange}
            />
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Postcode">
            <CustomTextField
              name="zip"
              value={data?.zip ?? ""}
              onChange={handleChange}
            />
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Plaats">
            <CustomTextField
              name="city"
              value={data?.city ?? ""}
              onChange={handleChange}
            />
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Wachtwoord">
            <CustomTextField
              name="password"
              value={data?.password ?? ""}
              onChange={handleChange}
            />
          </CustomFormComponent>
        </Grid>
      </Grid>
    </CustomDialog>
  );
}

export default NewClientDialog;
