import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { jsonEmpty } from "./../../../../toolbox/utils";
import {
  CustomButton,
  CustomTextField,
} from "../../../../components/custom_components";

export default function ClientModal(props) {
  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [nr, setNr] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [pw, setPw] = useState("");
  const [newEmail, setNewEmail] = useState("");

  useEffect(() => {
    if (
      typeof props.client !== "undefined" &&
      !jsonEmpty(props.client) &&
      props.client.user_id !== ""
    ) {
      setName(props.client.name);
      setPhone(
        props.client.phone_number === "-" ? "" : props.client.phone_number
      );
      setStreet(props.client.street === "-" ? "" : props.client.street);
      setNr(props.client.number === "-" ? "" : props.client.number);
      setZip(props.client.zip === "-" ? "" : props.client.zip);
      setCity(props.client.city === "-" ? "" : props.client.city);
    }
  }, [props.client]);

  const saveClient = () => {
    if ("user_id" in props.client)
      props.saveClient({
        id: props.client.user_id,
        name: name !== "" ? name : props.client.name,
        phone_number: phone !== "" ? phone : props.client.phone_number,
        street: street !== "" ? street : props.client.street,
        number: nr !== "" ? nr : props.client.number,
        zip: zip !== "" ? zip : props.client.zip,
        city: city !== "" ? city : props.client.city,
      });
    else
      props.saveClient({
        name: name !== "" ? name : props.client.name,
        phone_number: phone !== "" ? phone : props.client.phone_number,
        street: street !== "" ? street : props.client.street,
        number: nr !== "" ? nr : props.client.number,
        zip: zip !== "" ? zip : props.client.zip,
        city: city !== "" ? city : props.client.city,
      });
    props.close();
  };

  useEffect(() => {
    if (props.open && !open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.close(), 200);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Wijzig klantgegevens</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <CustomTextField
            placeholder={"Naam"}
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <CustomTextField
            placeholder={"Telefoonnummer"}
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <CustomTextField
            placeholder={"Straat"}
            value={street}
            id="street"
            onChange={(e) => setStreet(e.target.value)}
          />
          <CustomTextField
            placeholder={"Huisnummer + toevoeging"}
            value={nr}
            id="number"
            onChange={(e) => setNr(e.target.value)}
          />

          <CustomTextField
            placeholder={"Postcode"}
            value={zip}
            id="cip"
            onChange={(e) => setZip(e.target.value)}
          />
          <CustomTextField
            placeholder={"Plaats"}
            value={city}
            id="city"
            onChange={(e) => setCity(e.target.value)}
          />

          <CustomButton color="primary" onClick={saveClient} size="small">
            Opslaan
          </CustomButton>
          <Divider />
          <CustomTextField
            value={newEmail}
            placeholder="Email adres"
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <CustomButton
            color="primary"
            onClick={() =>
              props.saveEmail(
                newEmail,
                (props?.client?.id ?? "") !== ""
                  ? props?.client?.id
                  : props.client.user_id
              ) & handleClose()
            }
            size="small"
          >
            Opslaan
          </CustomButton>
          <Divider />
          <CustomTextField
            placeholder={"Wachtwoord"}
            id="city"
            value={pw}
            onChange={(e) => setPw(e.target.value)}
          />
          <CustomButton
            color="primary"
            size="small"
            onClick={() =>
              props.savePw(
                pw,
                (props?.client?.id ?? "") !== ""
                  ? props?.client?.id
                  : props.client.user_id
              )
            }
          >
            Opslaan
          </CustomButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <CustomButton color="error" onClick={handleClose} size="small">
          Sluiten
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
