import React from "react";
import { Stack, Typography } from "@mui/material";
import CustomIconButton from "./CustomIconButton";

/**
 *
 * @param {*} title Title at the top
 * @param {*} collapsed Boolean whether or not it is collapsed
 * @param {*} setCollapsed Function for when the collapse button is clicked
 * @param {*} children All elements that'll appear on the right
 * @returns
 */

function PaperTitle(props) {
  return (
    <Stack
      sx={{ width: "100%", padding: 2, height: "62px" }}
      direction="row"
      justifyContent={
        "children" in props || "collapsed" in props ? "space-between" : "start"
      }
      alignItems="center"
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "900",
          fontSize: ".95rem",
          letterSpacing: 2.5,
          textTransform: "uppercase",
          color: "rgba(0,0,0,.7)",
        }}
      >
        {props.title}
      </Typography>
      <Stack direction="row">
        {props.children}
        {"collapsed" in props && (
          <CustomIconButton
            type="collapse"
            sx={{
              transition: "transform 200ms",
              transform: `rotate(${!props.collapsed ? "0deg" : "-180deg"})`,
            }}
            onClick={() => props.setCollapsed()}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default PaperTitle;
