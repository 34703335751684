import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const button_map = (type, size) => {
  const style = {
    fontSize: size === "small" ? "1.15rem" : "unset",
  };
  const buttons = {
    edit: <EditIcon style={style} />,
    delete: <DeleteIcon style={style} />,
    menu: <MoreVertIcon style={style} />,
    eyes: <VisibilityIcon style={style} />,
    clear: <ClearIcon style={style} />,
    check: <PlaylistAddCheckIcon style={style} />,
    bar: <BarChartIcon style={style} />,
    camera: <PhotoCameraIcon style={style} />,
    filter: <FilterAltIcon style={style} />,
  };
  return buttons[type];
};

/**
 *
 * @param {*} type - edit, delete, menu, eyes, clear
 * @param {} size - Same as MUI
 * @param {} props - Same as MUI
 * @returns
 */
export function CustomIconButton(props) {
  return (
    <IconButton {...props}>
      {button_map(props.type, props.size ?? "small")}
    </IconButton>
  );
}
