import { useCallback, useState, useEffect } from "react";

export function useLocalStorage(key, defaultValue) {
  return useStorage(key, defaultValue, "local");
}

export function useSessionStorage(key, defaultValue) {
  return useStorage(key, defaultValue, "session");
}

function useStorage(key, defaultValue, storage) {
  const storageObject =
    typeof window !== "undefined"
      ? storage === "local"
        ? window.localStorage
        : window.sessionStorage
      : { getItem: () => {} };

  const [value, setValue] = useState(() => {
    const jsonValue = storageObject.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);
    if (typeof initialValue === "function") return defaultValue();
    else return defaultValue;
  });

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key);
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}
