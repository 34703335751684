import React, { useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import logo from "./../../assets/img/solesta-logo.png";
import { fetchPost } from "../../toolbox/fetchlib";
import {
  CustomButton,
  CustomTextField,
} from "../../components/custom_components";

const reset_api = "/api/request_pass_reset";

const styles = {
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    width: "98%",
    maxWidth: 300,
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "5vh",
  },
  new_acc_btn: {
    marginTop: "5vh",
  },
};

export default function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const sendEmail = () => {
    fetchPost(
      reset_api,
      { email: email },
      (data) => {
        setMessage("sent");
      },
      (er) => {
        setMessage("error");
        setLoading(false);
      }
    );
  }; //  /reset-wachtwoord

  return (
    <div style={styles.root}>
      <img src={logo} style={styles.logo} alt="logo" />
      <div style={styles.fields}>
        <Typography style={{ textAlign: "center", marginBottom: 10 }}>
          {message === "error"
            ? "E-mail onbekend"
            : message === "sent"
            ? "Reset link verzonden naar uw e-mail adres."
            : ""}
        </Typography>
        {message !== "sent" && (
          <CustomTextField
            id="uname"
            value={email}
            sx={{ marginBottom: 2 }}
            onChange={(v) => setEmail(v.target.value)}
            placeholder="naam@voorbeeld.nl"
            type="email"
          />
        )}
        {message !== "sent" && (
          <CustomButton
            variant="contained"
            sx={{ marginBottom: 2 }}
            color="warning"
            type="button"
            disabled={loading}
            style={{ width: "100%" }}
            onClick={() => sendEmail()}
          >
            {loading ? (
              <CircularProgress style={{ width: 21, height: 21 }} />
            ) : (
              "Versturen"
            )}
          </CustomButton>
        )}

        <CustomButton
          color="warning"
          type="button"
          disabled={loading}
          outline
          style={styles.new_acc_btn}
          onClick={() => navigate("/login")}
        >
          Terug
        </CustomButton>
      </div>
    </div>
  );
}
