import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
} from "@mui/material";
import { CustomIconButton } from "../../components/custom_components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useState } from "react";

const icon = (order) => (
  <ArrowUpwardIcon
    sx={{
      fontSize: "1.1rem",
      transition: "transform 200ms",
      transform: `rotate(${order === "desc" ? "180" : "0"}deg)`,
    }}
  />
);

export const monitoringColumns = [
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("registered_stamp")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Naam
        {orderBy === "registered_stamp" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) =>
      new Date(row.registered_stamp * 1000).toLocaleDateString("en-UK"),
  },
  {
    label: "Client ID",
    render: ({ row }) => row.client_id,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("software")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Software
        {orderBy === "software" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.software,
  },
  {
    label: ({ filters, filterClick, installers }) => (
      <FilterCell
        name="Installateur"
        filter_options={installers.map((inst) => inst.name)}
        filters={filters}
        filterClick={filterClick}
      />
    ),
    renderLabel: true,
    render: ({ row, installers_dict }) =>
      installers_dict?.[row.installer]?.name,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("city")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Plaats
        {orderBy === "city" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.city,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("zip")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Postcode
        {orderBy === "zip" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.zip,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("last_updated")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Laatst geupdated
        {orderBy === "last_updated" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) =>
      new Date(row.last_updated * 1000).toLocaleDateString("en-UK"),
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("temp")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Temperatuur
        {orderBy === "temp" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.temp,
  },
  {
    label: ({ filters, filterClick }) => (
      <FilterCell
        name="Orientatie"
        filter_options={["N", "NW", "W", "ZW", "Z", "ZO", "O", "NO"]}
        rowVal={"orientation"}
        filters={filters}
        filterClick={filterClick}
      />
    ),
    renderLabel: true,
    render: ({ row }) => row.orientation,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("height")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Opvoerhoogte
        {orderBy === "height" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.height,
  },
  {
    label: "Acties",
    render: ({ row, onClick }) => (
      <CustomIconButton type="eyes" onClick={() => onClick(row.client_id)} />
    ),
  },
];

function FilterCell({ name, filter_options, filters, filterClick }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Stack className="table-row" direction="row" alignItems="end">
        {name}
        <CustomIconButton
          type="filter"
          size="small"
          sx={{ marginLeft: "10px" }}
          onClick={handleClick}
          color={filters?.[name]?.length > 0 ? "primary" : "default"}
        />
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List dense>
          {filter_options.map((value, i) => (
            <ListItem key={i} size="small">
              <ListItemButton
                size="small"
                role={undefined}
                onClick={() => filterClick(name, value)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={filters?.[name].indexOf(value) !== -1}
                    tabIndex={-1}
                    size="small"
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={value} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
