export const jsonEmpty = (input) => {
  if (input === null || typeof input === "undefined") return false;
  return Object.keys(input).length === 0 && input.constructor === Object;
};

/**
 * @param {*} list List of objects
 * @param {*} key Key which you're comparing
 * @param {*} value Value you're looking for
 * @param {*} def Default return (optional)
 * @returns
 */
export const findByValue = (list, key, value, def) => {
  for (let i = 0; i < list.length; i++)
    if (list[i][key] === value) return list[i];
  if (typeof def !== "undefined") return def;
  return null;
};

/**
 * Remove element from array
 * @param {*} array Input array
 * @param {*} element Element you want to remove
 * @returns
 */
export const removeFromArray = (array, element) => {
  var index = array.indexOf(element);
  if (index >= 0) array.splice(index, 1);
  return array;
};
