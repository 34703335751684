import userEvent from "@testing-library/user-event";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGet, fetchPost } from "../toolbox/fetchlib";
import {
  failedNotification,
  successNotification,
} from "../toolbox/notification-center";
import {
  refresh_all_data,
  set_cust_data,
  set_external,
  set_installer,
  set_system_data,
  set_topics,
} from "../redux/actions";
import { set_user_info } from "../redux/actions";
import { reset_origin } from "../redux/actions";
import { useNavigate } from "react-router";

const getClients = "/api/noprefix_client_ids";
const getAllClients = "/api/all_my_client_ids";
const getCustomers = "/api/customers_minimal";
const disconnect = "/api/disconnect_smartline";
const get_installers = "/api/get_installers";
const get_externals = "/api/get_externals";
const topicCall = "/api/topics";
const changeClient = "/api/post_user_info";
const post_creds = "/api/connect_smartline";
const delete_user = "/api/delete_users";
const get_status_post = "/api/systems_online";
const hide_ids = "/api/hide_client_ids";
const get_hidden = "/api/get_hidden_client_ids";
const change_pw_external = "/api/change_password_external";
const manual_installer_link = "/api/manual_installer_link";
const manual_external_link = "/api/manual_external_link";
const manual_set = "/api/get_manual_installer_links";
const manual_external_set = "/api/get_manual_external_links";
const unlink_manual = "/api/delete_manual_installer_link";
const unlink_manual_external = "/api/delete_manual_external_link";
const store_note = "/api/store_note";
const store_additional_info = "/api/update_additional_system_info";

const desiredTopics = ["temp01", "temp02", "temp03", "temp04", "pump01pwm"];

function assignData(client, customers) {
  client.street = "";
  client.number = "";
  client.zip = "";
  client.city = "";
  client.phone_number = "-";
  client.email = "-";
  client.user_id = "";
  client.installer_name = "";
  client.installer = "";
  client.trace_number = {};
  client.name = "";
  for (var i = 0; i < customers.length; i++) {
    for (var j = 0; j < customers[i].client_ids.length; j++) {
      if (customers[i].client_ids[j].client_id === client.client_id) {
        var c = customers[i];
        client.email = c.email || "-";
        client.installer = c.client_ids[j].installer;
        client.number = c.number === null ? "" : c.number || "";
        client.street = c.street === null ? "" : c.street || "";
        client.address = `${c.street || ""} ${c.number || ""}`;
        client.zip = c.zip === null ? "" : c.zip || "";
        client.city = c.city === null ? "" : c.city || "";
        client.user_id = c.id;
        client.allow_contact = true;
        client.phone_number = c.phone_number || "-";
        client.name = c.name || "";
        client.system_name = c.client_ids[j].name;
        if ("trace_number" in customers[i].client_ids[j]) {
          client.trace_number = customers[i].client_ids[j].trace_number;
        }
        return;
      }
    }
  }
}

function setNrOfTraceNumbers(clients, systems) {
  for (let i = 0; i < clients.length; i++) {
    for (let j = 0; j < clients[i].client_ids.length; j++) {
      let found = false;
      for (let k = 0; k < systems.length; k++) {
        if (clients[i].client_ids[j].client_id === systems[k].client_id) {
          if (
            "trace_number" in systems[k] &&
            Object.keys(systems[k].trace_number).length > 0
          ) {
            var nr = 0;
            Object.keys(systems[k].trace_number).map((tn) => {
              if (systems[k].trace_number[tn] !== "") nr++;
            });
            clients[i].client_ids[j].nr_of_trace_numbers = nr;
            found = true;
          }
        }
      }
      if (!found) clients[i].client_ids[j].nr_of_trace_numbers = 0;
    }
  }
  return clients;
}

export default function GlobalDataHandler(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refresh_all_d = useSelector((state) => state?.refresh_all ?? false);
  const user_info = useSelector((state) => state?.user_info ?? {});
  const system_data = useSelector((state) => state?.system_data ?? []);

  const [loading, setLoading] = useState(false);
  const [reset_table, setResetTable] = useState(false);

  useEffect(() => {
    if ((user_info?.user_type ?? "") === "") {
      fetchUserInfo();
      return;
    }
    if (
      ["admin", "installer"].includes(user_info?.user_type ?? "") &&
      system_data.length <= 0
    ) {
      fetchInstallers();
      fetchExternal();
      fetchCustomers();
      fetchTopics();
    }
    return;
    //fetchAllData();
  }, [user_info]);

  useEffect(() => {
    if (refresh_all_d && !loading && (user_info?.user_type ?? "") === "admin") {
      //setLoading(true);
      //fetchAllData();
    }
  }, [refresh_all_d, loading]);

  const fetchUserInfo = () => {
    fetchGet(
      "/api/get_account_info",
      (data) => {
        dispatch(set_user_info(data));
      },
      (data, status) => {
        setLoading(false);
        if (window.location.href.includes("admin")) navigate(`/login`);
      }
    );
  };

  const fetchInstallers = () => {
    fetchGet(
      get_installers,
      (data) => {
        let new_dict = {};
        data.installers.map((inst) => {
          new_dict[inst.id] = {
            email: inst.email,
            name: inst.name,
            location: inst.location,
            phone_number: inst.phone_number,
            contact_person: inst.contact_person || "",
          };
        });
        data.installers.sort((a, b) => {
          if (a.name < b.name) return -1;
          return 1;
        });
        dispatch(
          set_installer({
            installers: data.installers,
            installer_dict: new_dict,
          })
        );
      },
      (er) => {}
    );
  };

  const fetchExternal = () => {
    fetchGet(
      get_externals,
      (data) => {
        let new_dict = {};
        data.externals.map((inst) => {
          new_dict[inst.id] = {
            email: inst.email,
            name: inst.name,
            location: inst.location,
            phone_number: inst.phone_number,
            contact_person: inst.contact_person || "",
            rol: inst.rol,
          };
        });
        dispatch(
          set_external({
            external: data.externals,
            external_dict: new_dict,
          })
        );
      },
      (er) => {}
    );
  };

  const fetchTopics = () => {
    fetchGet(
      topicCall,
      (resp) => {
        var new_topics = [];
        resp.topics.map((topic) => {
          var split = topic.split("/");
          if (desiredTopics.includes(split[split.length - 1]))
            new_topics.push({ name: split[split.length - 1], param: topic });
        });
        dispatch(set_topics(new_topics));
      },
      () => {
        console.log("can't fetch topics");
      }
    );
  };

  const fetchData = () => {
    return;
    fetchGet(
      getClients,
      (data) => {
        data.map((el) => (el.assigned = "Niet toegewezen"));
        fetchAll(data);
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan systemen niet ophalen. Probeer het later opnieuw"
        );
      }
    );
  };

  const fetchAll = (prefix) => {
    return;
    fetchGet(
      getAllClients,
      (data) => {
        data.map((el) => (el.assigned = "Solesta"));
        fetchCustomers(prefix.concat(data));
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan systemen niet ophalen. Probeer het later opnieuw"
        );
      }
    );
  };

  const fetchCustomers = () => {
    fetchGet(
      getCustomers,
      (data) => {
        dispatch(set_cust_data(data));
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan klant niet ophalen. Probeer het later opnieuw"
        );
      }
    );
  };

  const fetchClientIdOnline = (ids, prev_data) => {
    return;
    fetchPost(
      get_status_post,
      { client_ids: ids },
      (data) => {
        prev_data.map(
          (cl) => (cl.last_updated = (data[cl.client_id] || {}).last || -1)
        );
        fetchHidden(prev_data);
      },
      (er) => {
        failedNotification("Mislukt.", "Kan online statussen niet ophalen.");
      }
    );
  };

  const fetchHidden = (prev_data) => {
    return;
    fetchGet(
      get_hidden,
      (data) => {
        prev_data.map((sys) => (sys.hide = data.includes(sys.client_id)));
        fetchManuallyset(prev_data);
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan systemen niet ophalen. Probeer het later opnieuw"
        );
      }
    );
  };

  const fetchManuallyset = (prev_data) => {
    return;
    fetchGet(
      manual_set,
      (data) => {
        prev_data.map((dp) => {
          if (dp.client_id in data) {
            dp.street = data[dp.client_id].street;
            dp.number = data[dp.client_id].number;
            dp.zip = data[dp.client_id].zip;
            dp.city = data[dp.client_id].city;
            dp.installer = data[dp.client_id].installer;
            dp.manual_installer = true;
          }
        });
        fetchManualExternalSet(prev_data);
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan handmatig gekoppelde systemen niet ophalen. Probeer het later opnieuw"
        );
      }
    );
  };

  const fetchManualExternalSet = (prev_data) => {
    return;
    fetchGet(
      manual_external_set,
      (data) => {
        prev_data.map((dp) => {
          if (dp.client_id in data)
            dp.manual_external = data[dp.client_id].external;
        });
        dispatch(set_system_data(prev_data));
        setResetTable(true);
        setLoading(false);
        //dispatch(refresh_all_data(false));
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan handmatig gekoppelde systemen niet ophalen. Probeer het later opnieuw"
        );
      }
    );
  };

  const fetchAllData = () => {
    return;
    fetchInstallers();
    fetchExternal();
    fetchTopics();
    fetchData();
  };

  const logout = () => {};

  return { logout };
}
