import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import {
  CustomButton,
  CustomTextField,
} from "../../../../components/custom_components";

export default function InstallerModal(props) {
  const [open, setOpen] = useState(false);

  const [init, setInit] = useState(false);

  const [name, setName] = useState("");
  const [contact_person, setContactPerson] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (!init && "installer" in props && props.installer !== null) {
      setContactPerson(props.installer.contact_person || "");
      setName(props.installer.name);
      setEmail(props.installer.email);
      setLocation(props.installer.location);
      setPhone(props.installer.phone_number);
    }
  }, [props.installer]);

  const saveInstaller = () => {
    if (props.installer !== null) {
      if (
        contact_person !== "" &&
        name !== "" &&
        email !== "" &&
        location !== "" &&
        phone !== ""
      ) {
        var args = {
          id: props.installer.id,
          name: name,
          contact_person: contact_person,
          email: email,
          location: location,
          phone_number: phone,
        };
        if (password !== "") args.password = password;
        props.saveInstaller(args, () => {
          props.fetchInstallers();
          props.close();
        });
      }
    } else {
      if (
        contact_person !== "" &&
        name !== "" &&
        email !== "" &&
        password !== "" &&
        location !== "" &&
        phone !== ""
      ) {
        props.saveInstaller(
          {
            name: name,
            contact_person: contact_person,
            email: email,
            password: password,
            location: location,
            phone_number: phone,
          },
          () => {
            props.fetchInstallers();
            props.close();
          },
          (message) => {
            console.log(message);
          }
        );
      }
    }
  };

  useEffect(() => {
    if (props.open && !open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.close(), 200);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Installateur aanpassen</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <CustomTextField
            placeholder={"Naam"}
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <CustomTextField
            placeholder={"Contactpersoon"}
            id="contact_person"
            value={contact_person}
            onChange={(e) => setContactPerson(e.target.value)}
          />

          <CustomTextField
            placeholder={"Email"}
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <CustomTextField
            placeholder={"Wachtwoord"}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <CustomTextField
            placeholder={"Stad"}
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          <CustomTextField
            placeholder={"Telefoon"}
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <CustomButton color="error" onClick={handleClose} size="small">
          Annuleren
        </CustomButton>
        <CustomButton color="primary" onClick={saveInstaller} size="small">
          Opslaan
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
