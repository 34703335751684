import React from "react";
import { Box, Popover, Typography } from "@mui/material";
import "../../styles/CustomComponentsStyles.scss";

function CustomMenuButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        ...props.sx,
        backgroundColor: "#eef3f6c4",
        borderRadius: "48px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        px: 1.5,
        py: 0.8,
        alignItems: "center",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          flex: 0.4,
          fontSize: "0.8rem",
          fontWeight: "600",
          color: "#3b4395",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={handleClick}
      >
        {props.label}
      </Typography>
      <Box
        sx={{ flex: 1, textAlign: "right", "&:hover": { cursor: "pointer" } }}
        onClick={handleClick}
      >
        <Box
          style={{
            transition: "all 200ms",
            transform: `rotate(${open ? "180deg" : "0deg"})`,
            textAlign: "right",
            float: "right",
          }}
        >
          ▾
        </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={"popover_wrapper"}>
          {(props?.options ?? []).map((t) => {
            if (t?.disabled)
              return (
                <div key={t.label} className={"popover_value_disabled"}>
                  {t.label}
                </div>
              );
            return (
              <div
                key={t.label}
                className={"popover_value"}
                onClick={() => t.onClick() & handleClose()}
              >
                {t.label}
              </div>
            );
          })}
        </div>
      </Popover>
    </Box>
  );
}

export default CustomMenuButton;
