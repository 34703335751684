import { Typography } from "@mui/material";
import React from "react";

function SimpleText({ children, currency = null, loading = false, ...props }) {
  return (
    <Typography variant="body2" {...props}>
      {loading ? "Calculating..." : currency === null ? children : currency}
    </Typography>
  );
}

export default SimpleText;
