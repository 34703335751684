import { Box, Typography } from "@mui/material";
import { pogona_colors } from "../assets/themes/pogona_colors";

function UnderlinedTitle({ title }) {
  return (
    <Box>
      <Typography
        variant="h3"
        fontWeight="800"
        fontSize="1.2rem"
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <Box
        sx={{
          marginTop: 2,
          width: 44,
          height: 4,
          backgroundColor: pogona_colors.orange,
          borderRadius: 1,
        }}
      />
    </Box>
  );
}

export default UnderlinedTitle;
