var stringConstructor = "test".constructor;
var arrayConstructor = [].constructor;
var objectConstructor = ({}).constructor;

const check_type = (object) => {
    if (object === null) {
        return "null";
    }
    if (object === undefined) {
        return "undefined";
    }
    if (object.constructor === stringConstructor) {
        return "String";
    }
    if (object.constructor === arrayConstructor) {
        return "Array";
    }
    if (object.constructor === objectConstructor) {
        return "Object";
    }
    {
        return "don't know";
    }
}

export const store_local = (key, val) => {
    if (check_type(val) === "Object")
        window.localStorage.setItem(key, JSON.stringify(val));
    else
        window.localStorage.setItem(key, val);
}

export const get_local = (key, initial) => {
    if (typeof initial !== 'undefined' && check_type(initial) === "Object")
        return JSON.parse(window.localStorage.getItem(key));
    return window.localStorage.getItem(key)
}

export const store_session = (key, val) => {
    if (check_type(val) === "Object")
        window.sessionStorage.setItem(key, JSON.stringify(val));
    else
        window.sessionStorage.setItem(key, val);
}

export const get_session = (key, initial) => {
    if (typeof initial !== 'undefined' && check_type(initial) === "Object")
        return JSON.parse(window.sessionStorage.getItem(key));
    return window.sessionStorage.getItem(key)
}