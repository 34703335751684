import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import CustomButton from "./CustomButton";
import { pogona_colors } from "../../assets/themes/pogona_colors";
import LoadingOverlay from "../global/LoadingOverlay";

function CustomDialog({
  dial_open,
  onClose,
  fullWidth,
  maxWidth,
  title,
  children,
  sx,
  loading = false,
  loadingText = "Loading...",
  customActionButtons,
  saveText = "Save",
  cancelText = "Close",
  onSave,
  hasNext = false,
  contentSx = {},
  showCloseButton = true,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (dial_open && !open) {
      setOpen(true);
    }
  }, [dial_open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => onClose(), 200);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          ...sx,
          position: "relative",
        },
      }}
    >
      {loading && (
        <LoadingOverlay
          minHeight={"unset"}
          glass
          size="md"
          text={loadingText}
        />
      )}
      {title && (
        <DialogTitle>
          <Box>
            <Typography
              variant="h3"
              fontWeight="800"
              fontSize="1.2rem"
              textTransform="uppercase"
            >
              {title}
            </Typography>
            <Box
              sx={{
                marginTop: 2,
                width: 44,
                height: 4,
                backgroundColor: pogona_colors.orange,
                borderRadius: 1,
              }}
            />
          </Box>
        </DialogTitle>
      )}
      <DialogContent sx={{ ...contentSx }}>{children}</DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={0.5}>
          {(onSave || hasNext) && (
            <CustomButton
              onClick={handleClose}
              variant="outlined"
              color="warning"
              roundedCorner="BottomLeft"
            >
              {cancelText}
            </CustomButton>
          )}
          {showCloseButton && !onSave && !hasNext && (
            <CustomButton
              onClick={handleClose}
              variant="outlined"
              roundedCorner="BottomRight"
            >
              {cancelText}
            </CustomButton>
          )}
          {customActionButtons && customActionButtons}
          {onSave && (
            <CustomButton
              onClick={() => onSave(handleClose)}
              roundedCorner="TopRight"
              color="primary"
            >
              {saveText}
            </CustomButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
