import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Banner from "../../../assets/components/Banner";
import { fetchGet } from "./../../../toolbox/fetchlib";
import Footer from "../../../assets/components/Footer";
import ClientModal from "../../admin/components/clients_overview/ClientModal";

const getSystemInfo = "/api/get_system_info";

export default function BoilerStatus(props) {
  const [info_modal, setInfoModal] = useState(false);

  return (
    <div className="full-page">
      <Banner />
      <div id="system_info">
        <div>
          <Typography className="yellow_small" variant="h5">
            Systeem status {props.system_info.system_status || "-"}
          </Typography>

          <Typography
            variant="body1"
            className="b1"
            style={{ margin: "10px 0" }}
          >
            Watertemperatuur in het vat
          </Typography>
          <Typography variant="h4" id="water_temp">{`${Math.round(
            props.system_info.water_temp || 0
          )}° C`}</Typography>
        </div>
        <div
          id="showers-and-system"
          style={{
            backgroundColor:
              props.system_info.pump_status === "Circulatie"
                ? "#fbfbfb"
                : "white",
          }}
        >
          <div id="showers">
            <Typography variant="body2" className="b2">
              Energie voor
            </Typography>
            <Typography variant="h5" id="douche">
              {Math.floor(props.system_info.showers || 0)}
            </Typography>
            <Typography variant="body2" className="b2">
              standaard douchebeurten
            </Typography>
          </div>
          <div>
            <Typography variant="body2" className="b2">
              Status van uw systeem:
            </Typography>
            <Typography className="yellow_small" variant="h5">
              {props.system_info.pump_status || "-"}
            </Typography>
          </div>
        </div>
        <div>
          <Typography
            className="yellow_small"
            variant="h5"
            style={{ marginBottom: 10 }}
          >
            Zonne-energie
          </Typography>
          <Typography variant="body2" className="b2">{`In het boilervat:  ${(
            props.system_info.energy_tank_mj || 0
          ).toFixed(2)} MJ`}</Typography>
          <Typography variant="body2" className="b2">{`In het boilervat:  ${(
            props.system_info.energy_tank_kwh || 0
          ).toFixed(2)} KWh`}</Typography>
        </div>
      </div>
      <Footer changeUserInfo={props.changeUserInfo.bind(this)} />
    </div>
  );
}
