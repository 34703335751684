export const pogona_colors = {
  dark_purple: "#012f3d",
  royal_purple: "#231428",
  royal_purple_95: "#36283d",
  royal_purple_90: "#413347",
  royal_purple_80: "#432C4E",
  light_purple: "#713BDB",
  red: "#e83630",
  orange: "#f18522",
  yellow: "#e9cb1b",
  grey: "#808080",
  green: "#42c98b",
  light_grey: "#dbdbdb",
  medium_grey: "#888888",
  fluor_green: "#4dff00",
  completed_green: "#b4d9a5",
};
