import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { refresh_all_data } from "../../../../redux/actions";
import { fetchPost } from "../../../../toolbox/fetchlib";
import {
  failedNotification,
  successNotification,
} from "../../../../toolbox/notification-center";

/**
 
installers={installers}
data={cust_data}
reset_table={false}
system_data={system_data}
deleteUser={deleteUser.bind(this)}
saveSystem={saveSystem.bind(this)}
changePassword={changePassword.bind(this)}
savePw={savePw.bind(this)}
updateClient={updateClient.bind(this)}
chartData={(id, info) => findChartData(id, info)}
 */

const post_creds = "/api/connect_smartline";
const delete_user = "/api/delete_users";
const change_pw_external = "/api/change_password_external";
const changeClient = "/api/post_user_info";

function ClientData(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const installers = useSelector((state) => state?.installers?.installer ?? []);
  const cust_data = useSelector((state) => state?.cust_data ?? []);
  const system_data = useSelector((state) => state?.system_data ?? []);

  const fetchData = () => {
    //dispatch(refresh_all_data(true));
  };

  const deleteUser = (id) => {
    fetchPost(
      delete_user,
      { ids: [id] },
      (data) => {
        fetchData();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan klant niet verwijderen. Probeer het later opnieuw."
        );
      }
    );
  };

  const saveSystem = (system, callback) => {
    fetchPost(
      post_creds,
      system,
      (data) => {
        if (data.success) {
          fetchData();
          callback();
        } else if (data.status === 1)
          failedNotification(
            "Apparaat ID niet gevonden.",
            "Apparaat ID bestaat niet. Controleer of dit veld juist is ingevuld."
          );
        else if (data.status === 2)
          failedNotification(
            "Apparaat ID al gekoppeld.",
            "Apparaat ID al gekoppeld aan een ander apparaat. Neem contact op met Solesta."
          );
        else
          failedNotification("Koppelen mislukt.", "Probeer het later opnieuw.");
      },
      (er) => {
        failedNotification("Koppelen mislukt.", "Probeer het later opnieuw.");
      }
    );
  };

  const changePassword = (id, newPw) => {
    fetchPost(
      "/api/change_password_external",
      {
        id,
        new_password: newPw,
      },
      (resp) => {
        successNotification("Opgeslagen", "Wachtwoord is veranderd");
      },
      (err) => {
        failedNotification(
          "Niet gelukt.",
          "Zorg dat het wachtwoord genoeg karakters, een hoofdletter en een cijfer bevat"
        );
      }
    );
  };

  const savePw = (pw, id) => {
    fetchPost(
      change_pw_external,
      { new_password: pw, id: id },
      (data) => {
        successNotification("Wachtwoord gewijzigd", "");
      },
      (er) => {
        if (er.status === 450)
          failedNotification(
            "Niet gelukt",
            "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer."
          );
        else failedNotification("Niet gelukt", "Probeer het later opniew.");
      }
    );
  };

  const saveEmail = (pw, id) => {
    fetchPost(
      "/api/change_email_external",
      { new_email: pw, id: id },
      (data) => {
        successNotification("Email gewijzigd", "");
      },
      (er) => {
        if (er.status === 450)
          failedNotification(
            "Niet gelukt",
            "Email adres bestaat al of is niet geldig."
          );
        else
          failedNotification(
            "Niet gelukt",
            "Email adres bestaat al of is niet geldig."
          );
      }
    );
  };

  const updateClient = (client) => {
    fetchPost(
      changeClient,
      client,
      (data) => {
        fetchData();
      },
      (er) => {
        failedNotification(
          "Opslaan mislukt",
          "Kan nu niet opslaan. Probeer het later opnieuw."
        );
      }
    );
  };

  const createNewClient = (data, cb) => {
    fetchPost(
      "/api/post_manual_user_credentials",
      data,
      (resp) => {
        fetchData();
        cb();
      },
      (err) => {
        failedNotification(
          "Opslaan mislukt",
          "Kan nu niet opslaan. Probeer het later opnieuw."
        );
      }
    );
  };

  const chartData = (client_id, row) => {
    console.log(client_id);
    navigate(`/admin/systemen/${client_id}`);
  };

  return {
    installers,
    data: cust_data,
    reset_table: false,
    system_data,
    saveEmail,
    deleteUser,
    saveSystem,
    changePassword,
    savePw,
    updateClient,
    chartData,
    createNewClient,
  };
}

export default ClientData;
