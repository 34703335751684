import { Box, Popover, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { findByValue } from "../../utils/utils";
import "../../styles/CustomComponentsStyles.scss";

function CustomSelect(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        ...props.sx,
        backgroundColor: "#eef3f6c4",
        borderRadius: "48px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        px: 1.5,
        py: 0.8,
        alignItems: "center",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: "0.8rem",
          fontWeight: "600",
          color: "#3b4395",
        }}
      >
        {props.label}
      </Typography>
      <Box
        onClick={handleClick}
        sx={{
          flex: 1,
          gap: 2,
          display: "flex",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Typography
          variant="body2"
          onClick={handleClick}
          sx={{
            textAlign: "right",
            fontSize: "0.8rem",
            color: "#3b4395",
            flex: 1,
          }}
        >
          {
            findByValue(props.options, "value", props.value, { label: "" })
              .label
          }
        </Typography>
        <Box
          style={{
            width: 10,
            transition: "all 200ms",
            transform: `rotate(${open ? "180deg" : "0deg"})`,
          }}
        >
          ▾
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className={"popover_wrapper"}>
          {(props?.options ?? []).map((t) => (
            <div
              key={t.value}
              className={"popover_value"}
              onClick={() =>
                props.onChange({
                  target: { name: props.name, value: t.value },
                }) & handleClose()
              }
            >
              {t.label}
            </div>
          ))}
        </div>
      </Popover>
    </Box>
  );
}

export default CustomSelect;
