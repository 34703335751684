import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import PaperTitle from "../../../../assets/components/PaperTitle";
import {
  CustomFormComponent,
  CustomPaper,
} from "../../../../components/custom_components";

const data_app = [
  {
    label: "Systeem status",
    render: (item) => (item?.["system_online"] ? "Online" : "Offline"),
  },

  {
    label: "App temp",
    render: (item) => Math.round(item?.["water_temp"] ?? 0),
  },
  {
    label: "Pomp",
    render: (item) => item?.["pump_status"],
  },
];

const temp_actueel = [
  {
    label: "Collector (geel)",
    render: (item) => item?.["temp1"],
  },
  {
    label: "Vat boven (rood)",
    render: (item) => item?.["temp2"],
  },
  {
    label: "Extra sensor",
    render: (item) => item?.["temp4"],
  },
  {
    label: "Vat onder (blauw)",
    render: (item) => item?.["temp3"],
  },
];

const datamap24h = [
  {
    label: "Collector",
    render1: (item) => item?.["min_1"],
    render2: (item) => item?.["max_1"],
  },
  {
    label: "Vat boven",
    render1: (item) => item?.["min_2"],
    render2: (item) => item?.["max_2"],
  },
  {
    label: "Vat onder",
    render1: (item) => item?.["min_3"],
    render2: (item) => item?.["max_3"],
  },
  {
    label: "Extra sensor",
    render1: (item) => item?.["min_4"],
    render2: (item) => item?.["max_4"],
  },
];

function LiveData({ data }) {
  const getValue = (val) => {
    if ((val ?? 300) >= 300) return "N.A.";
    return val;
  };

  return (
    <CustomPaper title="Live data">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="600">
            Informatie in de app
          </Typography>
        </Grid>
        {data_app.map((item, i) => (
          <Grid item xs={12} sm={12 / data_app.length} key={`da_${i}`}>
            <CustomFormComponent label={item.label}>
              <Typography variant="body2">{item.render(data) ?? -1}</Typography>
            </CustomFormComponent>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="600" sx={{ my: 2 }}>
            Temperatuursensoren actueel
          </Typography>
        </Grid>
        {temp_actueel.map((item, i) => (
          <Grid item xs={12} sm={12 / 2} key={`ta_${i}`}>
            <CustomFormComponent label={item.label}>
              <Typography variant="body2">
                {getValue(item.render(data) ?? 307)}
              </Typography>
            </CustomFormComponent>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="600" sx={{ my: 2 }}>
            Historie laatste 24 uur (min, max)
          </Typography>
        </Grid>
        {datamap24h.map((item, i) => (
          <Grid item xs={12} sm={12 / 2} key={`dm_${i}`}>
            <CustomFormComponent label={item.label}>
              <Typography variant="body2">
                {`${getValue(item.render1(data) ?? 307)}, ${getValue(
                  item.render2(data) ?? 307
                )}`}
              </Typography>
            </CustomFormComponent>
          </Grid>
        ))}
      </Grid>
    </CustomPaper>
  );
}

export default LiveData;
