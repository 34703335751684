import axios from "axios";

const offline_prefix = "http://127.0.0.1:5050/api/";
const online_prefix = "https://monitor.solesta.nl/api/";

export const getPrefix = () => {
  return window.location.host.includes("127.0.0")
    ? offline_prefix
    : online_prefix;
};

export const queryGet = async (call, params) => {
  call = getPrefix() + call;
  if (typeof params !== "undefined")
    return axios
      .get(call, { params: params, withCredentials: true })
      .then((res) => res.data);
  return axios.get(call, { withCredentials: true }).then((res) => res.data);
};

export const queryPost = async (call, params) => {
  call = getPrefix() + call;
  //const api = axios.create({ withCredentials: true });
  //return api.post(call, { ...params }).then((res) => res.data);
  return axios
    .post(call, params, { withCredentials: true })
    .then((res) => res.data);
};
