import React, { useMemo } from "react";
import { Button } from "@mui/material";

const sx_map = {
  small: {
    padding: "4px 9px",
    fontSize: ".75rem",
  },
  normal: {
    padding: "5px 9px",
    fontSize: ".8rem",
  },
  large: {
    padding: "6px 9px",
    fontSize: ".85rem",
  },
};

function CustomButton(props) {
  const styles = useMemo(() => {
    let sx = {
      ...(props.sx ?? {}),
      textTransform: "uppercase",
      fontWeight: "600",
      borderRadius: 2,
      padding: sx_map[props.size ?? "normal"].padding,
      fontSize: sx_map[props.size ?? "normal"].fontSize,
    };
    if ("roundedCorner" in props)
      sx[`border${props.roundedCorner}Radius`] = "20px";
    if ("textColor" in props) sx[`color`] = props.textColor;
    if ("width" in props) sx[`width`] = props.width;
    if (
      (!("color" in props) || props.color === "secondary") &&
      props.variant === "contained" &&
      !("backgroundColor" in props)
    )
      sx["background"] =
        "linear-gradient(90deg, rgba(240,122,24,1) 0%, rgba(231,51,49,1) 100%)";
    if (
      props.variant === "outlined" &&
      (!("color" in props) || props.color === "secondary")
    )
      sx["borderColor"] = "#E84C24";
    if ("backgroundColor" in props)
      sx[`backgroundColor`] = props.backgroundColor;
    return sx;
  }, [props]);

  const fwdProps = useMemo(() => {
    let fwd = { ...props };
    //if (props?.children === "Select version") console.log(fwd);
    delete fwd.roundedCorner;
    delete fwd.backgroundColor;
    delete fwd.textColor;
    fwd.variant = props.variant ?? "outlined";
    if (typeof props.fwdRef !== "undefined") {
      fwd.ref = props.fwdRef;
      delete fwd.fwdRef;
    }
    return fwd;
  }, [props]);

  return (
    <Button {...fwdProps} color={props.color ?? "secondary"} sx={styles}>
      {props.children}
    </Button>
  );
}

export default CustomButton;
