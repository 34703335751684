import React, { useEffect, useState } from "react";
import { failedNotification } from "../../../../toolbox/notification-center";
import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomSelect from "../../../../assets/components/CustomSelect";
import {
  CustomButton,
  CustomDropdown,
  CustomFormComponent,
  CustomTextField,
} from "../../../../components/custom_components";

export default function SystemLinker(props) {
  const [open, setOpen] = useState(false);

  const [initial, setInitial] = useState(false);

  const [clientId, setClientId] = useState("");

  const [installer, setInstaller] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    if (!initial) {
      setClientId(props?.client_id ?? "");
      setInstaller(props?.system?.installer ?? "");
      setStreet(props?.system?.street ?? "");
      setNumber(props?.system?.number ?? "");
      setZip(props?.system?.zip ?? "");
      setCity(props?.system?.city ?? "");
      setInitial(true);
    }
  }, [props.system]);

  const save = () => {
    if (installer !== "") {
      var args = {
        client_id: clientId,
        installer: installer,
        street: street,
        number: number,
        zip: zip,
        city: city,
        user_id: props.user_id,
      };
      props.save(args);
    } else {
      failedNotification("Niet gelukt.", "Selecteer een installateur.");
    }
  };

  useEffect(() => {
    if (props.open && !open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.close(), 200);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {props.showClientId && (
            <CustomFormComponent label="Client ID">
              <CustomTextField
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
              />
            </CustomFormComponent>
          )}
          {(props?.installers ?? []).length > 0 && (
            <CustomFormComponent label="Installateur">
              <CustomDropdown
                options={(props?.installers ?? [])
                  .sort((a, b) => {
                    if (a.name < b.name) return -1;
                    return 1;
                  })
                  .map((inst) => {
                    return { label: inst.name, value: inst.id };
                  })}
                onChange={(e) => setInstaller(e.target.value)}
                value={installer}
                style={{ width: "100%", marginBottom: 20 }}
                label="Installateur"
              />
            </CustomFormComponent>
          )}
          <CustomFormComponent label="Straatnaam">
            <CustomTextField
              id="street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </CustomFormComponent>

          <CustomFormComponent label="Huisnummer + toevoeging">
            <CustomTextField
              id="number"
              placeholder="Huisnummer + toevoeging"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </CustomFormComponent>
          <CustomFormComponent label="Postcode">
            <CustomTextField
              id="zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
          </CustomFormComponent>
          <CustomFormComponent label="Plaatsnaam">
            <CustomTextField
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </CustomFormComponent>
        </Stack>
      </DialogContent>
      <DialogActions>
        <CustomButton color="error" onClick={handleClose} size="small">
          Annuleren
        </CustomButton>
        <CustomButton color="primary" onClick={save} size="small">
          Opslaan
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
