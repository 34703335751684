import React, { useState } from "react";
import { Button, Container, Stack, TextField } from "@mui/material";
import ConfirmationScreen from "../../../../assets/components/ConfirmationScreen";
import TabTopBar from "../../../../assets/components/TabTopBar";
import { InstallerData } from "./InstallerData";
import InstallerModal from "./InstallerModal";
import InstallerTable from "./InstallerTable";
import {
  CustomButton,
  CustomTextField,
} from "../../../../components/custom_components";

export default function InstallerOverview(props) {
  const {
    installers,
    newInstaller,
    deleteInstallers,
    editInstaller,
    fetchInstallers,
  } = InstallerData();

  const default_modal = { open: false, callback: null, installer: null };

  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(default_modal);
  const [confirm_modal, setConfirmModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const selectInstaller = (arg) => {
    if (arg === "all") setSelected(installers.map((inst) => inst.id));
    else if (arg === "none") setSelected([]);
    else {
      var index = selected.indexOf(arg),
        new_selected = [...selected];
      index >= 0 ? new_selected.splice(index, 1) : new_selected.push(arg);
      setSelected(new_selected);
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: `calc(100% - ${20}px)`,
        overflow: "auto",
        backgroundColor: "#f5f7f9",
        p: 2,
      }}
    >
      <TabTopBar title="Installateurs" subtitle="Beheer installateurs">
        <Stack direction="row" spacing={1} alignItems="center">
          <CustomButton
            color="secondary"
            onClick={() => setConfirmModal(true)}
            size="small"
            variant="contained"
          >
            Verwijderen
          </CustomButton>
          <CustomButton
            color="primary"
            variant="contained"
            size="small"
            onClick={() =>
              setModal({
                open: true,
                callback: newInstaller.bind(this),
                installer: null,
              })
            }
          >
            Nieuw
          </CustomButton>
          <CustomTextField
            placeholder="Zoeken..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Stack>
      </TabTopBar>
      <InstallerTable
        data={installers}
        search={search}
        selected={selected}
        editInstaller={(inst) =>
          setModal({
            open: true,
            callback: editInstaller.bind(this),
            installer: inst,
          })
        }
        selectAll={() => selectInstaller("all")}
        deselectAll={() => selectInstaller("none")}
        editClient={(id) => selectInstaller(id)}
      />
      {modal.open && (
        <InstallerModal
          open={modal.open}
          close={() => setModal(default_modal)}
          installer={modal.installer}
          fetchInstallers={fetchInstallers.bind(this)}
          textAlign="left"
          confirm_button="Opslaan"
          saveInstaller={(data, cb, ecb) => modal.callback(data, cb, ecb)}
        />
      )}
      <ConfirmationScreen
        open={confirm_modal}
        close={() => setConfirmModal(false)}
        textAlign="left"
        confirm_button="Bevestig"
        confirm={() =>
          deleteInstallers(selected, () => {
            fetchInstallers();
          }) & setConfirmModal(false)
        }
      >
        Weet je zeker dat je deze installateurs wil verwijderen?
      </ConfirmationScreen>
    </Container>
  );
}
