import { Box, Paper, Stack, Typography } from "@mui/material";
import { pogona_colors } from "../../assets/themes/pogona_colors";
import CustomIconButton from "./CustomIconButton";

function CustomPaper({
  paperProps,
  title,
  titleChildren,
  children,
  childrenLeft = null,
  childrenRight = null,
  minContentHeight = 0,
  maxContentHeight = "unset",
}) {
  return (
    <Paper
      elevation={0}
      sx={{ border: "1px solid #DBDBDB", ...(paperProps?.sx ?? {}) }}
      {...paperProps}
    >
      <Title title={title} titleChildren={titleChildren} />
      <Box
        sx={{
          px: 4,
          paddingTop: 2,
          paddingBottom: 4,
          minHeight: minContentHeight,
          maxHeight: maxContentHeight,
          overflow: "auto",
        }}
      >
        {children}
      </Box>
      {(childrenLeft !== null || childrenRight !== null) && (
        <BottomChildren
          childrenLeft={childrenLeft}
          childrenRight={childrenRight}
        />
      )}
    </Paper>
  );
}

export default CustomPaper;

const Title = ({ title, titleChildren, collapsed, setCollapsed }) => (
  <Stack
    sx={{ width: "100%", px: 4, py: 3 }}
    direction="row"
    justifyContent={
      (titleChildren ?? null) !== null || (collapsed ?? null) !== null
        ? "space-between"
        : "start"
    }
    alignItems="center"
  >
    <Box>
      <Typography
        variant="h3"
        fontWeight="800"
        fontSize="1.2rem"
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <Box
        sx={{
          marginTop: 2,
          width: 44,
          height: 4,
          backgroundColor: "#f18522",
          borderRadius: 1,
        }}
      />
    </Box>
    <Stack direction="row">
      {titleChildren}
      {(collapsed ?? null) !== null && (
        <CustomIconButton
          type="collapse"
          sx={{
            marginLeft: 1,
            transition: "transform 200ms",
            transform: `rotate(${!collapsed ? "0deg" : "-180deg"})`,
          }}
          onClick={() => setCollapsed()}
        />
      )}
    </Stack>
  </Stack>
);

const BottomChildren = ({ childrenLeft, childrenRight }) => (
  <Stack
    direction="row"
    justifyContent={
      (childrenLeft ?? null) !== null && (childrenRight ?? null) !== null
        ? "space-between"
        : childrenLeft !== null
        ? "flex-start"
        : "flex-end"
    }
    sx={{ marginTop: 4, width: "100%", px: 4, pb: 2 }}
  >
    {childrenLeft}
    {childrenRight}
  </Stack>
);
