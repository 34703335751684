import { combineReducers } from "redux";

import userInfoReducer from "./user_info";
import originReducer from "./origin";
import customerAccountReducer from "./customer_info";
import installerReducer from "./installerReducer";
import externalReducer from "./externalReducer";
import customerDataReducer from "./custDataReducer";
import systemDataReducer from "./systemDataReducer";
import topicsReducer from "./topicsReducer";
import dataRefreshReducer from "./dataRefreshReducer";

const allReducers = combineReducers({
  user_info: userInfoReducer,
  origin: originReducer,
  customer_info: customerAccountReducer,
  installers: installerReducer,
  external: externalReducer,
  cust_data: customerDataReducer,
  system_data: systemDataReducer,
  topics: topicsReducer,
  refresh_all: dataRefreshReducer,
});

export default allReducers;
