import { Stack } from "@mui/material";
import CustomLabel from "./CustomLabel";

function CustomFormComponents(props) {
  return (
    <Stack
      spacing={props?.spacing ?? 1}
      sx={{ maxWidth: props?.maxWidth, ...(props?.sx ?? {}) }}
    >
      {"label" in props &&
        ("titleChildren" in props ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <CustomLabel>{props.label}</CustomLabel>
            {props.titleChildren}
          </Stack>
        ) : (
          <CustomLabel>{props.label}</CustomLabel>
        ))}
      {props.children}
    </Stack>
  );
}

export default CustomFormComponents;
