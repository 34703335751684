import { Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ConfirmDialog from "../../assets/components/ConfirmDialog";
import TabTopBar from "../../assets/components/TabTopBar";
import EditSystem from "./EditSystem";
import UnlinkedSystemsData from "./UnlinkedSystemsData";
import UnlinkedSystemsTable from "./UnlinkedSystemsTable";
import SystemLinker from "../admin/components/unassigned_overview/SystemLinker";

function UnlinkedSystems(props) {
  const default_confirm = { open: false, text: "", callback: null };
  const default_edit = { open: false, system: {} };

  const {
    user_info,
    installers,
    installers_dict,
    unlinked_systems,
    updateSystem,
    deleteSystem,
  } = UnlinkedSystemsData(props);

  const [confirm, setConfirm] = useState(default_confirm);
  const [edit, setEdit] = useState(default_edit);

  return (
    <>
      {confirm.open && (
        <ConfirmDialog
          dial_open={confirm.open}
          onConfirm={() => confirm.callback()}
          close={() => setConfirm(default_confirm)}
        >
          <Typography>{confirm.text}</Typography>
        </ConfirmDialog>
      )}
      {edit.open && (
        <SystemLinker
          open={edit.open}
          showClientId
          client_id={edit.system.client_id}
          system={edit.system}
          user_info={user_info}
          installers={installers}
          close={() => setEdit(default_edit)}
          save={updateSystem.bind(this)}
        />
      )}
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          height: `calc(100% - ${20}px)`,
          overflow: "auto",
          backgroundColor: "#f5f7f9",
          p: 2,
        }}
      >
        <TabTopBar
          title="Ongekoppelde systemen"
          subtitle="Beheer ongekoppelde systemen"
        />
        <UnlinkedSystemsTable
          data={unlinked_systems}
          installers_dict={installers_dict}
          edit={(system) => setEdit({ open: true, system: system })}
          delete={(client_id) =>
            setConfirm({
              open: true,
              text: `Weet je zeker dat je apparaat ${client_id} wil verwijderen?`,
              callback: () => deleteSystem(client_id),
            })
          }
        />
      </Container>
    </>
  );
}

export default UnlinkedSystems;
