import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";

import logo from "./../../assets/img/solesta-logo.png";
import { fetchPost } from "../../toolbox/fetchlib";
import { useNavigate } from "react-router";
import {
  CustomButton,
  CustomTextField,
} from "../../components/custom_components";

const reset_api = "/api/change_forgotten_pass";

const styles = {
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    width: "98%",
    maxWidth: 300,
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "5vh",
    width: 220,
  },
  new_acc_btn: {
    marginTop: "5vh",
  },
};

export default function ResetPassword(props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [token, setToken] = useState("");

  const [pw_1, setPw1] = useState("");
  const [pw_2, setPw2] = useState("");

  useEffect(() => {
    var url = window.location.href;
    var index = url.indexOf("?token=");
    if (index >= 0) setToken(url.substr(index + 7, url.length));
  }, []);

  const sendEmail = () => {
    if (pw_1 === pw_2)
      fetchPost(
        reset_api,
        { token: token, new_password: pw_1 },
        (data) => {
          setMessage("success");
        },
        (er) => {
          setMessage("criteria");
        }
      );
    else setMessage("not_equal");
  }; //

  return (
    <div style={styles.root}>
      <img src={logo} style={styles.logo} alt="logo" />
      <div style={styles.fields}>
        <Typography style={{ textAlign: "center", marginBottom: 10 }}>
          {message === "not_equal"
            ? "Wachtwoorden komen niet overeen"
            : message === "criteria"
            ? "Wachtwoord moet minimaal 7 tekens bevatten waaronder een hoofdletter, cijfer en speciaal teken."
            : message === "success"
            ? "Opgeslagen. Druk op de knop om in te loggen."
            : ""}
        </Typography>
        {message !== "success" && (
          <CustomTextField
            id="uname"
            sx={{ marginBottom: 2 }}
            value={pw_1}
            onChange={(v) => setPw1(v.target.value)}
            placeholder="Nieuw wachtwoord"
            type="password"
          />
        )}
        {message !== "success" && (
          <CustomTextField
            id="uname"
            value={pw_2}
            sx={{ marginBottom: 2 }}
            onChange={(v) => setPw2(v.target.value)}
            placeholder="Herhaal wachtwoord"
            type="password"
          />
        )}
        {message !== "success" && (
          <CustomButton
            color="warning"
            variant="contained"
            sx={{ marginBottom: 2 }}
            type="button"
            disabled={loading}
            style={{ width: "100%" }}
            onClick={() => sendEmail()}
          >
            {loading ? (
              <CircularProgress style={{ width: 21, height: 21 }} />
            ) : (
              "Opslaan"
            )}
          </CustomButton>
        )}
        {message === "success" && (
          <CustomButton
            color="warning"
            type="button"
            disabled={loading}
            style={{ width: "100%" }}
            onClick={() => navigate("/login")}
          >
            {loading ? (
              <CircularProgress style={{ width: 21, height: 21 }} />
            ) : (
              "Naar login"
            )}
          </CustomButton>
        )}
      </div>
    </div>
  );
}
