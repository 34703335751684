const default_state = {
  external: [],
  external_dict: {},
};

const externalReducer = (state = default_state, action) => {
  switch (action.type) {
    case "SET_EXTERNAL":
      state = { ...action.payload };
      return state;
    default:
      return state;
  }
};

export default externalReducer;
