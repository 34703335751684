import { useState, useEffect } from "react";
import {
  failedNotification,
  successNotification,
} from "./../../toolbox/notification-center";
import { fetchGet, fetchPost } from "./../../toolbox/fetchlib";
import { useSelector } from "react-redux";

function UnlinkedSystemsData(props) {
  const [unlinked_systems, setUnlinkedSystems] = useState([]);
  const installers = useSelector(
    (state) => state?.installers?.installers ?? []
  );
  const installers_dict = useSelector(
    (state) => state?.installers?.installers_dict ?? {}
  );
  const user_info = useSelector((state) => state.user_info);

  useEffect(() => {
    fetchSystems();
  }, []);

  const fetchSystems = () => {
    fetchGet(
      "/api/get_unlinked_systems",
      (data) => {
        setUnlinkedSystems(data);
      },
      (er) => {
        failedNotification(
          "Niet gelukt",
          "Kan niet gekoppelde systemen nu niet ophalen"
        );
      }
    );
  };

  const updateSystem = (params, callback) => {
    fetchPost(
      "/api/update_unlinked_system",
      params,
      (data) => {
        successNotification("Opgeslagen", "Wijzigingen zijn opgeslagen");
        callback();
        fetchSystems();
      },
      (er) => {
        if (er.status === 400)
          failedNotification("Apparaat ID bestaat al", "Probeer een ander ID");
        else
          failedNotification(
            "Niet gelukt",
            "Kon wijzigingen niet opslaan, probeer het later opnieuw"
          );
      }
    );
  };

  const deleteSystem = (client_id) => {
    fetchPost("/api/delete_unlinked_system", { client_id }, (data) => {
      successNotification(
        "Succesvol verwijderd",
        `Apparaat ${client_id} is verwijderd uit lijst ontkoppelde systemen`
      );
      fetchSystems();
    });
  };

  return {
    user_info,
    installers,
    installers_dict,
    unlinked_systems,
    updateSystem,
    deleteSystem,
  };
}

export default UnlinkedSystemsData;
