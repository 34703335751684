import React, { useState, useEffect } from "react";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TableWrapper from "../../assets/components/TableWrapper";
import { CustomIconButton } from "../../assets/components/IconButtons";
import { CustomButton, CustomTable } from "../../components/custom_components";
import { UnlinkedSystemsColumns } from "./UnlinkedSystemsColumns";

function UnlinkedSystemsTable(props) {
  return (
    <>
      <CustomTable
        data={props.data}
        columns={UnlinkedSystemsColumns}
        childrenRight={
          <CustomButton
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => props.edit({})}
          >
            Nieuw
          </CustomButton>
        }
        renderProps={{
          installers_dict: props.installers_dict,
          onEdit: props.edit,
          onDelete: props.delete,
        }}
      />
    </>
  );
}

export default UnlinkedSystemsTable;

const Row = ({ item, installer, onEdit, onDelete }) => {
  return (
    <TableRow>
      <TableCell>{item.client_id}</TableCell>
      <TableCell>{installer}</TableCell>
      <TableCell>{item.street || ""}</TableCell>
      <TableCell>{item.zip || ""}</TableCell>
      <TableCell>{item.city || ""}</TableCell>
      <TableCell align="center">
        <Stack direction="row" justifyContent="space-around">
          <CustomIconButton
            type="edit"
            color="primary"
            size="small"
            variant="outliend"
            onClick={() => onEdit(item)}
          />
          <CustomIconButton
            type="delete"
            color="error"
            size="small"
            onClick={() => onDelete(item.client_id)}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
