import { Stack } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { CustomMenuButton } from "../../../../components/custom_components";

const icon = (order) => (
  <ArrowUpwardIcon
    sx={{
      fontSize: "1.1rem",
      transition: "transform 200ms",
      transform: `rotate(${order === "desc" ? "180" : "0"}deg)`,
    }}
  />
);

export const ClientColumns = [
  {
    label: "Apparaat ID",
    render: ({ row }) => (row.client_ids ?? [])?.[0]?.client_id ?? "",
  },
  {
    label: "Trace",
    render: ({ row }) => 0,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("name")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Naam
        {orderBy === "name" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.name,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("email")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        E-mail
        {orderBy === "email" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.email,
  },
  {
    label: "Telefoonnummer",
    render: ({ row }) => row.phone_number,
  },
  {
    label: "Straat",
    render: ({ row }) => `${row.street} ${row.number}`,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("zip")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Postcode
        {orderBy === "zip" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.zip,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("city")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Plaats
        {orderBy === "city" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.city,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("verified")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Geverifieerd
        {orderBy === "verified" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => (row.verified ? "Ja" : "Nee"),
  },
  {
    label: "Acties",
    render: ({
      row,
      chartData,
      editSystem,
      editClient,
      deleteUser,
      showSystem,
    }) => (
      <CustomMenuButton
        label="Acties"
        options={[
          {
            label: "Systeeminformatie",
            onClick: () =>
              (row.client_ids || []).length
                ? chartData(row.client_ids[0].client_id)
                : console.log("todo"),
            disabled: row.client_ids.length === 0,
          },
          {
            label: "Gebruikersinfo",
            onClick: () =>
              (row.client_ids || []).length
                ? showSystem(row.client_ids[0].client_id)
                : console.log("todo"),
            disabled: row.client_ids.length === 0,
          },
          {
            label: "Systeem wijzigen",
            onClick: () =>
              editSystem(
                row.client_ids[0],
                `${row.zip} - ${row.number}`,
                row.id
              ),
            disabled: row.client_ids.length === 0,
          },
          {
            label: "Gebruiker wijzigen",
            onClick: () => editClient(row),
          },
          {
            label: "Gebruiker verwijderen",
            onClick: () => deleteUser(row.id),
          },
        ]}
      />
    ),
  },
];
