import { createContext, useContext, useState } from "react";
import ConfirmDialog from "../assets/components/ConfirmDialog";

const ConfirmationContext = createContext();

export function useConfirmation() {
  return useContext(ConfirmationContext);
}

export function ConfirmationProvider({ children }) {
  const [confirmation, setConfirmation] = useState({ open: false });

  const confirm = ({
    text = "",
    callback = null,
    extraCallback = null,
    extraCallbackText = "",
    confirmText,
    title,
    cancelText,
    maxWidth = "xs",
  }) => {
    if (text === "" || callback === null) return;
    setConfirmation({
      open: true,
      text,
      callback,
      extraCallback,
      extraCallbackText,
      confirmText,
      cancelText,
      title,
      maxWidth,
    });
  };

  return (
    <ConfirmationContext.Provider value={confirm}>
      {confirmation.open && (
        <ConfirmDialog
          dial_open={confirmation.open}
          text={confirmation.text}
          onConfirm={() => confirmation.callback()}
          title={confirmation.title}
          confirmText={confirmation.confirmText}
          extraCallback={
            confirmation.extraCallback === null
              ? null
              : (cb) => confirmation.extraCallback() & cb()
          }
          extraCallbackText={confirmation.extraCallbackText}
          cancelText={confirmation.cancelText}
          close={() => setConfirmation({ open: false })}
          maxWidth={confirmation.maxWidth}
        >
          {confirmation.text}
        </ConfirmDialog>
      )}
      {children}
    </ConfirmationContext.Provider>
  );
}
