import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { get_session, store_session } from "../../../../toolbox/local_storage";
import { CustomIconButton } from "../../../../assets/components/IconButtons";
import {
  CustomButton,
  CustomDropdown,
  CustomMenuButton,
  CustomTable,
} from "../../../../components/custom_components";
import { ClientColumns } from "./ClientColumns";

function filter(data, search) {
  if (search === "") return data;
  return data.filter((dp) =>
    (
      ((dp.street || "") !== null ? dp.street || "" : "") +
      ((dp.number || "") !== null ? dp.number || "" : "") +
      ("name" in dp ? dp.name || "" : "") +
      ((dp.zip || "") !== null ? dp.zip || "" : "") +
      ((dp.city || "") !== null ? dp.city || "" : "")
    )
      .toLowerCase()
      .includes(search.replace(/\s/g, "").toLowerCase())
  );
}

function findInstaller(installers, id) {
  for (var i = 0; i < installers.length; i++)
    if (installers[i].id === id) return installers[i].name;
  return "Geen installateur";
}

function descendingComparator(a, b, orderBy, order) {
  if (b[orderBy] === "-") return order === "asc" ? 1 : -1;
  if (a[orderBy] === "-") return order === "asc" ? -1 : 1;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ClientsTable(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [open_ids_set, setOpenIdsSet] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("zip");

  const [open_ids, setOpenIds] = useState({});

  useEffect(() => {
    if (props.data.length && !open_ids_set) {
      var new_ids = {};
      props.data.map(
        (item) => (new_ids[item.id] = item.client_ids.length ? 0 : -1)
      );
      setOpenIdsSet(true);
      setOpenIds(new_ids);
    }

    var local_sort = get_session("clients_sort", {});
    if (local_sort !== null && typeof local_sort !== "undefined") {
      setOrder(local_sort.dir || "asc");
      setOrderBy(local_sort.key || "zip");
    } else {
      setOrder("asc");
      setOrderBy("zip");
    }
  }, [props.data]);

  useEffect(() => {
    if (props.reset_table) setOpenIds({});
  }, [props.reset_table]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const changeOpenIds = (id, val) => {
    setOpenIds((cur) => {
      var new_open = { ...cur };
      new_open[id] = val;
      return new_open;
    });
  };

  const sortClick = (key) => {
    if (key === orderBy && order === "asc") {
      setOrder("desc");
      store_session("clients_sort", { key: key, dir: "desc" });
    } else {
      setOrderBy(key);
      setOrder("asc");
      store_session("clients_sort", { key: key, dir: "asc" });
    }
  };

  const data = filter(
    stableSort(props.data, getComparator(order, orderBy)),
    props.search
  );

  return (
    <>
      <CustomTable
        data={data.slice(
          (page - 1) * rowsPerPage,
          (page - 1) * rowsPerPage + rowsPerPage
        )}
        columns={ClientColumns}
        page={page}
        setPage={setPage}
        childrenRight={
          <CustomButton onClick={props.newClient}>Nieuwe klant</CustomButton>
        }
        pageAmount={rowsPerPage}
        labelRenderProps={{
          sortClick,
          order,
          orderBy,
        }}
        renderProps={{
          chartData: props.chartData,
          editSystem: props.editSystem,
          editClient: props.editClient,
          deleteUser: props.deleteUser,
          showSystem: (client_id) =>
            window.open("/systeem-status#" + client_id, "_blank"),
        }}
        count={data?.length}
      />
    </>
  );
}
