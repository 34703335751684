import { Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import TabTopBar from "../../assets/components/TabTopBar";
import {
  CustomButton,
  CustomDropdown,
  CustomMenuButton,
  CustomTextField,
} from "../../components/custom_components";

function TestComponents() {
  const [selected, setSelected] = useState("");
  const [email, setEmail] = useState("");

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        padding: 1,
        height: `calc(100% - ${20}px)`,
        overflow: "auto",
        backgroundColor: "#f5f7f9",
      }}
    >
      <TabTopBar title="Test components" subtitle="Beheer klanten" />
      <CustomButton color="secondary">Button</CustomButton>

      <CustomDropdown
        options={["bla", "keokje", "slkfjsld", "sdlkfjs"].map((opt) => ({
          label: opt,
          value: opt,
        }))}
        onChange={(e) => setSelected(e.target.value)}
        value={selected}
        label="Test dropdown"
        sx={{ width: 280, marginTop: 2 }}
      />
      <CustomMenuButton
        label="Test menu button"
        sx={{ width: 280, mt: 2 }}
        onClick={(v) => console.log("clicked", v)}
        options={[
          { label: "bla", value: "bla" },
          { label: "bla", value: "bla1" },
          { label: "bla", value: "bla2" },
        ]}
      />

      <CustomTextField
        value={email}
        onChange={(v) => setEmail(v.target.value)}
        placeholder="naam@voorbeeld.nl"
        sx={{ width: 200, mt: 2 }}
      />
    </Container>
  );
}

export default TestComponents;
