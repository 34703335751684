import { createTheme } from "@mui/material/styles";

const textfield_styling = {
  defaultProps: {
    variant: "outlined",
  },
  styleOverrides: {
    root: {},
  },
};

/*

const button_styling = {
  variants: [
    {
      props: { variant: "contained" },
      style: {
        color: "white",
      },
    },
  ],
  defaultProps: {
    variant: "text",
  },
  styleOverrides: {
    root: {
      borderRadius: 5, // was 30, check if this looks good
      borderWidth: 2,
      fontWeight: "bold",
      textTransform: "none",
      "&:hover": {
        borderWidth: 2,
      },
    },
  },
};*/

export const light_theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#005a70",
      light: "#0082a0",
      dark: "#004152",
    },
    secondary: {
      main: "#ad3300",
      light: "#d24915",
      dark: "#982c00",
    },
    error: {
      main: "#a80710",
      light: "#de3730",
      dark: "#7e0007",
    },
    info: {
      main: "#009dc2",
      light: "#39b9df",
      dark: "#0082a0",
    },
    success: {
      main: "#7acbc7",
      light: "#acfefa",
      dark: "#489a96",
    },
    warning: {
      main: "#F3A738",
      dark: "#F3D34A",
    },
  },
  typography: {
    //fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    allVariants: {
      color: "#333333",
    },
    h6: {
      fontWeight: 500,
    },
    body1: {
      //fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
      color: "#132435",
    },
    body2: {
      //fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
      color: "#132435",
    },
  },
});
