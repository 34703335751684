import { InputAdornment, TextField } from "@mui/material";
import { pogona_colors } from "../../assets/themes/pogona_colors";

const acStyles = {
  "&:hover": { borderColor: "red" },
  "& .MuiSvgIcon-root": { color: pogona_colors.orange },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#e6e6e6",
    },
    "&.Mui-focused fieldset": {
      borderColor: `${pogona_colors.orange}80`,
    },
  },
};

const icons = {
  euro: "€",
};

function DashboardTextField({
  label,
  value,
  onChange,
  variant = "outlined",
  placeholder,
  disabled,
  name = "",
  multiline,
  rows,
  startIcon,
  type = "text",
  endIcon,
  fullWidth = false,
  sx = {},
}) {
  return (
    <TextField
      label={label}
      sx={{ ...acStyles, ...sx }}
      value={value}
      onChange={onChange}
      name={name}
      variant={variant}
      type={type}
      fullWidth={fullWidth}
      placeholder={placeholder}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      InputProps={{
        startAdornment: startIcon ? (
          <InputAdornment position="start">
            {icons[startIcon] ?? startIcon}
          </InputAdornment>
        ) : null,
        endAdornment: endIcon ? (
          <InputAdornment position="end">
            {icons[endIcon] ?? endIcon}
          </InputAdornment>
        ) : null,
      }}
    />
  );
}

export default DashboardTextField;
