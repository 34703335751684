import React, { useState, useEffect } from "react";
import { Box, Chip, Paper, Stack, Typography } from "@mui/material";
import PaperTitle from "../../../assets/components/PaperTitle";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CustomIconButton from "../../../assets/components/CustomIconButton";
import { typeMap } from "../constants/statusMap";
import { unixToDateAndTime } from "../../../utils/utils";
import { useNavigate } from "react-router";
import CustomPaper from "../../../components/custom_components/CustomPaper";

function Notifications({
  notifications,
  muteNotification,
  deleteNotification,
}) {
  const navigate = useNavigate();

  return (
    <CustomPaper title="Notificaties" maxContentHeight={304}>
      {notifications?.length <= 0 ? (
        <Stack spacing={2} alignItems="center">
          <TaskAltIcon sx={{ fontSize: 48, color: "rgba(0,0,0,.26)" }} />
          <Typography variant="h5" fontWeight={"800"} color="rgba(0,0,0,.26)">
            Geen notificaties!
          </Typography>
        </Stack>
      ) : (
        <Stack spacing={2}>
          {notifications.map((noti, i) => (
            <Notification
              key={i}
              id={noti?._id}
              type={noti?.type}
              value={noti?.value}
              message={noti?.message}
              client_id={noti?.client_id}
              timestamp={noti?.timestamp}
              goToSystem={() => navigate(`/admin/systemen/${noti?.client_id}`)}
              muteNotification={muteNotification}
              deleteNotification={deleteNotification}
            />
          ))}
        </Stack>
      )}
    </CustomPaper>
  );
}

export default Notifications;

const Notification = ({
  type,
  message,
  id,
  client_id,
  value,
  timestamp,
  muteNotification,
  deleteNotification,
  goToSystem,
}) => (
  <Stack
    direction="row"
    alignItems={"center"}
    spacing={3}
    sx={{ width: "100%" }}
  >
    <Chip
      label={typeMap?.[type]?.["label"]}
      sx={{ width: 110 }}
      color={typeMap?.[type]?.["color"]}
      size="small"
    />
    <Typography variant="body2" sx={{ flex: 0.7 }}>
      {`${client_id}: ${message}`}
    </Typography>
    <Typography variant="body2" sx={{ flex: 0.2 }}>
      {unixToDateAndTime(timestamp)}
    </Typography>
    <Stack direction="row" alignItems={"center"} spacing={1} sx={{ flex: 0.1 }}>
      <CustomIconButton type="eyes" color="primary" onClick={goToSystem} />
      <CustomIconButton
        type="mute"
        color="info"
        onClick={() => muteNotification(client_id, value)}
      />
      <CustomIconButton
        type="delete"
        color="warning"
        onClick={() => deleteNotification(id)}
      />
    </Stack>
  </Stack>
);
