import React, { useMemo } from "react";
import { TextField } from "@mui/material";

function CustomTextField(props) {
  const styles = useMemo(() => {
    let sx = {
      ...(props.sx ?? {}),
      "& input::placeholder": {
        fontSize: ".9rem",
        color: "rgba(0,0,0,0.65)",
      },
      "&.MuiTextField-root": {
        input: {
          backgroundColor: props.backgroundColor ?? "white",
          color: "rgba(0,0,0,0.6)",
          fontSize: ".9rem",
          padding: "12px",
        },
        div: {
          backgroundColor: props.backgroundColor ?? "white",
        },
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "rgba(0,0,0,.2)",
        },
        "&:hover fieldset": {
          borderColor: "rgba(0,0,0,0.2)",
        },
        "&.Mui-focused fieldset": {
          borderWidth: 1,
          borderColor: "#39b9df66",
        },
      },
    };
    return sx;
  }, [props]);

  return <TextField {...props} sx={styles} />;
}

export default CustomTextField;
