import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CustomButton } from "../../components/custom_components";

export default function ConfirmationScreen(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.open && !open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.close(), 200);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <div className="modal-body">
          <p style={{ fontWeight: "bold", textAlign: props.textAlign }}>
            {props.children}
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton color="error" onClick={handleClose} size="small">
          Annuleren
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={() => props.confirm()}
          size="small"
        >
          {props.confirmText ?? "Opslaan"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
