import React from "react";
import {
  Box,
  Breadcrumbs,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

/**
 *
 * @param {*} title
 * @param {*} breadcrumbs List of jsons with each a label and link
 * @param {*} subtitle
 * @param {*} children
 * @param {*} tabs
 * @param {*} currentTab
 * @param {*} changeTab
 * @returns
 */

function TabTopBar(props) {
  let smallFont = ".92857rem";

  const userInfo = useSelector((state) => state.user_info);

  return (
    <>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
        width="100%"
        sx={{ my: 3, px: 2 }}
      >
        <Stack>
          <Typography
            variant="h3"
            fontSize="1.5rem"
            sx={{
              fontSize: "1.5rem !important",
              fontWeight: "600 !important",
            }}
          >
            {props.title}
          </Typography>
          {"subtitle" in props && !("breadcrumbs" in props) && (
            <Typography
              variant="h6"
              sx={{
                marginTop: "6px !important",
                fontWeight: "400 !important",
                fontSize: `${smallFont} !important`,
              }}
            >
              {props.subtitle}
            </Typography>
          )}{" "}
          {"breadcrumbs" in props && (
            <Breadcrumbs
              aria-label="breadcrumb"
              separator=">"
              sx={{ marginTop: "6px !important" }}
            >
              {props.breadcrumbs.map((item, i) => (
                <a
                  style={{ fontSize: smallFont }}
                  href={`/${userInfo?.user_type ?? ""}/${item.link}`}
                  key={i}
                >
                  {item.label}
                </a>
              ))}
              <Typography variant-="h6" sx={{ fontSize: smallFont }}>
                {props.subtitle}
              </Typography>
            </Breadcrumbs>
          )}
        </Stack>
        <Box className="flex-col-vertical-center">{props.children}</Box>
      </Stack>
      {"tabs" in props && (
        <Tabs
          value={props.currentTab}
          onChange={(o, n) => props.changeTab(n)}
          sx={{ marginTop: 1 }}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          {props.tabs.map((tab) => (
            <Tab
              key={tab.tab}
              value={tab.tab}
              label={tab.label}
              sx={{ padding: 2, fontSize: ".8rem" }}
            />
          ))}
        </Tabs>
      )}
      <Divider sx={"tabs" in props ? { marginBottom: 2 } : { my: 2 }} />
    </>
  );
}

export default TabTopBar;
