import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { refresh_all_data } from "../../../../redux/actions";
import { fetchGet, fetchPost } from "../../../../toolbox/fetchlib";
import { failedNotification } from "../../../../toolbox/notification-center";
import useAdminFunctions from "../../hooks/useAdminFunctions";

const getClients = "/api/noprefix_client_ids";
const getAllClients = "/api/all_my_client_ids";
const claimClients = "/api/claim_client_ids";
const manual_installer_link = "/api/manual_installer_link";
const manual_external_link = "/api/manual_external_link";
const unlink_manual = "/api/delete_manual_installer_link";
const unlink_manual_external = "/api/delete_manual_external_link";
const hide_ids = "/api/hide_client_ids";
const disconnect = "/api/disconnect_smartline";

export const UnassignedData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { unlink, unlinkManual, unlinkManualExternal } = useAdminFunctions();

  const data = useSelector((state) => state?.system_data ?? []);
  const installers = useSelector((state) => state?.installers?.installer ?? []);
  const installers_dict = useSelector(
    (state) => state?.installers?.installer_dict ?? []
  );
  const external = useSelector((state) => state?.external?.external ?? []);
  const external_dict = useSelector(
    (state) => state?.external?.external_dict ?? []
  );

  const [selected, setSelected] = useState([]);
  const [clients, setClients] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNoPrefix();
  }, []);

  const fetchData = () => {
    //dispatch(refresh_all_data(true));
  };

  const fetchNoPrefix = () => {
    setLoading(true);
    fetchGet(
      getClients,
      (data) => {
        data.map((el) => (el.assigned = "Niet toegewezen"));
        fetchAll(data);
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan systemen niet ophalen. Probeer het later opnieuw"
        );
      }
    );
  };

  const fetchAll = (prefix) => {
    fetchGet(
      getAllClients,
      (data) => {
        data.map((el) => (el.assigned = "Solesta"));
        setClients(prefix.concat(data));
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan systemen niet ophalen. Probeer het later opnieuw"
        );
      }
    );
  };

  const saveSystemLink = (args, callback) => {
    fetchPost(
      manual_installer_link,
      args,
      (data) => {
        callback();
        fetchData();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Koppeling mislukt, probeer later opnieuw."
        );
      }
    );
  };

  const saveExternalLink = (args, callback) => {
    fetchPost(
      manual_external_link,
      args,
      (data) => {
        callback();
        fetchData();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Koppeling mislukt, probeer later opnieuw."
        );
      }
    );
  };

  const toggleHide = (id, hide) => {
    fetchPost(
      hide_ids,
      { client_ids: { [id]: hide } },
      (data) => {
        fetchData();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Verbergen mislukt, probeer later opnieuw."
        );
      }
    );
  };

  const selectClient = (id) => {
    if (id === "all")
      setSelected(
        clients.map((cl) => {
          if (cl.assigned === "Niet toegewezen") return cl.client_id;
        })
      );
    else if (id === "none") setSelected([]);
    else {
      var index = selected.indexOf(id),
        new_selected = [...selected];
      if (index >= 0) new_selected.splice(index, 1);
      else new_selected.push(id);
      setSelected(new_selected);
    }
  };

  const updateClients = () => {
    var args = [];
    for (var i = 0; i < selected.length; i++) {
      for (var j = 0; j < clients.length; j++) {
        if (clients[j].client_id === selected[i]) {
          args.push(clients[j].client_id);
        }
      }
    }
    fetchPost(
      claimClients,
      { client_ids: args },
      (data) => {
        setSelected([]);
        fetchNoPrefix();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Claimen mislukt, probeer later opnieuw."
        );
      }
    );
  };

  const chartData = (client_id, row) => {
    navigate(`/admin/systemen/${client_id}`);
  };

  return {
    data,
    loading,
    installers,
    external,
    installers_dict,
    external_dict,
    saveExternalLink,
    saveSystemLink,
    toggleHide,
    unlink,
    unlinkManual,
    unlinkManualExternal,
    selected,
    loading,
    updateClients,
    selectClient,
    chartData,
  };
};
