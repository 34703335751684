import { Redirect } from "react-router-dom";
import * as fake from "./fakedata.js";

const onlinePrefix = "https://monitor.solesta.nl";
const offlinePrefix = "http://127.0.0.1:5050";

const getFake = (call, params) => {
  const fakes = {
    "/api/topics": fake.getTopics(),
    "/api/data": fake.getData(),
    "/api/clients": fake.getClients(),
    "/api/login": fake.login(),
    "/api/get_account_info": fake.getAccountInfo(),
    "/api/get_system_info": fake.getSystemInfo(),
    "/api/get_installers": fake.getInstallers(),
    "/api/get_externals": fake.getExternal(),
    "/api/client_account_info": fake.getClientInfo(),
    "/api/customers": fake.clients(),
    "/api/noprefix_client_ids": fake.getNoPrefix(),
    "/api/customer_account_info": fake.getCustomerAccInfo(),
    "/api/all_my_client_ids": fake.myClientIds(),
    "/api/systems_online": fake.systemsOnline(),
    "/api/get_hidden_client_ids": fake.getHidden(),
    "/api/get_manual_installer_links": fake.getManualInstaller(),
    "/api/get_notes": fake.getNotes(),
    "/api/get_unlinked_systems": fake.getUnlinked(),
    "/api/get_multiple_additional_system": [],
    "/api/get_notifications": fake.getFakeNotifications(),
  };
  if (call in fakes) return fakes[call];
  if (call.includes("/api/data")) return fakes["/api/data"];
  if (call.includes("/api/get_system_info"))
    return fakes["/api/get_system_info"];
  return {};
};

export const getPrefix = () => {
  return window.location.host.includes("localhost") ||
    window.location.host.includes("127.0.0.1")
    ? offlinePrefix
    : onlinePrefix;
};

const getJSON = (type) => {
  let base = {
    method: type,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return window.location.host.includes("127.0.0")
    ? { ...base, credentials: "include" }
    : base;
};

export const fetchGet = (call, callback, errCallback) => {
  const fullCall = getPrefix() + call;
  fetch(fullCall, {
    ...getJSON("GET"),
  }).then((response) => {
    if (response.status < 300) {
      response.json().then((data) => {
        callback(data);
      });
    } else {
      if (errCallback === null) callback({ error: true });
      else errCallback(response);
    }
  });
};

export const fetchPost = (
  call,
  params,
  callback,
  errCallback,
  offlineCallback
) => {
  const fullCall = getPrefix() + call;
  fetch(
    fullCall,
    fullCall.includes("upload_image")
      ? {
          method: "POST",
          body: params,
        }
      : {
          ...getJSON("POST"),
          body: JSON.stringify(params),
        }
  ).then((response) => {
    if (response.status < 300) {
      response.json().then((data) => {
        callback(data);
      });
    } else {
      if (errCallback === null) callback({ error: true });
      else errCallback(response);
    }
  });
};
