import { useNavigate } from "react-router";
import ClientsNewModel from "./services/clientsNewModel";
import { store_session } from "../../toolbox/local_storage";
import { Container, FormControlLabel, Stack, Switch } from "@mui/material";
import TabTopBar from "../../assets/components/TabTopBar";
import {
  CustomButton,
  CustomSelect,
  CustomTable,
  CustomTextField,
  DashboardButton,
} from "../../components/custom_components";
import { ClientsNewColumns } from "./ClientsNewColumns";
import { useSelector } from "react-redux";
import NewClientDialog from "../../pages/admin/components/clients_overview/NewClientDialog";
import { useState } from "react";
import { useConfirmation } from "../../contexts/ConfirmationContext";
import ClientModal from "../../pages/admin/components/clients_overview/ClientModal";

function ClientsNew() {
  const {
    clients,
    count,
    loading,
    searchParams,
    setSearchParams,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    createNewClient,
    hasSystem,
    setHasSystem,
    installers,
    saveEmail,
    savePw,
    updateClient,
    deleteUser,
  } = ClientsNewModel();

  const confirm = useConfirmation();

  const navigate = useNavigate();

  const [newClient, setNewClient] = useState(false);
  const [client_edit, setClientEdit] = useState({ open: false, client: {} });

  const sortClick = (key) => {
    if (key === orderBy && order === "asc") {
      setOrder("desc");
      store_session("clients_sort", { key: key, dir: "desc" });
    } else {
      setOrderBy(key);
      setOrder("asc");
      store_session("clients_sort", { key: key, dir: "asc" });
    }
  };

  const userInfo = useSelector((state) => state?.user_info ?? {});

  return (
    <>
      {newClient && (
        <NewClientDialog
          dial_open={newClient}
          onClose={() => setNewClient(false)}
          onSave={createNewClient}
        />
      )}
      {client_edit.open && (
        <ClientModal
          open={client_edit.open}
          close={() => setClientEdit({ open: false, client: {} })}
          installers={installers}
          text="Wijzig klant gegevens"
          client={client_edit.client}
          savePw={savePw}
          saveClient={updateClient}
          saveEmail={saveEmail}
        />
      )}

      <Container
        maxWidth={false}
        disableGutters
        sx={{
          minHeight: `calc(90vh - ${20}px)`,
          overflow: "auto",
          backgroundColor: "#f5f7f9",
          p: 2,
        }}
      >
        <TabTopBar title="Klanten" />
        <CustomTable
          data={clients}
          columns={ClientsNewColumns}
          loading={loading}
          count={count}
          page={page}
          height="65vh"
          setPage={setPage}
          renderProps={{
            installers_dict: {},
            order,
            setOrder,
            orderBy,
            setOrderBy,
            chartData: (client_id) =>
              navigate(
                `/${userInfo?.user_type ?? ""}/systemen-nieuw/${client_id}`
              ),
            sortClick,
            editClient: (client) => setClientEdit({ open: true, client }),
            deleteClient: (id) =>
              confirm({
                text: "Weet je zeker dat je deze gebruiker wil verwijderen?",
                callback: () => deleteUser(id),
              }),
          }}
          childrenRight={
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                control={
                  <CustomSelect
                    options={[
                      { value: "all", label: "Alle" },
                      { value: "with_system", label: "Met systemen" },
                      { value: "without_system", label: "Zonder systemen" },
                    ]}
                    value={hasSystem}
                    onChange={(e) => setHasSystem(e.target.value)}
                  />
                }
                labelPlacement="start"
                label="Toon klanten"
              />
              <CustomTextField
                value={searchParams.get("search") ?? ""}
                onChange={(e) =>
                  setSearchParams(
                    (prev) => {
                      prev.set("search", e.target.value);
                      return prev;
                    },
                    { replace: true }
                  )
                }
                placeholder="Zoeken..."
              />
              <CustomButton onClick={() => setNewClient(true)}>
                Nieuwe klant
              </CustomButton>
            </Stack>
          }
        />
      </Container>
    </>
  );
}

export default ClientsNew;
