import { Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmationScreen from "../../../../assets/components/ConfirmationScreen";
import TabTopBar from "../../../../assets/components/TabTopBar";
import { useSessionStorage } from "../../../../hooks/useStorage";
import { get_session, store_session } from "../../../../toolbox/local_storage";
import SystemEditor from "../SystemEditor";
import TableToolbar from "../TableToolbar";
import ChangePassword from "./ChangePassword";
import ClientData from "./ClientData";
import ClientModal from "./ClientModal";
import ClientsTable from "./ClientsTable";
import {
  CustomButton,
  CustomTextField,
  DashboardButton,
} from "../../../../components/custom_components";
import NewClientDialog from "./NewClientDialog";

export default function ClientOverview(props) {
  const {
    installers,
    data,
    reset_table,
    system_data,
    deleteUser,
    saveSystem,
    changePassword,
    saveEmail,
    savePw,
    updateClient,
    chartData,
    createNewClient,
  } = ClientData(props);

  const default_editor = {
    open: false,
    system: null,
    text: "",
    callback: null,
    name: "",
    user_id: null,
  };
  const default_confirm = { open: false, text: "", callback: null };

  const [modal, setModal] = useState({ open: false, client: {} });
  const [newClient, setNewClient] = useState(false);

  const [search, setSearch, removeSearch] = useSessionStorage(
    "customer_search",
    ""
  );

  const [editor, setEditor] = useState(default_editor);
  const [confirm, setConfirm] = useState(default_confirm);

  useEffect(() => {
    var local_search = get_session("search_val_clients", "");
    if (local_search !== null && typeof local_search !== "undefined")
      setSearch(local_search);
  }, []);

  return (
    <>
      {newClient && (
        <NewClientDialog
          dial_open={newClient}
          onClose={() => setNewClient(false)}
          onSave={createNewClient}
        />
      )}
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          p: 2,
          height: `calc(100% - ${20}px)`,
          overflow: "auto",
          backgroundColor: "#f5f7f9",
        }}
      >
        <TabTopBar title="Klanten" subtitle="Beheer klanten">
          <CustomTextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Zoeken...."
          />
        </TabTopBar>

        <ClientsTable
          data={data}
          search={search}
          installers={installers}
          reset_table={reset_table}
          system_data={system_data}
          newClient={() => setNewClient(true)}
          //deleteUser={deleteUser.bind(this)}
          deleteUser={(id) =>
            setConfirm({
              open: true,
              text: "Weet je zeker dat je deze gebruiker wilt verwijderen?",
              callback: () => deleteUser(id) & setConfirm(default_confirm),
            })
          }
          chartData={chartData.bind(this)}
          editSystem={(s, name, id) =>
            setEditor({
              open: true,
              user_id: id,
              system: s,
              name: name,
              text: s === null ? "Nieuw systeem koppelen" : "Systeem wijzigen",
              callback: (s) => saveSystem(s, () => setEditor(default_editor)),
            })
          }
          editClient={(c) => setModal({ open: true, client: c })}
        />
        {modal.open && (
          <ClientModal
            open={modal.open}
            close={() => setModal({ open: false, client: {} })}
            installers={installers}
            text="Wijzig klant gegevens"
            client={modal.client}
            savePw={savePw.bind(this)}
            saveEmail={saveEmail.bind(this)}
            saveClient={updateClient.bind(this)}
          />
        )}
        {editor.open && (
          <SystemEditor
            open={editor.open}
            installers={installers}
            system={editor.system}
            user_id={editor.user_id}
            name={editor.name}
            close={() => setEditor(default_editor)}
            text={editor.text}
            save={(v) => editor.callback(v)}
          />
        )}
        {confirm.open && (
          <ConfirmationScreen
            confirm={() => confirm.callback()}
            open={confirm.open}
            close={() => setConfirm(default_confirm)}
          >
            {confirm.text}
          </ConfirmationScreen>
        )}
      </Container>
    </>
  );
}
