import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import TabTopBar from "../../assets/components/TabTopBar";
import Notifications from "./components/Notifications";
import OverviewModel from "./services/overviewModel";
import LinkCard from "../../components/custom_components/LinkCard";
import { queryGet } from "../../toolbox/queryfetch";
import { useQuery } from "@tanstack/react-query";

function Overview() {
  const { notifications, statistics, muteNotification, deleteNotification } =
    OverviewModel();

  return (
    <Container disableGutters maxWidth={false} sx={{ px: 1 }}>
      <TabTopBar title="Solesta Monitor" subtitle={"Welkom! 👋"} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <LinkCard title="Aantal systemen">
                {statistics?.systems ?? 0}
              </LinkCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LinkCard title="Gemiddelde temperatuur" color="dark">
                {`${(statistics?.mean_temp ?? 0).toFixed(1)}°`}
              </LinkCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LinkCard title="Hoogste temperatuur" color="yellow">
                {`${(statistics?.max_temp ?? 0).toFixed(1)}°`}
              </LinkCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LinkCard title="Laagste temperatuur">
                {`${(statistics?.min_temp ?? 0).toFixed(1)}°`}
              </LinkCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Notifications
            notifications={notifications ?? []}
            muteNotification={muteNotification}
            deleteNotification={deleteNotification}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Overview;

function testGet() {
  return queryGet("get_systems_table", { page: 1 });
}
