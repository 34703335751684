import { Checkbox, Stack } from "@mui/material";
import {
  CustomIconButton,
  CustomMenuButton,
} from "../../../../components/custom_components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const icon = (order) => (
  <ArrowUpwardIcon
    sx={{
      fontSize: "1.1rem",
      transition: "transform 200ms",
      transform: `rotate(${order === "desc" ? "180" : "0"}deg)`,
    }}
  />
);

export const InstallerColumns = [
  {
    label: "",
    render: ({ row, selected, editClient }) => (
      <Checkbox
        size="small"
        checked={selected.includes(row?.id)}
        onClick={() => editClient(row?.id)}
      />
    ),
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("name")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Naam
        {orderBy === "name" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.name,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("contact_person")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Contactpersoon
        {orderBy === "contact_person" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.contact_person,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("email")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Email
        {orderBy === "email" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.email,
  },
  {
    label: ({ sortClick, orderBy, order }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        onClick={() => sortClick("location")}
        sx={{ "&:hover": { cursor: "pointer" } }}
      >
        Stad
        {orderBy === "location" ? icon(order) : <></>}
      </Stack>
    ),
    renderLabel: true,
    render: ({ row }) => row.location,
  },
  {
    label: "Telefoonnummer",
    render: ({ row }) => row.phone_number,
  },
  {
    label: "Acties",
    render: ({ row, editInstaller }) => (
      <CustomIconButton type="edit" onClick={() => editInstaller(row)} />
    ),
  },
];
