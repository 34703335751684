import { Stack, Typography } from "@mui/material";
import React from "react";

function CenteredText({ children }) {
  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      sx={{ width: "100%", height: "100%", position: "absolute" }}
    >
      <Typography
        variant="h5"
        fontWeight="700"
        textTransform={"uppercase"}
        color="rgba(0,0,0,.5)"
        letterSpacing={2}
      >
        {children}
      </Typography>
    </Stack>
  );
}

export default CenteredText;
