const originReducer = (state = "", action) => {
    switch(action.type) {
        case 'SET_ORIGIN':
            return action.payload;
        case 'RESET_ORIGIN':
            return "";
        default: 
            return state;
    }
}

export default originReducer;