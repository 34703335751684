import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchGet, fetchPost } from "../../toolbox/fetchlib";

import logo from "./../../assets/img/solesta-logo.png";
import {
  CustomButton,
  CustomTextField,
} from "../../components/custom_components";

const loginCall = "/api/login";
const getAccountInfo = "/api/get_account_info";

const styles = {
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    width: "98%",
    maxWidth: 300,
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "5vh",
  },
  new_acc_btn: {
    marginTop: "5vh",
  },
};

export default function Login(props) {
  const [login, setLogin] = useState(false);
  const [uname, setUname] = useState("");
  const [pword, setPword] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchGet(
      getAccountInfo,
      (data) => {
        //navigate("/")
      },
      () => {
        console.log("failed to login");
      }
    );
  }, []);

  const postLogin = () => {
    setLoading(true);

    fetchPost(
      loginCall,
      { email: uname, password: pword },
      (data) => {
        setLoading(false);
        navigate(`/`);
      },
      (data) => {
        setLoading(false);
        setError(true);
      }
    );
  };

  const newAccount = () => {
    navigate(`/nieuw-account`);
  };

  return (
    <div style={styles.root}>
      <img src={logo} style={styles.logo} alt="logo" />
      <div style={styles.fields}>
        <Typography style={{ textAlign: "center", marginBottom: 10 }}>
          {error ? "Wachtwoord incorrect" : ""}
        </Typography>
        <CustomTextField
          sx={{ marginBottom: 2 }}
          id="uname"
          placeholder="naam@voorbeeld.nl"
          type="email"
          value={uname}
          onChange={(e) => setUname(e.target.value)}
        />
        <CustomTextField
          id="pword"
          placeholder="wachtwoord"
          type="password"
          value={pword}
          onChange={(e) => setPword(e.target.value)}
          onKeyUp={(e) => {
            if (e.code === "Enter") postLogin();
          }}
          sx={{ marginBottom: 2 }}
        />

        <CustomButton
          color="warning"
          variant="contained"
          type="button"
          disabled={loading}
          style={{ width: "100%" }}
          onClick={() => postLogin()}
        >
          {loading ? (
            <CircularProgress style={{ width: 21, height: 21 }} />
          ) : (
            "Login"
          )}
        </CustomButton>
        <CustomButton
          color="warning"
          type="button"
          disabled={loading}
          outline
          style={styles.new_acc_btn}
          onClick={() => newAccount()}
        >
          Nieuw account
        </CustomButton>
        <CustomButton
          color="warning"
          type="button"
          disabled={loading}
          outline
          style={styles.new_acc_btn}
          onClick={() => navigate("/wachtwoord-vergeten")}
        >
          Wachtwoord vergeten
        </CustomButton>
      </div>
    </div>
  );
}
