import {
  Box,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { pogona_colors } from "../../assets/themes/pogona_colors";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: pogona_colors.orange,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: pogona_colors.orange,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    margin: "0 8px",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 41,
  height: 41,
  display: "flex",
  fontFamily: '"Rubik", sans-serif',
  fontWeight: "500",
  fontSize: 19,
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  color: pogona_colors.light_grey,
  transition: "all 150ms ease-in-out",
  border: "2px solid",
  "&:hover": {
    cursor: "pointer",
  },
  ...(ownerState.active && {
    color: pogona_colors.orange,
    borderColor: pogona_colors.orange,
  }),
  ...(ownerState.completed && {
    backgroundImage: "url('/icons/pogona_check_white.svg')",
    color: "rgba(0,0,0,0)",
    backgroundColor: pogona_colors.orange,
    backgroundSize: "cover",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {props.icon < 10 ? `0${props.icon}` : props.icon}
    </ColorlibStepIconRoot>
  );
}

function CustomStepper({
  steps,
  activeStep,
  handleStep,
  sx,
  allowFutureClicks = false,
}) {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<ColorlibConnector />}
      sx={{ ...sx }}
    >
      {steps.map((label, index) => {
        return (
          <Step
            key={label}
            sx={{
              "& .MuiStepLabel-label": {
                fontWeight: "700",
                textAlign: "center",
                color: pogona_colors.light_grey,
                "&.Mui-completed, &.Mui-active": {
                  color: "#000",
                },
              },
            }}
          >
            <StepButton
              onClick={() => handleStep(index)}
              disabled={allowFutureClicks ? false : index >= activeStep}
              sx={{ borderRadius: 10, "&:hover": { cursor: "pointer" } }}
            >
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                sx={{
                  fontFamily: '"Raleway", sans-serif',
                  fontWeight: "800",
                  textTransform: "uppercase",
                  maxWidth: 90,
                }}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default CustomStepper;
