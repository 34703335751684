import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { jsonEmpty } from "../../../toolbox/utils";
import CustomSelect from "../../../assets/components/CustomSelect";
import {
  CustomButton,
  CustomDropdown,
  CustomTextField,
} from "../../../components/custom_components";

const styles = {
  creds_fields: {
    width: "100%",
    maxWidth: 300,
    margin: "30px auto 0",
  },
  system_name: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  name: {
    width: 150,
  },
  tn_fields: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tn_1: {
    width: 65,
  },
  tn_2: {
    width: 60,
  },
  tn_3: {
    width: 90,
  },
  b2: {
    fontSize: "13px !important",
  },
};

export default function SystemEditor(props) {
  const [open, setOpen] = useState(false);

  const [existing, setExisting] = useState(false);
  const [systemName, setSystemName] = useState("");

  const [error, setError] = useState(false);
  const [client_id, setClientId] = useState("");
  const [installer, setInstaller] = useState("");
  const [tn_1_col, setTn1Col] = useState("");
  const [tn_2_col, setTn2Col] = useState("");
  const [tn_3_col, setTn3Col] = useState("");
  const [tn_1_op, setTn1Op] = useState("");
  const [tn_2_op, setTn2Op] = useState("");
  const [tn_3_op, setTn3Op] = useState("");
  const [tn_1_pomp, setTn1Pomp] = useState("");
  const [tn_2_pomp, setTn2Pomp] = useState("");
  const [tn_3_pomp, setTn3Pomp] = useState("");

  useEffect(() => {
    var s = props.system;
    if (typeof s !== "undefined" && s !== null && !jsonEmpty(s) && !existing) {
      setClientId(s.client_id ?? "");
      setSystemName(s.system_name ?? "");
      setInstaller(s.installer ?? "");
      if ("trace_number" in s) {
        var col = (s.trace_number.collector || "").split(/[/\-_]/);
        var op = (s.trace_number.opslag || "").split(/[/\-_]/);
        var pomp = (s.trace_number.pomp || "").split(/[/\-_]/);
        if (col.length === 3) {
          setTn1Col(col[0]);
          setTn2Col(col[1]);
          setTn3Col(col[2]);
        }
        if (op.length === 3) {
          setTn1Op(op[0]);
          setTn2Op(op[1]);
          setTn3Op(op[2]);
        }
        if (pomp.length === 3) {
          setTn1Pomp(pomp[0]);
          setTn2Pomp(pomp[1]);
          setTn3Pomp(pomp[2]);
        }
      }

      setExisting(true);
    }
  }, [props.system]);

  const save = () => {
    if (client_id === "" || installer === "") setError(true);
    else {
      var system = {
        name: systemName,
        client_id: client_id.includes("SOL_")
          ? client_id.replace(/\s/g, "")
          : "SOL_" + client_id.replace(/\s/g, ""),
        installer: installer,
        trace_number: {
          collector:
            tn_1_col !== "" && tn_2_col !== "" && tn_3_col !== ""
              ? `${tn_1_col}/${tn_2_col}-${tn_3_col}`
              : "",
          opslag:
            tn_1_op !== "" && tn_2_op !== "" && tn_3_op !== ""
              ? `${tn_1_op}/${tn_2_op}-${tn_3_op}`
              : "",
          pomp:
            tn_1_pomp !== "" && tn_2_pomp !== "" && tn_3_pomp !== ""
              ? `${tn_1_pomp}/${tn_2_pomp}-${tn_3_pomp}`
              : "",
        },
      };
      if (props.user_id !== null) system.id = props.user_id;
      props.save(system);
    }
  };

  useEffect(() => {
    if (props.open && !open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.handleClose(), 200);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Systeem aanpassen</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ maxWidth: 300, margin: "0 auto" }}>
          {props.installers.length > 0 && (
            <CustomDropdown
              options={props.installers.map((inst) => {
                return { label: inst.name, value: inst.id };
              })}
              onChange={(v) => setInstaller(v.target.value)}
              value={installer}
              color="primary"
              style={{ width: "100%", marginBottom: 20 }}
              label="Installateur"
            />
          )}
          <CustomTextField
            id="client_id"
            placeholder="Apparaat ID"
            disabled={props.lock_id}
            value={client_id}
            className={error && client_id === "" ? "is-invalid" : ""}
            onChange={(e) => setClientId(e.target.value)}
          />
          <CustomTextField
            id="client_id"
            placeholder="Systeemnaam"
            value={systemName}
            className={error && client_id === "" ? "is-invalid" : ""}
            onChange={(e) => setSystemName(e.target.value)}
          />
          <Typography variant="body2" className={`b2`} style={styles.b2}>
            Collector (optioneel)
          </Typography>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <CustomTextField
              placeholder="AAA"
              value={tn_1_col}
              style={styles.tn_1}
              onChange={(e) => setTn1Col(e.target.value)}
            />
            /
            <CustomTextField
              placeholder="00"
              value={tn_2_col}
              style={styles.tn_2}
              onChange={(e) => setTn2Col(e.target.value)}
            />
            -
            <CustomTextField
              placeholder="000000"
              value={tn_3_col}
              style={styles.tn_3}
              onChange={(e) => setTn3Col(e.target.value)}
            />
          </Stack>
          <Typography variant="body2" className={`b2`} style={styles.b2}>
            Opslagmodule (optioneel)
          </Typography>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <CustomTextField
              placeholder="AAA"
              value={tn_1_op}
              style={styles.tn_1}
              onChange={(e) => setTn1Op(e.target.value)}
            />
            /
            <CustomTextField
              placeholder="00"
              value={tn_2_op}
              style={styles.tn_2}
              onChange={(e) => setTn2Op(e.target.value)}
            />
            -
            <CustomTextField
              placeholder="000000"
              value={tn_3_op}
              style={styles.tn_3}
              onChange={(e) => setTn3Op(e.target.value)}
            />
          </Stack>
          <Typography variant="body2" className={`b2`} style={styles.b2}>
            Pompmodule (optioneel)
          </Typography>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <CustomTextField
              placeholder="AAA"
              value={tn_1_pomp}
              style={styles.tn_1}
              onChange={(e) => setTn1Pomp(e.target.value)}
            />
            /
            <CustomTextField
              placeholder="00"
              value={tn_2_pomp}
              style={styles.tn_2}
              onChange={(e) => setTn2Pomp(e.target.value)}
            />
            -
            <CustomTextField
              placeholder="000000"
              value={tn_3_pomp}
              style={styles.tn_3}
              onChange={(e) => setTn3Pomp(e.target.value)}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <CustomButton
          color="error"
          style={{ width: 130 }}
          onClick={handleClose}
          size="small"
        >
          Annuleren
        </CustomButton>
        <CustomButton
          color="primary"
          style={{ width: 130 }}
          onClick={() => save()}
          size="small"
        >
          Opslaan
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
