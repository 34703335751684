import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_external } from "../../../../redux/actions";
import { fetchGet, fetchPost } from "../../../../toolbox/fetchlib";
import { failedNotification } from "../../../../toolbox/notification-center";

const postNewExternal = "/api/add_external";
const postDelete = "/api/delete_users";
const edit_external = "/api/edit_external";
const get_externals = "/api/get_externals";

export const ExternalData = () => {
  const external = useSelector((state) => state?.external?.external ?? []);

  const dispatch = useDispatch();

  const newExternal = (args, callback, error_callback) => {
    fetchPost(
      postNewExternal,
      args,
      (data) => {
        callback();
      },
      (er) => {
        alert(
          "Kan de externe partij niet toevoegen. Weet u zeker dat de gegevens correct zijn?"
        );
      }
    );
  };

  const deleteExternal = (targets, callback) => {
    fetchPost(
      postDelete,
      { ids: targets },
      (data) => {
        callback();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan woningcorporatie niet verwijderen. Probeer het later opnieuw."
        );
      }
    );
  };

  const editExternal = (creds, callback) => {
    fetchPost(
      edit_external,
      creds,
      (data) => {
        callback();
      },
      (er) => {
        if (er.status === 450)
          failedNotification(
            "Niet gelukt.",
            "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer."
          );
        else
          failedNotification(
            "Niet gelukt.",
            "Kan woningcorporatie niet aanpassen. Probeer het later opnieuw."
          );
      }
    );
  };

  const fetchExternal = () => {
    fetchGet(
      get_externals,
      (data) => {
        let new_dict = {};
        data.externals.map((inst) => {
          new_dict[inst.id] = {
            email: inst.email,
            name: inst.name,
            location: inst.location,
            phone_number: inst.phone_number,
            contact_person: inst.contact_person || "",
            rol: inst.rol,
          };
        });
        dispatch(
          set_external({
            external: data.externals,
            external_dict: new_dict,
          })
        );
      },
      (er) => {}
    );
  };

  return {
    external,

    newExternal,
    deleteExternal,
    editExternal,
    fetchExternal,
  };
};
