import { useState, useEffect, useMemo } from "react";

function useDebounce(searchVal, delay) {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchTerm(searchVal);
    }, delay ?? 700);
    if (searchVal === "") {
      clearTimeout(delayDebounceFn);
      setSearchTerm("");
    }

    return () => clearTimeout(delayDebounceFn);
  }, [searchVal]);

  const loading = useMemo(() => {
    return searchTerm !== searchVal;
  }, [searchVal, searchTerm]);

  return [searchTerm, loading];
}

export default useDebounce;
