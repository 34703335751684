export const typeMap = {
  danger: {
    color: "secondary",
    label: "Kritiek",
  },
  warning: {
    color: "warning",
    label: "Waarschuwing",
  },
  info: {
    color: "info",
    label: "Ter info",
  },
};
