import React, { useState, useEffect } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Outlet, useLocation } from "react-router";
import logo from "../assets/img/solesta-logo-white.png";
import { Link } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import "../assets/components/style/ContainerStyles.scss";
import GlobalDataHandler from "../services/global_data_handler";
import CustomIconButton from "../assets/components/CustomIconButton";
import ConfirmationScreen from "../assets/components/ConfirmationScreen";
import { useSelector } from "react-redux";

const menuItems = {
  admin: [
    { label: "Overzicht", value: "" },
    /*{
      label: "Systemen",
      value: "systemen",
    },
    {
      label: "Klanten",
      value: "klanten",
    },*/
    {
      label: "Systemen (nieuw)",
      value: "systemen-nieuw",
    },
    {
      label: "Klanten (nieuw)",
      value: "klanten-nieuw",
    },
    {
      label: "Installateurs",
      value: "installateurs",
    },
    {
      label: "Extern",
      value: "extern",
    },
    {
      label: "Prestaties",
      value: "prestaties",
    },
    {
      label: "Ongekoppeld",
      value: "ongekoppeld",
    } /*
  {
    label: "Test components",
    value: "test-components",
  },*/,
  ],
  installer: [
    {
      label: "Systemen",
      value: "systemen-nieuw",
    },
    {
      label: "Klanten",
      value: "klanten-nieuw",
    },
    {
      label: "Extern",
      value: "extern",
    },
  ],
  external: [
    {
      label: "Systemen",
      value: "systemen-nieuw",
    },
    {
      label: "Klanten",
      value: "klanten-nieuw",
    },
  ],
};

function AppContainer() {
  const location = useLocation();

  const { logout } = GlobalDataHandler();

  const [confirm, setConfirm] = useState(false);
  const [barEnabled, setBarEnabled] = useState(true);
  const [hamburger, setHamburger] = useState(false);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const userInfo = useSelector((state) => state.user_info);

  const drawerWidth =
    (userInfo?.user_type ?? "") !== "" &&
    !(location?.pathname ?? "").includes("systeem-status")
      ? 240
      : 0;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ backgroundColor: "#002f3c", height: "100%" }}>
      <Toolbar
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img src={logo} alt="" style={{ width: 150 }} />
      </Toolbar>
      <Divider />
      <List sx={{ px: 2 }}>
        {(menuItems?.[userInfo.user_type] ?? []).map((item, index) => (
          <ListItem
            key={item.value}
            disablePadding
            component="a"
            sx={{
              color: "white",
              my: 1,
              borderRadius: 2,
              transition: "all .2s ease-in-out",
              "&:hover": { backgroundColor: "rgba(255,255,255,.2)" },
            }}
            href={`/${userInfo.user_type}${
              item.value === "" ? "" : `/${item.value}`
            }`}
          >
            <ListItemButton>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {userInfo.user_type === "installer" && (
        <>
          <Divider />

          <List sx={{ px: 2 }}>
            <ListItem
              disablePadding
              component="a"
              sx={{
                color: "white",
                my: 1,
                borderRadius: 2,
                transition: "all .2s ease-in-out",
                "&:hover": { backgroundColor: "rgba(255,255,255,.2)" },
              }}
              target="_blank"
              href="https://service.solesta.nl/smartline/installateurs/ "
            >
              <ListItemButton>
                <ListItemText primary="Meer informatie" />
              </ListItemButton>
            </ListItem>
          </List>
        </>
      )}
    </div>
  );

  return (
    <>
      {confirm && (
        <ConfirmationScreen
          close={() => setConfirm(false)}
          open={confirm}
          confirm={logout}
          confirmText="Ja"
        >
          Weet je zeker dat je uit wilt loggen?
        </ConfirmationScreen>
      )}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            //container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: drawerWidth > 0 ? 3 : 0,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          {drawerWidth > 0 && <Bar setMobileOpen={setMobileOpen} />}
          <Container
            maxWidth={false}
            disableGutters
            sx={{ mt: drawerWidth > 0 ? 5 : 0 }}
          >
            <Outlet />
          </Container>
        </Box>
      </Box>
    </>
  );
  return <Outlet />;

  if (location.pathname.includes("admin"))
    return (
      <>
        {confirm && (
          <ConfirmationScreen
            close={() => setConfirm(false)}
            open={confirm}
            confirm={logout}
            confirmText="Ja"
          >
            Weet je zeker dat je uit wilt loggen?
          </ConfirmationScreen>
        )}
        <Container
          sx={{
            display: "flex",
            height: "100vh",
            width: "100vw",
          }}
          maxWidth={false}
          disableGutters
        >
          <Sidebar
            backgroundColor="#002f3c"
            customBreakPoint="lg"
            width={barEnabled ? `${drawerWidth}px` : 0}
            style={{
              borderRight: "none",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: 4,
                textAlign: "center",
              }}
            >
              <img src={logo} alt="" style={{ width: 150 }} />
            </Box>
            <Menu>
              {(menuItems?.[userInfo.user_type] ?? []).map((mi) => (
                <MenuItem
                  key={mi.value}
                  className="menu-item-styles"
                  routerLink={
                    <Link
                      to={`/admin${mi.value === "" ? "" : `/${mi.value}`}`}
                      className="menu-item-link"
                    />
                  }
                >
                  {mi.label}
                </MenuItem>
              ))}
            </Menu>
          </Sidebar>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              paddingTop: 8,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Bar
              barEnabled={barEnabled}
              hamburger={hamburger}
              setBarEnabled={setBarEnabled}
              drawerWidth={drawerWidth}
              setConfirm={() => setConfirm(true)}
            />
            <Outlet />
          </Box>
        </Container>
      </>
    );
  return <Outlet />;
}

export default AppContainer;

function Bar({
  drawerWidth,
  setConfirm,
  barEnabled,
  hamburger,
  setMobileOpen,
}) {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: "white",
      }}
    >
      <Toolbar>
        <Stack
          sx={{
            display: {
              sm: "flex",
              //xs: "none",
            },
          }}
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          width={"100%"}
        >
          <CustomIconButton
            type="hamburger"
            onClick={() => setMobileOpen((old) => !old)}
          />
          <Stack direction="row" spacing={2}>
            <Stack alignItems={"end"}>
              <Typography
                variant="body1"
                color="black"
                sx={{
                  fontSize: "14px !important",
                }}
              >
                Solesta monitor
              </Typography>
              <Typography
                variant="body1"
                color="#A6ACBE"
                sx={{
                  fontSize: "12px !important",
                }}
              >
                Admin
              </Typography>
            </Stack>
            <Avatar alt="admin img" src="/biz_icon.png" />
            <Divider
              orientation="vertical"
              sx={{ backgroundColor: "#EFF4F6", height: "unset" }}
            />
            {/**
             * 
            <CustomIconButton
              type="notification"
              badge={10}
              size="medium"
              disableHover
            />
             */}

            <CustomIconButton
              type="logout"
              size="medium"
              onClick={() => setConfirm(true)}
            />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
