import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_installer } from "../../../../redux/actions";
import { fetchGet, fetchPost } from "../../../../toolbox/fetchlib";
import { failedNotification } from "../../../../toolbox/notification-center";

const postNewInstaller = "/api/add_installer";
const postDelete = "/api/delete_users";
const edit_installer = "/api/edit_installer";
const get_installers = "/api/get_installers";

export const InstallerData = () => {
  const installers = useSelector(
    (state) => state?.installers?.installers ?? []
  );

  const dispatch = useDispatch();

  const newInstaller = (args, callback, error_callback) => {
    fetchPost(
      postNewInstaller,
      args,
      (data) => {
        callback();
      },
      (er) => {
        error_callback(er.message);
      }
    );
  };

  const deleteInstallers = (targets, callback) => {
    fetchPost(
      postDelete,
      { ids: targets },
      (data) => {
        callback();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Kan installateur niet verwijderen. Probeer het later opnieuw."
        );
      }
    );
  };

  const editInstaller = (creds, callback) => {
    fetchPost(
      edit_installer,
      creds,
      (data) => {
        callback();
      },
      (er) => {
        if (er.status === 450)
          failedNotification(
            "Niet gelukt.",
            "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer."
          );
        else
          failedNotification(
            "Niet gelukt.",
            "Kan installateur niet aanpassen. Probeer het later opnieuw."
          );
      }
    );
  };

  const fetchInstallers = () => {
    fetchGet(
      get_installers,
      (data) => {
        let new_dict = {};
        data.installers.map((inst) => {
          new_dict[inst.id] = {
            email: inst.email,
            name: inst.name,
            location: inst.location,
            phone_number: inst.phone_number,
            contact_person: inst.contact_person || "",
          };
        });
        dispatch(
          set_installer({
            installers: data.installers,
            installer_dict: new_dict,
          })
        );
      },
      (er) => {}
    );
  };

  return {
    installers,
    newInstaller,
    deleteInstallers,
    editInstaller,
    fetchInstallers,
  };
};
