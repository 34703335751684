import { useEffect, useState } from "react";
import { fetchGet, fetchPost } from "../../../toolbox/fetchlib";

function OverviewModel() {
  const [initialized, setInitialized] = useState(false);
  const [statistics, setStatistics] = useState({});

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      getStatistics();
      getNotifications();
    }
  }, []);

  const getStatistics = () => {
    fetchGet(
      "/api/get_statistics",
      (data) => {
        setStatistics(data?.stats ?? {});
      },
      () => {}
    );
  };

  const getNotifications = () => {
    fetchGet(
      "/api/get_notifications",
      (data) => {
        setNotifications(data ?? []);
      },
      () => {}
    );
  };

  const muteNotification = (client_id, value) => {
    fetchPost(
      "/api/mute_system_notification",
      { client_id, value },
      (data) => {
        getNotifications();
      },
      () => {}
    );
  };

  /**
   * Delete one notification
   */
  const deleteNotification = (id) => {
    fetchPost(
      "/api/delete_notification",
      { id },
      (data) => {
        getNotifications();
      },
      () => {}
    );
  };

  return { notifications, statistics, muteNotification, deleteNotification };
}

export default OverviewModel;
