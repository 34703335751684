import React, { useState } from "react";
import Banner from "../../../assets/components/Banner";
import { CircularProgress, Typography } from "@mui/material";
import { fetchPost } from "../../../toolbox/fetchlib";
import { failedNotification } from "../../../toolbox/notification-center";
import {
  CustomButton,
  CustomTextField,
} from "../../../components/custom_components";

const post_creds = "/api/post_user_info";

const styles = {
  creds: {
    margin: "0 auto",
    width: "98%",
    maxWidth: 500,
  },
  creds_fields: {
    width: "100%",
    maxWidth: 230,
    margin: "30px auto 0",
  },
  nr: {
    width: "48%",
    marginRight: "2%",
    display: "inline-block",
  },
  add: {
    width: "48%",
    marginLeft: "2%",
    display: "inline-block",
  },
};

export default function BoilerAccount(props) {
  const [installers, setInstallers] = useState([]);
  const [error, setError] = useState(false);

  const [phone_number, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [nr, setNr] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);

  const postCreds = () => {
    if (street === "" || nr === "" || zip === "" || city === "") setError(true);
    else {
      setLoading(true);
      fetchPost(
        post_creds,
        {
          phone_number: phone_number,
          street: street,
          number: nr,
          zip: zip,
          city: city,
        },
        (data) => {
          props.dataChanged(`${zip} - ${nr}`);
        },
        (er) => {
          failedNotification(
            "Opslaan mislukt.",
            "Kan gegevens nu niet opslaan. Probeer het later opnieuw."
          );
        }
      );
    }
  };

  return (
    <div className="full-page">
      <Banner />
      <div id={"system_info"}>
        <div style={styles.creds}>
          <Typography variant="body1" className="b1">
            Voer hieronder uw gegevens in:
          </Typography>
          <div style={styles.creds_fields}>
            <CustomTextField
              sx={{ marginBottom: 2 }}
              value={phone_number}
              className={error && phone_number === "" ? "is-invalid" : ""}
              placeholder="Telefoon"
              type="text"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <CustomTextField
              sx={{ marginBottom: 2 }}
              value={street}
              className={error && street === "" ? "is-invalid" : ""}
              placeholder="Straatnaam"
              type="text"
              onChange={(e) => setStreet(e.target.value)}
            />

            <CustomTextField
              sx={{ marginBottom: 2 }}
              value={nr}
              className={error && nr === "" ? "is-invalid" : ""}
              placeholder="Huisnummer + toevoeging"
              type="text"
              onChange={(e) => setNr(e.target.value)}
            />

            <CustomTextField
              sx={{ marginBottom: 2 }}
              value={zip}
              placeholder="Postcode: 1234 AB"
              type="text"
              className={error && zip === "" ? "is-invalid" : ""}
              onChange={(e) => setZip(e.target.value)}
            />

            <CustomTextField
              sx={{ marginBottom: 2 }}
              value={city}
              placeholder="Plaats"
              className={error && city === "" ? "is-invalid" : ""}
              type="text"
              onChange={(e) => setCity(e.target.value)}
            />

            <CustomButton
              variant="contained"
              color="warning"
              type="button"
              style={{ width: "100%", marginTop: 20 }}
              onClick={() => postCreds()}
            >
              {loading ? (
                <CircularProgress style={{ width: 21, height: 21 }} />
              ) : (
                "Opslaan"
              )}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
}
