/**
 * item.client_id
installer}</
item.street
item.zip
item.city
 */

import { Stack } from "@mui/material";
import { CustomIconButton } from "../../components/custom_components";

export const UnlinkedSystemsColumns = [
  {
    label: "Apparaat ID",
    render: ({ row }) => row.client_id,
  },
  {
    label: "Installateur",
    render: ({ row, installers_dict }) =>
      installers_dict?.[row.installer_id]?.name,
  },
  {
    label: "Straat",
    render: ({ row }) => row.street,
  },
  {
    label: "Postcode",
    render: ({ row }) => row.zip,
  },
  {
    label: "Plaats",
    render: ({ row }) => row.city,
  },
  {
    label: "Acties",
    render: ({ row, onEdit, onDelete }) => (
      <Stack direction="row" spacing={1}>
        <CustomIconButton
          type="edit"
          color="primary"
          size="small"
          variant="outliend"
          onClick={() => onEdit(row)}
        />
        <CustomIconButton
          type="delete"
          color="error"
          size="small"
          onClick={() => onDelete(row.client_id)}
        />
      </Stack>
    ),
  },
];
