import React from "react";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";

const glassEffect = {
  background: "rgba(255, 255, 255, 0.2)",
  borderRadius: "16px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(5px)",
};

function LoadingOverlay({ size, text, glass, fontWeight, minHeight = 800 }) {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        minHeight: minHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10000,
        ...(glass ? glassEffect : {}),
      }}
    >
      <Stack direction="column" alignItems="center" spacing={3}>
        <CircularProgress />
        <Typography variant="h6">Laden...</Typography>
      </Stack>
    </Container>
  );
}

export default LoadingOverlay;
