import { CircularProgress } from "@mui/material";
import React from "react";

export default function Loading() {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 6,
        backgroundColor: "rgba(255,255,255,0.8)",
      }}
    >
      <CircularProgress />
    </div>
  );
}
