import { Container, FormControlLabel, Stack, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmationScreen from "../../../../assets/components/ConfirmationScreen";
import TabTopBar from "../../../../assets/components/TabTopBar";
import {
  get_local,
  get_session,
  store_local,
  store_session,
} from "../../../../toolbox/local_storage";
import TableToolbar from "../TableToolbar";
import ExternalLinker from "./ExternalLinker";
import SystemLinker from "./SystemLinker";
import { UnassignedData } from "./UnassignedData";
import UnassignedTable from "./UnassignedTable";
import { useSessionStorage } from "../../../../hooks/useStorage";
import { TextField } from "@mui/material";
import { CustomTextField } from "../../../../components/custom_components";

export default function UnassignedOverview(props) {
  const {
    data,
    loading,
    installers,
    external,
    installers_dict,
    external_dict,
    saveExternalLink,
    saveSystemLink,
    toggleHide,
    unlink,
    unlinkManual,
    unlinkManualExternal,
    selected,
    updateClients,
    selectClient,
    chartData,
  } = UnassignedData();

  const defaultSystemLink = { open: false, client_id: "" };
  const defaultExternalLink = { open: false, client_id: "" };

  const [search, setSearch, removeSearch] = useSessionStorage(
    "system_search",
    ""
  );

  const [modal, setModal] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [system_link, setSystemLink] = useState(defaultSystemLink);
  const [external_link, setExternalLink] = useState(defaultExternalLink);

  useEffect(() => {
    var local_search = get_session("search_val_systems", "");
    if (local_search !== null && typeof local_search !== "undefined")
      setSearch(local_search);
  }, []);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: `calc(100% - ${20}px)`,
        overflow: "auto",
        backgroundColor: "#f5f7f9",
        p: 2,
      }}
    >
      <TabTopBar title="Systemen" subtitle="Overzicht van alle systemen">
        <Stack direction="row" spacing={4} alignItems="baseline">
          <FormControlLabel
            control={
              <Switch
                checked={hidden}
                onChange={() => setHidden((old) => !old)}
                name="checkedB"
                color="primary"
              />
            }
            labelPlacement="start"
            label="Verborgen systemen"
          />
          <CustomTextField
            placeholder="Zoeken..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Stack>
      </TabTopBar>
      <UnassignedTable
        data={data}
        search={search}
        selected={selected}
        hidden={hidden}
        installers={installers}
        installers_dict={installers_dict}
        external={external}
        external_dict={external_dict}
        openSystemLink={(id) => setSystemLink({ open: true, client_id: id })}
        openExternalLink={(id) =>
          setExternalLink({ open: true, client_id: id })
        }
        toggleHide={toggleHide.bind(this)}
        chartData={chartData.bind(this)}
        unlink={unlink.bind(this)}
        unlinkManual={unlinkManual.bind(this)}
        unlinkManualExternal={unlinkManualExternal.bind(this)}
        selectAll={() => selectClient("all")}
        deselectAll={() => selectClient("none")}
        editClient={(id) => selectClient(id)}
      />
      {system_link.open && (
        <SystemLinker
          installers={installers}
          open={system_link.open}
          close={() => setSystemLink(defaultSystemLink)}
          save={(args) =>
            saveSystemLink(args, () => setSystemLink(defaultSystemLink))
          }
          client_id={system_link.client_id}
        />
      )}
      {external_link.open && (
        <ExternalLinker
          external={external}
          open={external_link.open}
          close={() => setExternalLink(defaultExternalLink)}
          save={(args) =>
            saveExternalLink(args, () => setExternalLink(defaultExternalLink))
          }
          client_id={external_link.client_id}
        />
      )}
      <ConfirmationScreen
        open={modal}
        close={() => setModal(false)}
        textAlign="left"
        confirm_button="Bevestig"
        confirm={() => updateClients() & setModal(false)}
      >
        Weet je zeker dat je deze klanten wilt toewijzen aan Solesta?
      </ConfirmationScreen>
    </Container>
  );
}
