import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import Banner from "../../../assets/components/Banner";
import Footer from "../../../assets/components/Footer";

export default function BoilerLoading(props) {
  return (
    <div className="full-page">
      <Banner />
      <div id="system_info">
        <div>
          <CircularProgress />
          <Typography
            variant="body1"
            className="b1"
            style={{ margin: "10px 0" }}
          >
            Gegevens ophalen...
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
}
