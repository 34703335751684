import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  Paper,
  Typography,
  styled,
  Stack,
  TextField,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Container,
  CircularProgress,
  Box,
} from "@mui/material";
import { ChartData } from "./ChartData";
import { LineChart } from "./../../../../assets/components/LineChart";
import { Toolbar } from "./Toolbar";
import ConfirmationScreen from "../../../../assets/components/ConfirmationScreen";
import { fetchPost } from "../../../../toolbox/fetchlib";
import {
  failedNotification,
  successNotification,
} from "../../../../toolbox/notification-center";
import { useParams } from "react-router";
import { jsonEmpty } from "../../../../toolbox/utils";
import SystemEditor from "../SystemEditor";
import ClientModal from "../clients_overview/ClientModal";
import ExternalLinker from "../unassigned_overview/ExternalLinker";
import SystemLinker from "../unassigned_overview/SystemLinker";
import { CustomIconButton } from "../../../../assets/components/IconButtons";
import TabTopBar from "../../../../assets/components/TabTopBar";
import {
  CustomButton,
  CustomFormComponent,
  CustomPaper,
  CustomTextField,
} from "../../../../components/custom_components";
import ConfirmDialog from "../../../../assets/components/ConfirmDialog";
import CustomSelect from "../../../../components/custom_components/CustomDropdown";
import LiveData from "./LiveData";
import ClientLinker from "../clients_overview/ClientLinker";
import { CustomDatepickerString } from "../../../../components/custom_components/CustomDatepicker";
import { unixToDate } from "../../../../utils/utils";
import { useSelector } from "react-redux";

const get_notes = "/api/get_notes";
const get_additional_info = "/api/get_additional_system_info";
const get_references = "/api/get_system_references";
const update_references = "/api/update_system_references";

const styles = {
  root: {
    flexGrow: 1,
    overflow: "scroll",
  },
  content: {
    padding: 15,
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#f5f7f9",
  },
  paper_styles: {
    padding: 15,
  },
  paper_title: {
    padding: "5px 0 15px",
    fontWeight: "bold",
  },
  divider: {
    marginBottom: 15,
    backgroundColor: "rgba(0,0,0,.1)",
  },
  note_field: {
    width: "80%",
  },
  note_btns: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  paper_header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paper_content: {
    width: "99%",
    maxWidth: 700,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 100,
  },
  central_message: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 100,
  },
  section_content: {
    display: "flex",
    width: 250,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subject_header: {
    fontSize: 14,
    fontWeight: "bold",
    display: "inline",
  },
  subject_value: {
    fontSize: 14,
    display: "inline",
  },
  btn: {
    padding: "5px 10px",
    height: 28,
    marginTop: -12,
  },
  orientation_select: {
    width: "25%",
  },
};

const defaultSystemEditor = {
  open: false,
  system: null,
  name: "",
  user_id: "",
};
const defaultExternalLink = { open: false, client_id: "" };
const defaultSystemLink = { open: false, client_id: "" };

export default function DataOverview(props) {
  const { id } = useParams();

  const { dataprops } = ChartData({ client_id: id });

  const [confirmation, setConfirmation] = useState({
    open: false,
    text: "",
    callback: null,
  });

  const userInfo = useSelector((state) => state.user_info ?? {});

  const [installer_reference, setInstallerReference] = useState("");
  const [external_reference, setExternalReference] = useState("");
  const [system_editor, setSystemEditor] = useState(defaultSystemEditor);
  const [clientLink, setClientLink] = useState({ open: false, client: {} });

  const [client_edit, setClientEdit] = useState({ open: false, client: {} });
  const [external_link, setExternalLink] = useState(defaultExternalLink);
  const [system_link, setSystemLink] = useState(defaultSystemLink);

  useEffect(() => {
    fetchPost(
      get_references,
      { client_id: id },
      (data) => {
        setInstallerReference(data.installer);
        setExternalReference(data.external);
      },
      (er) => {
        failedNotification("Niet gelukt", "Kan referenties niet ophalen");
      }
    );
  }, []);

  const saveReferences = () => {
    fetchPost(
      update_references,
      {
        client_id: id,
        installer: installer_reference,
        external: external_reference,
      },
      (data) => {
        successNotification("Opgeslagen", "Referentie is opgeslagen");
      },
      (er) => {
        failedNotification("Niet gelukt", "Kan referentie niet opslaan");
      }
    );
  };

  return (
    <>
      {system_link.open && (
        <SystemLinker
          installers={dataprops.installers}
          open={system_link.open}
          close={() => setSystemLink(defaultSystemLink)}
          save={(args) =>
            dataprops.saveSystemLink(args, () =>
              setSystemLink(defaultSystemLink)
            )
          }
          user_id={dataprops.coreData?.address_info?.user_id ?? ""}
          client_id={system_link.client_id}
        />
      )}
      {external_link.open && (
        <ExternalLinker
          external={dataprops.external}
          open={external_link.open}
          close={() => setExternalLink(defaultExternalLink)}
          save={(args) =>
            dataprops.saveExternalLink(args, () =>
              setExternalLink(defaultExternalLink)
            )
          }
          client_id={external_link.client_id}
        />
      )}
      {system_editor.open && (
        <SystemEditor
          open={system_editor.open}
          installers={dataprops.installers}
          system={system_editor.system}
          user_id={system_editor.user_id}
          name={system_editor.name}
          lock_id={true}
          close={() => setSystemEditor(defaultSystemEditor)}
          save={(v) =>
            dataprops.saveSystem(v, () => setSystemEditor(defaultSystemEditor))
          }
        />
      )}
      {client_edit.open && (
        <ClientModal
          open={client_edit.open}
          close={() => setClientEdit({ open: false, client: {} })}
          installers={dataprops.installers}
          text="Wijzig klant gegevens"
          client={client_edit.client}
          savePw={dataprops.savePw}
          saveClient={dataprops.updateClient}
          saveEmail={dataprops.saveEmail}
        />
      )}
      {clientLink.open && (
        <ClientLinker
          open={clientLink.open}
          close={() => setClientLink({ open: false, client: {} })}
          client={clientLink.client}
          save={dataprops.linkClient}
          installers={dataprops.installers}
        />
      )}
      {confirmation.open && (
        <ConfirmDialog
          dial_open={confirmation.open}
          close={() =>
            setConfirmation({ open: false, text: "", callback: null })
          }
          onConfirm={() => confirmation.callback()}
        >
          {confirmation.text}
        </ConfirmDialog>
      )}
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          padding: 1,
          overflow: "auto",
          height: `calc(100% - ${20}px)`,
          backgroundColor: "#f5f7f9",
        }}
      >
        <Toolbar
          dates={dataprops.dates}
          setDates={(start, end) =>
            dataprops.setDates({ start: start, end: end })
          }
          client_id={id}
          userType={userInfo?.user_type ?? ""}
          updateData={dataprops.updateData.bind(this)}
        />
        {typeof dataprops.full_data !== "undefined" &&
          !dataprops.loading &&
          !jsonEmpty(dataprops.coreData) && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Systeem
                  {...props}
                  systemData={dataprops?.coreData?.system_info}
                  addressInfo={dataprops?.coreData?.address_info}
                  id={id}
                  fullLiveData={dataprops.fullLiveData}
                  userType={userInfo?.user_type ?? ""}
                  editSystem={(system, name, uid) =>
                    setSystemEditor({
                      open: true,
                      user_id: uid,
                      system: system,
                      name: name,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Klant
                  {...props}
                  addressInfo={dataprops?.coreData?.address_info}
                  id={id}
                  userType={userInfo?.user_type ?? ""}
                  linkClient={(client) =>
                    setClientLink({
                      open: true,
                      client: { ...client, client_id: id },
                    })
                  }
                  editClient={(client) => {
                    setClientEdit({ open: true, client: client });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Installateur
                  {...props}
                  {...dataprops}
                  id={id}
                  userType={userInfo?.user_type ?? ""}
                  systemData={dataprops?.coreData?.system_info}
                  addressInfo={dataprops?.coreData?.address_info}
                  installer_reference={installer_reference}
                  saveReferences={saveReferences.bind(this)}
                  unlinkManual={dataprops.unlinkManual.bind(this)}
                  setConfirmation={(v) => setConfirmation(v)}
                  setInstallerReference={setInstallerReference.bind(this)}
                  linkInstaller={(id) =>
                    setSystemLink({ open: true, client_id: id })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Extern
                  {...props}
                  external_reference={external_reference}
                  systemData={dataprops?.coreData?.system_info}
                  saveReferences={saveReferences.bind(this)}
                  setConfirmation={(v) => setConfirmation(v)}
                  unlinkManualExternal={dataprops.unlinkManualExternal.bind(
                    this
                  )}
                  setExternalReference={setExternalReference.bind(this)}
                  linkExternal={(id) =>
                    setExternalLink({ open: true, client_id: id })
                  }
                  {...dataprops}
                  id={id}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Notities {...props} {...dataprops} id={id} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LiveData data={dataprops.fullLiveData} />
              </Grid>
              {(userInfo?.user_type ?? "") === "admin" && (
                <Grid item xs={12} sm={12}>
                  <LineChart
                    title="Temperatuur Overzicht"
                    title_5="Vat boven"
                    title_2="Vat onder"
                    title_3="Collector"
                    title_1="Pomp %"
                    title_4="Extern"
                    data={dataprops.full_data["pump01pwm"]}
                    data_5={dataprops.full_data["temp02"]}
                    data_2={dataprops.full_data["temp03"]}
                    data_3={dataprops.full_data["temp01"]}
                    data_4={dataprops.full_data["temp04"]}
                    loading={dataprops.loadingChart}
                    id={id}
                  />
                </Grid>
              )}
            </Grid>
          )}
        {(typeof dataprops.full_data === "undefined" ||
          dataprops.loading ||
          jsonEmpty(dataprops.coreData)) && (
          <Box
            sx={{
              width: "100%",
              height: "75vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack alignItems={"center"} spacing={4}>
              <CircularProgress />
              <Typography variant="h6">Gegevens laden...</Typography>
            </Stack>
          </Box>
        )}
      </Container>
    </>
  );
}

function Systeem(props) {
  return (
    <CustomPaper
      title="Systeem"
      minContentHeight={290}
      titleChildren={
        props?.userType === "admin" ? (
          <CustomButton
            size="small"
            onClick={() =>
              props.editSystem(
                {
                  client_id: props.systemData.client_id,
                  installer: props.systemData.installer,
                  trace_number: props.systemData.trace_number,
                },
                `${props.addressInfo.zip} - ${props.addressInfo.number}`,
                props.addressInfo.user_id
              )
            }
            disabled={(props?.addressInfo?.user_id ?? "") === ""}
            style={styles.btn}
            color="primary"
          >
            Wijzigen
          </CustomButton>
        ) : (
          <></>
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="ID">
            <Typography variant="body2">{props.id}</Typography>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Naam">
            <Typography variant="body2">
              {(props.systemData.name ?? "") === ""
                ? "Geen naam"
                : props.systemData.name}
            </Typography>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Laatst geupdated">
            <Typography variant="body2">
              {props.systemData.last_updated !== -1
                ? new Date(
                    props.systemData.last_updated * 1000
                  ).toLocaleDateString("en-UK")
                : "-"}
            </Typography>
          </CustomFormComponent>
          <CustomFormComponent label="Software versie" sx={{ pt: 2 }}>
            <Typography variant="body2">
              {(props?.fullLiveData?.max_4 ?? 0) > 0
                ? props.fullLiveData.max_4 / 100
                : "-"}
            </Typography>
          </CustomFormComponent>
        </Grid>

        {props.version >= 0 && (
          <Grid item xs={12} sm={6}>
            <CustomFormComponent label="Versie">
              <Typography variant="body2">{props.version}</Typography>
            </CustomFormComponent>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Traceernummers">
            <Stack direction="row" spacing={2}>
              <Typography
                variant="body1"
                style={styles.subject_header}
                sx={{ width: 100 }}
              >
                Collector
              </Typography>
              <Typography variant="body2">
                {props.systemData?.trace_number?.collector || "-"}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography
                variant="body1"
                style={styles.subject_header}
                sx={{ width: 100 }}
              >
                Opslag
              </Typography>
              <Typography variant="body2">
                {props.systemData?.trace_number?.opslag || "-"}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography
                variant="body1"
                style={styles.subject_header}
                sx={{ width: 100 }}
              >
                Pomp
              </Typography>
              <Typography variant="body2">
                {props.systemData?.trace_number?.pomp || "-"}
              </Typography>
            </Stack>
          </CustomFormComponent>
        </Grid>
      </Grid>
    </CustomPaper>
  );
}

function Waarschuwingen(props) {
  return (
    <Paper style={styles.paper_styles}>
      <Typography variant="h6" style={styles.paper_title}>
        Waarschuwingen
      </Typography>
      <Divider style={styles.divider} />
      <div style={styles.paper_content}>Coming soon..</div>
    </Paper>
  );
}

function Notities(props) {
  const [note_set, setNoteSet] = useState(false);
  const [notes_value, setNotesValue] = useState("");
  const [del_window, setDelWindow] = useState(false);

  const [height, setHeight] = useState("");
  const [orientation, setOrientation] = useState("");
  const [registered, setRegistered] = useState("");
  const [del_window2, setDelWindow2] = useState(false);

  useEffect(() => {
    fetchPost(
      get_additional_info,
      { client_id: props.id },
      (data) => {
        setHeight(data.height);
        setOrientation(data.orientation);
        setRegistered(data.registered);
      },
      (er) => {
        failedNotification("Ophalen mislukt.", "Kan notities nu niet ophalen.");
      }
    );
  }, []);

  const saveAdditionalNotes = () => {
    props.storeAdditionalInfo(
      {
        client_id: props.id,
        height: height,
        orientation: orientation,
        registered: registered,
      },
      null
    );
  };

  const delInfo = () => {
    props.storeAdditionalInfo(
      {
        client_id: props.id,
        height: "",
        orientation: "",
        registered: "",
      },
      () => {
        setHeight("");
        setOrientation("");
        setRegistered("");
        setDelWindow(false);
      }
    );
  };

  const onHeightChange = (v) => {
    if (v === "") {
      setHeight(v);
      return;
    }
    const allowed = "1234567890,";
    if (v.includes(",")) {
      let parts = v.split(",");
      if (parts.length > 2) return;
      if (parts[0].length > 2) return;
      if (parts[1].length > 1) return;
    } else {
      if (v.length > 2) return;
    }
    let newStr = "";
    for (let i = 0; i < v.length; i++) {
      if (allowed.includes(v[i])) newStr += v[i];
    }
    setHeight(newStr);
  };

  useEffect(() => {
    fetchPost(
      get_notes,
      { client_ids: [props.id] },
      (data) => {
        if (props.id in data) setNotesValue(data[props.id]);
      },
      (er) => {
        failedNotification("Ophalen mislukt.", "Kan notities nu niet ophalen.");
      }
    );
  }, []);

  const handleUpload = (e) => {
    let uploaded = e.target.files[0];
    props.uploadPic(uploaded, () => {
      uploaded = null;
    });
  };

  const delImage = (_id) => {
    props.deleteImage(_id);
  };

  const saveNotes = () => {
    props.storeNote({ client_id: props.id, note: notes_value }, "opgeslagen");
  };

  const delNotes = () => {
    props.storeNote({ client_id: props.id, note: "" }, "verwijderd", () => {
      setNotesValue("");
      setDelWindow(false);
    });
  };

  return (
    <CustomPaper
      title="Notities & Afbeeldingen"
      titleChildren={
        <label>
          <Input
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={(e) => handleUpload(e)}
          />
          <CustomIconButton component="span" type="camera" />
        </label>
      }
    >
      {del_window && (
        <ConfirmationScreen
          confirm={() => delNotes()}
          open={del_window}
          close={() => setDelWindow(false)}
        >
          Weet je zeker dat je de notities wilt verwijderen?
        </ConfirmationScreen>
      )}
      {del_window2 && (
        <ConfirmationScreen
          confirm={() => delInfo()}
          open={del_window2}
          close={() => setDelWindow2(false)}
        >
          Weet je zeker dat je de gegevens wilt verwijderen?
        </ConfirmationScreen>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Extra systeem info">
            <Stack spacing={2} style={{ marginTop: 12 }}>
              <TextField
                id="outlined-multiline-static"
                variant="outlined"
                size="small"
                value={height ?? ""}
                onChange={(e) => onHeightChange(e.target.value)}
                label="Opvoerhoogte (m)"
              />
              <CustomSelect
                options={[
                  { value: "N", label: "Noord" },
                  { value: "NO", label: "Noordoost" },
                  { value: "NW", label: "Noordwest" },
                  { value: "Z", label: "Zuid" },
                  { value: "ZO", label: "Zuidoost" },
                  { value: "ZW", label: "Zuidwest" },
                  { value: "O", label: "Oost" },
                  { value: "W", label: "West" },
                ]}
                value={orientation ?? ""}
                label="Orientatie"
                onChange={(e) => setOrientation(e.target.value)}
              />

              <CustomDatepickerString
                size="small"
                id="outlined-multiline-static"
                variant="outlined"
                fullWidth
                value={
                  (registered ?? "") === ""
                    ? unixToDate(props?.coreData?.system_info?.coupled_stamp)
                    : registered
                }
                onChange={(e) => setRegistered(e.target.value)}
                label="Geïnstalleerd" // TODO: Datum veld van maken
              />
            </Stack>
            <Stack
              direction={"row"}
              spacing={3}
              alignItems={"center"}
              style={{ marginTop: 24 }}
            >
              <CustomButton
                color="primary"
                size="small"
                onClick={() => saveAdditionalNotes()}
              >
                Opslaan
              </CustomButton>
              <CustomButton
                size="small"
                onClick={() => setDelWindow2(true)}
                color="error"
              >
                Verwijderen
              </CustomButton>
            </Stack>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Afbeeldingen">
            {(props?.systemImages ?? []).length === 0 ? (
              <Typography variant="body2">Geen afbeeldingen</Typography>
            ) : (
              <Box sx={{ height: 100 }}>
                {(props?.systemImages ?? []).map((img, i) => (
                  <Stack direction="row" spacing={1}>
                    <a
                      key={i}
                      target="_blank"
                      href={`https://monitor.solesta.nl/api/get_image?image_id=${img._id}`}
                    >
                      {`Foto ${i + 1}: ${img.image_name}`}
                    </a>
                    <CustomIconButton
                      type="delete"
                      onClick={() => delImage(img._id)}
                    />
                  </Stack>
                ))}
              </Box>
            )}
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12}>
          <CustomFormComponent label="Notities">
            <TextField
              id="outlined-multiline-static"
              variant="outlined"
              multiline
              value={notes_value}
              onChange={(e) => setNotesValue(e.target.value)}
              minRows={5}
            />
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" alignItems="center" gap={2} marginTop={2}>
            <CustomButton
              color="primary"
              size="small"
              onClick={() => saveNotes()}
              variant="contained"
            >
              Opslaan
            </CustomButton>
            <CustomButton
              size="small"
              onClick={() => setDelWindow(true)}
              variant="contained"
              color="error"
            >
              Verwijderen
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>
    </CustomPaper>
  );
}

function Klant(props) {
  return (
    <CustomPaper
      title="Klant"
      titleChildren={
        (props?.userType ?? "") === "admin" ? (
          !jsonEmpty(props?.addressInfo ?? {}) ? (
            <CustomButton
              size="small"
              style={styles.btn}
              onClick={() =>
                props.editClient({ ...props.addressInfo, ...props.systemData })
              }
              color="primary"
            >
              Klant aanpassen
            </CustomButton>
          ) : (
            <CustomButton
              size="small"
              style={styles.btn}
              onClick={() =>
                props.linkClient({ ...props.addressInfo, ...props.systemData })
              }
              color="primary"
            >
              Klant linken
            </CustomButton>
          )
        ) : (
          <></>
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Naam">
            <Typography variant="body2">
              {props.addressInfo.name || "-"}
            </Typography>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="E-mail">
            <Typography variant="body2">
              {props.addressInfo.email || "-"}
            </Typography>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Telefoon">
            <Typography variant="body2">
              {props.addressInfo.phone_number || "-"}
            </Typography>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Contact">
            <Typography variant="body2">
              {props.addressInfo?.allow_contact ? "Akkoord" : "Niet akkoord"}
            </Typography>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Adres">
            <Typography variant="body2">
              {props.addressInfo.address || "-"}
            </Typography>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Postcode">
            <Typography variant="body2">
              {props.addressInfo.zip || "-"}
            </Typography>
          </CustomFormComponent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormComponent label="Plaats">
            <Typography variant="body2">
              {props.addressInfo.city || "-"}
            </Typography>
          </CustomFormComponent>
        </Grid>
      </Grid>
    </CustomPaper>
  );
}

function Installateur(props) {
  console.log(props);
  return (
    <CustomPaper
      title="Installateur"
      minContentHeight={245}
      titleChildren={
        props.userType === "admin" ? (
          <>
            {!(
              (props?.systemData?.installer ?? "") !== "" &&
              props.systemData.installer in props.installers_dict
            ) && (
              <CustomButton
                size="small"
                color="primary"
                onClick={() => props.linkInstaller(props.id)}
                style={{ ...styles.btn, marginTop: -12 }}
              >
                Nieuwe koppelen
              </CustomButton>
            )}
            {(props?.systemData?.installer ?? "") !== "" &&
              props.systemData.installer in props.installers_dict && (
                <div>
                  <CustomButton
                    size="small"
                    style={{ marginTop: -12, marginRight: 15 }}
                    onClick={() => props.saveReferences()}
                  >
                    Referentie opslaan
                  </CustomButton>
                  <CustomButton
                    size="small"
                    color="error"
                    style={{ ...styles.btn, marginTop: -12 }}
                    onClick={() =>
                      props?.systemData?.manual_installer
                        ? props.setConfirmation({
                            open: true,
                            text: "Weet je zeker dat je dit systeem wil ontkoppelen?",
                            callback: () =>
                              props.unlinkManual(
                                {
                                  ...props?.systemData,
                                  ...props?.addressInfo,
                                },
                                () =>
                                  props.setConfirmation({
                                    open: false,
                                    callback: null,
                                  })
                              ),
                          })
                        : props.setConfirmation({
                            open: true,
                            text: "Weet je zeker dat je dit systeem wil ontkoppelen?",
                            callback: () =>
                              props.unlink({
                                ...props?.systemData,
                                ...props?.addressInfo,
                              }) &
                              props.setConfirmation({
                                open: false,
                                client: {},
                              }),
                          })
                    }
                  >
                    Koppeling verwijderen
                  </CustomButton>
                </div>
              )}
          </>
        ) : props.userType === "installer" || props.userType === "external" ? (
          <CustomButton
            size="small"
            style={{ marginTop: -12, marginRight: 15 }}
            onClick={() => props.saveReferences()}
          >
            Referentie opslaan
          </CustomButton>
        ) : (
          <></>
        )
      }
    >
      {(props?.systemData?.installer ?? "") !== "" &&
        props.systemData.installer in props.installers_dict && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Naam">
                <Typography variant="body2">
                  {props.installers_dict[props.systemData.installer].name}
                </Typography>
              </CustomFormComponent>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Contact">
                <Typography variant="body2">
                  {
                    props.installers_dict[props.systemData.installer]
                      .contact_person
                  }
                </Typography>
              </CustomFormComponent>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Referentie">
                <TextField
                  variant="outlined"
                  size="small"
                  value={props.installer_reference}
                  onChange={(e) => props.setInstallerReference(e.target.value)}
                />
              </CustomFormComponent>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Telefoon">
                <Typography variant="body2">
                  {
                    props.installers_dict[props.systemData.installer]
                      .phone_number
                  }
                </Typography>
              </CustomFormComponent>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Email">
                <Typography variant="body2">
                  {props.installers_dict[props.systemData.installer].email}
                </Typography>
              </CustomFormComponent>
            </Grid>
          </Grid>
        )}
      {!(
        (props?.systemData?.installer ?? "") !== "" &&
        props.systemData.installer in props.installers_dict
      ) && (
        <div style={styles.central_message}>Geen installateur gekoppeld</div>
      )}
    </CustomPaper>
  );
}

function Extern(props) {
  console.log(props);

  return (
    <CustomPaper
      title="Extern"
      minContentHeight={245}
      titleChildren={
        <>
          {!(
            (props?.systemData?.manual_external ?? "") !== "" &&
            props.systemData.manual_external in props.external_dict
          ) && (
            <CustomButton
              size="small"
              color="primary"
              onClick={() => props.linkExternal(props.id)}
              style={{ ...styles.btn, marginTop: -12 }}
            >
              Nieuwe koppelen
            </CustomButton>
          )}
          {(props?.systemData?.manual_external ?? "") !== "" &&
            props.systemData.manual_external in props.external_dict && (
              <div>
                <CustomButton
                  size="small"
                  style={{ marginTop: -12, marginRight: 15 }}
                  onClick={() => props.saveReferences()}
                >
                  Referentie opslaan
                </CustomButton>
                <CustomButton
                  size="small"
                  color="warning"
                  style={{ ...styles.btn, marginTop: -12 }}
                  onClick={() =>
                    props.setConfirmation({
                      open: true,
                      text: "Weet je zeker dat je dit systeem wil ontkoppelen?",
                      callback: () =>
                        props.unlinkManualExternal(
                          {
                            ...props?.systemData,
                            ...props?.addressInfo,
                          },
                          () =>
                            props.setConfirmation({
                              open: false,
                              callback: null,
                            })
                        ),
                    })
                  }
                >
                  Koppeling verwijderen
                </CustomButton>
              </div>
            )}
        </>
      }
    >
      {(props?.systemData?.manual_external ?? "") !== "" &&
        props.systemData.manual_external in props.external_dict && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Naam">
                <Typography variant="body2">
                  {props.external_dict[props.systemData.manual_external].name}
                </Typography>
              </CustomFormComponent>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Contact">
                <Typography variant="body2">
                  {
                    props.external_dict[props.systemData.manual_external]
                      .contact_person
                  }
                </Typography>
              </CustomFormComponent>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Referentie">
                <TextField
                  variant="outlined"
                  size="small"
                  value={props.external_reference}
                  onChange={(e) => props.setExternalReference(e.target.value)}
                  style={{ marginLeft: 20 }}
                />
              </CustomFormComponent>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Telefoon">
                <Typography variant="body2">
                  {
                    props.external_dict[props.systemData.manual_external]
                      .phone_number
                  }
                </Typography>
              </CustomFormComponent>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomFormComponent label="Email">
                <Typography variant="body2">
                  {props.external_dict[props.systemData.manual_external].email}
                </Typography>
              </CustomFormComponent>
            </Grid>
          </Grid>
        )}
      {!(
        (props?.systemData?.manual_external ?? "") !== "" &&
        props.systemData.manual_external in props.external_dict
      ) && <div style={styles.central_message}>Geen extern gekoppeld</div>}
    </CustomPaper>
  );
}

function ExtraInfo(props) {
  const [height, setHeight] = useState("");
  const [orientation, setOrientation] = useState("");
  const [registered, setRegistered] = useState("");
  const [del_window, setDelWindow] = useState(false);

  useEffect(() => {
    fetchPost(
      get_additional_info,
      { client_id: props.id },
      (data) => {
        setHeight(data.height);
        setOrientation(data.orientation);
        setRegistered(data.registered);
      },
      (er) => {
        failedNotification("Ophalen mislukt.", "Kan notities nu niet ophalen.");
      }
    );
  }, []);

  const saveNotes = () => {
    props.storeAdditionalInfo(
      {
        client_id: props.id,
        height: height,
        orientation: orientation,
        registered: registered,
      },
      null
    );
  };

  const delInfo = () => {
    props.storeAdditionalInfo(
      {
        client_id: props.id,
        height: "",
        orientation: "",
        registered: "",
      },
      () => {
        setHeight("");
        setOrientation("");
        setRegistered("");
        setDelWindow(false);
      }
    );
  };

  const onHeightChange = (v) => {
    if (v === "") {
      setHeight(v);
      return;
    }
    const allowed = "1234567890,";
    let newStr = "";
    for (let i = 0; i < v.length; i++) {
      if (allowed.includes(v[i])) newStr += v[i];
    }
    setHeight(newStr);
  };

  return (
    <CustomPaper title="Extra systeem info">
      {del_window && (
        <ConfirmationScreen
          confirm={() => delInfo()}
          open={del_window}
          close={() => setDelWindow(false)}
        >
          Weet je zeker dat je de gegevens wilt verwijderen?
        </ConfirmationScreen>
      )}

      <div
        style={{
          ...styles.paper_content,
          maxWidth: "none",
          alignItems: "center",
          height: 200,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <TextField
            id="outlined-multiline-static"
            variant="outlined"
            size="small"
            value={height ?? ""}
            onChange={(e) => onHeightChange(e.target.value)}
            rows={3}
            label="Opvoerhoogte (m)"
          />
          <CustomSelect
            options={[
              { value: "N", label: "Noord" },
              { value: "NO", label: "Noordoost" },
              { value: "NW", label: "Noordwest" },
              { value: "Z", label: "Zuid" },
              { value: "ZO", label: "Zuidoost" },
              { value: "ZW", label: "Zuidwest" },
              { value: "O", label: "Oost" },
              { value: "W", label: "West" },
            ]}
            value={orientation ?? ""}
            onChange={(e) => setOrientation(e.target.value)}
          />

          <TextField
            size="small"
            id="outlined-multiline-static"
            variant="outlined"
            value={registered ?? ""}
            onChange={(e) => setRegistered(e.target.value)}
            rows={3}
            label="Geïnstalleerd" // TODO: Datum veld van maken
          />
        </div>
        <div style={styles.note_btns}>
          <CustomButton
            color="primary"
            size="small"
            onClick={() => saveNotes()}
            style={{ marginBottom: 5 }}
          >
            Opslaan
          </CustomButton>
          <CustomButton
            size="small"
            onClick={() => setDelWindow(true)}
            style={{ marginTop: 5 }}
            color="error"
          >
            Verwijderen
          </CustomButton>
        </div>
      </div>
    </CustomPaper>
  );
}

const Input = styled("input")({
  display: "none",
});
