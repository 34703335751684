import React, { useMemo } from "react";
import { Box, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { dateToUnix, isFloat } from "../../utils/utils";
import { unixToDate } from "../../utils/utils";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { pogona_colors } from "../../assets/themes/pogona_colors";

/**
 *
 * @param {*}
 * @returns TIMESTAMP
 */

function CustomDatepicker({
  value,
  onChange,
  sx,
  disabled,
  error,
  width,
  fullWidth,
}) {
  const strToDate = () => {
    if (isFloat(value)) return value * 1000;
    if ((value ?? "") === "" || !(value instanceof moment)) {
      return value;
    }
    return value.unix() * 1000;
  };

  return (
    <Box
      sx={{
        ...sx,
        width: fullWidth ? "100%" : width ?? 150,
        display: "flex",
        margin: 0,
        px: 0,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          sx={sx}
          disabled={disabled}
          value={strToDate()}
          onChange={(v) => onChange(v === null ? "" : v.unix())}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error ?? false}
              size="small"
              fullWidth={fullWidth || width === "100%"}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default CustomDatepicker;

/**
 *
 * @param {*} param0
 * @returns DATE AS STRING
 */

export function CustomDatepickerString({
  value,
  onChange,
  sx,
  disabled,
  error,
  width,
  fullWidth,
  name,
}) {
  const strToDate = () => {
    if ((value ?? "") === "") return "";
    return dateToUnix(value) * 1000;
  };

  return (
    <Box
      sx={{
        ...sx,
        width: fullWidth ? "100%" : width ?? 160,
        display: "flex",
        margin: 0,
        px: 0,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          sx={{ p: 1, ...(sx ?? {}) }}
          disabled={disabled}
          components={{
            OpenPickerIcon: CalendarMonthIcon,
          }}
          value={strToDate()}
          onChange={(v) =>
            onChange({
              target: {
                value: v === null ? "" : unixToDate(v.unix()),
                name: name,
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ svg: { color: pogona_colors.orange } }}
              size="small"
              error={error ?? false}
              fullWidth={fullWidth || width === "100%"}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
