import React from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import CustomIconButton from "../../../../assets/components/CustomIconButton";
import { CustomTable } from "../../../../components/custom_components";
import { InstallerColumns } from "./InstallerColumns";

function filter(data, search) {
  if (search === "") return data;
  return data.filter((dp) =>
    ((dp.contact_person || "") + (dp.name || "") + (dp.location || ""))
      .toLowerCase()
      .includes(search.replace(/\s/g, "").toLowerCase())
  );
}

function descendingComparator(a, b, orderBy, order) {
  if (b[orderBy] === "-") return order === "asc" ? 1 : -1;
  if (a[orderBy] === "-") return order === "asc" ? -1 : 1;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function InstallerTable(props) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortClick = (key) => {
    if (key === orderBy && order === "asc") {
      setOrder("desc");
    } else {
      setOrderBy(key);
      setOrder("asc");
    }
  };

  const data = filter(
    stableSort(props.data, getComparator(order, orderBy)),
    props.search
  );

  return (
    <>
      <CustomTable
        data={data.slice(
          (page - 1) * rowsPerPage,
          (page - 1) * rowsPerPage + rowsPerPage
        )}
        columns={InstallerColumns}
        labelRenderProps={{
          sortClick,
          orderBy,
          order,
        }}
        renderProps={{
          editInstaller: props.editInstaller,
          selected: props.selected,
          editClient: props.editClient,
        }}
        page={page}
        setPage={setPage}
        pageAmount={rowsPerPage}
        count={data.length}
      />
    </>
  );
}
