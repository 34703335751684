export const set_user_info = (user_info) => {
  return {
    type: "SET_USER_INFO",
    payload: user_info,
  };
};

export const set_origin = (origin) => {
  return {
    type: "SET_ORIGIN",
    payload: origin,
  };
};

export const reset_origin = () => {
  return {
    type: "RESET_ORIGIN",
  };
};

export const set_cust_acc_info = (info) => {
  return {
    type: "SET_CUSTOMER_ACCOUNT_INFO",
    payload: info,
  };
};

export const set_cust_data = (info) => {
  return {
    type: "SET_CUSTOMER_DATA",
    payload: info,
  };
};

export const set_system_data = (info) => {
  return {
    type: "SET_SYSTEM_DATA",
    payload: info,
  };
};

export const set_external = (info) => {
  return {
    type: "SET_EXTERNAL",
    payload: info,
  };
};

export const set_installer = (info) => {
  return {
    type: "SET_INSTALLER",
    payload: info,
  };
};

export const set_topics = (info) => {
  return {
    type: "SET_TOPICS",
    payload: info,
  };
};

export const refresh_all_data = (info) => {
  return {
    type: "REFRESH_ALL_DATA",
    payload: info,
  };
};
