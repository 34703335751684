import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refresh_all_data } from "../../../../redux/actions";
import { fetchGet, fetchPost } from "../../../../toolbox/fetchlib";
import {
  failedNotification,
  successNotification,
} from "../../../../toolbox/notification-center";
import { jsonEmpty } from "../../../../toolbox/utils";
import useAdminFunctions from "../../hooks/useAdminFunctions";
import { useQuery } from "@tanstack/react-query";
import { queryGet } from "../../../../toolbox/queryfetch";

const dataApi = "/api/data";
const post_creds = "/api/connect_smartline";
const change_pw_external = "/api/change_password_external";
const manual_installer_link = "/api/manual_installer_link";
const manual_external_link = "/api/manual_external_link";
const store_note = "/api/store_note";
const store_additional_info = "/api/update_additional_system_info";
const changeClient = "/api/post_user_info";

const desiredTopics = ["temp01", "temp02", "temp03", "temp04", "pump01pwm"];

export const ChartData = ({ client_id }) => {
  const { unlink, unlinkManual, unlinkManualExternal } = useAdminFunctions();
  const dispatch = useDispatch();

  const installers = useSelector(
    (state) => state?.installers?.installers ?? []
  );
  const installers_dict = useSelector(
    (state) => state?.installers?.installer_dict ?? []
  );
  const external_dict = useSelector(
    (state) => state?.external?.external_dict ?? []
  );
  const external = useSelector((state) => state?.external?.external ?? []);
  const topics = useSelector((state) => state?.topics ?? []);

  const [dates, setDates] = useState({
    start: new Date().getTime() / 1000 - 604800,
    end: new Date().getTime() / 1000,
  });

  const [fullLiveData, setFullLiveData] = useState({});

  const [systemImages, setSystemImages] = useState([]);

  const {
    data: fullSysData,
    status,
    refetch,
  } = useQuery({
    queryKey: ["get_full_system_info", { client_id }],
    queryFn: () => getFullSystemInfo(client_id),
    enabled: client_id !== null && (client_id ?? "") !== "",
  });

  const data = useRef({});

  const [full_data, setFullData] = useState({});
  const [version, setVersion] = useState(-1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getImages();
    getFullLiveData();
  }, []);

  useEffect(() => {
    if (topics?.length > 0 && !loading && jsonEmpty(full_data)) {
      setLoading(true);
      updateData();
    }
  }, [topics, full_data]);

  const getFullLiveData = () => {
    fetchPost("/api/get_full_current_system", { client_id }, (data) => {
      setFullLiveData(data);
    });
  };

  const getImages = () => {
    fetchGet(
      `/api/get_system_images?client_id=${client_id}`,
      (resp) => {
        setSystemImages(resp?.images ?? []);
      },
      (er) => {
        failedNotification("Niet gelukt", "Kan afbeeldingen niet ophalen");
      }
    );
  };

  const updateData = () => {
    setLoading(true);
    fetchData([...topics]);
  };

  const saveSystem = (system, callback) => {
    fetchPost(
      post_creds,
      system,
      (data) => {
        if (data.success) {
          //dispatch(refresh_all_data(true));
          refetch();
          callback();
        } else if (data.status === 1)
          failedNotification(
            "Apparaat ID niet gevonden.",
            "Apparaat ID bestaat niet. Controleer of dit veld juist is ingevuld."
          );
        else if (data.status === 2)
          failedNotification(
            "Apparaat ID al gekoppeld.",
            "Apparaat ID al gekoppeld aan een ander apparaat. Neem contact op met Solesta."
          );
        else
          failedNotification("Koppelen mislukt.", "Probeer het later opnieuw.");
      },
      (er) => {
        failedNotification("Koppelen mislukt.", "Probeer het later opnieuw.");
      }
    );
  };

  const fetchData = (tops) => {
    var topic = tops.pop();
    if ("param" in topic) {
      fetchGet(
        dataApi +
          "?client_id=" +
          client_id +
          "&topic=" +
          encodeURIComponent(topic.param) +
          "&min_stamp=" +
          Math.round(dates.start) +
          "&max_stamp=" +
          Math.round(dates.end),
        (resp) => {
          if ("vals" in resp && resp.vals.length > 40 && resp.vals[35] > 290) {
            setVersion((resp.vals[resp.vals.length - 1] / 100).toFixed(2));
            data.current = {
              ...data.current,
              [topic.name]: { vals: [], stamps: [] },
            };
          } else data.current = { ...data.current, [topic.name]: resp };
          if (tops.length === 0) {
            var new_data = {};
            desiredTopics.map((top) => {
              if (top in data.current) new_data[top] = data.current[top];
            });
            setFullData(new_data);
            setLoading(false);
          } else fetchData(tops);
        }
      );
    }
  };

  const savePw = (pw, id) => {
    fetchPost(
      change_pw_external,
      { new_password: pw, id: id },
      (data) => {
        successNotification("Wachtwoord gewijzigd", "");
      },
      (er) => {
        if (er.status === 450)
          failedNotification(
            "Niet gelukt",
            "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer."
          );
        else failedNotification("Niet gelukt", "Probeer het later opniew.");
      }
    );
  };

  const saveExternalLink = (args, callback) => {
    fetchPost(
      manual_external_link,
      args,
      (data) => {
        callback();
        //dispatch(refresh_all_data(true));
        refetch();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Koppeling mislukt, probeer later opnieuw."
        );
      }
    );
  };

  const saveSystemLink = (args, callback) => {
    fetchPost(
      manual_installer_link,
      args,
      (data) => {
        callback();
        //dispatch(refresh_all_data(true));
        refetch();
      },
      (er) => {
        failedNotification(
          "Niet gelukt.",
          "Koppeling mislukt, probeer later opnieuw."
        );
      }
    );
  };

  const storeNote = (args, action, callback) => {
    fetchPost(
      store_note,
      args,
      (data) => {
        fetchData();
        if (typeof callback !== "undefined") callback();
        successNotification("Opgeslagen.", `Notitie is ${action}.`);
      },
      (er) => {
        failedNotification("Niet gelukt.", "Kan notitie niet opslaan.");
      }
    );
  };

  const storeAdditionalInfo = (args, callback) => {
    fetchPost(
      store_additional_info,
      args,
      (data) => {
        successNotification("Opgeslagen.", `Gegevens zijn opgeslagen.`);
        if (callback !== null) return callback();
      },
      (er) => {
        failedNotification("Niet gelukt.", "Kan gegevens niet opslaan.");
      }
    );
  };

  const updateClient = (client) => {
    fetchPost(
      changeClient,
      client,
      (data) => {
        //dispatch(refresh_all_data(true));
        refetch();
      },
      (er) => {
        failedNotification(
          "Opslaan mislukt",
          "Kan nu niet opslaan. Probeer het later opnieuw."
        );
      }
    );
  };

  const linkClient = (args, callback) => {
    fetchPost(
      "/api/connect_smartline",
      args,
      (data) => {
        //dispatch(refresh_all_data(true));
        refetch();
        callback();
      },
      (er) => {
        failedNotification(
          "Koppelen mislukt",
          "Kan nu niet koppelen. Probeer het later opnieuw."
        );
      }
    );
  };

  const saveEmail = (pw, id) => {
    fetchPost(
      "/api/change_email_external",
      { new_email: pw, id: id },
      (data) => {
        successNotification("Email gewijzigd", "");
        refetch();
      },
      (er) => {
        if (er.status === 450)
          failedNotification(
            "Niet gelukt",
            "Email adres bestaat al of is niet geldig."
          );
        else
          failedNotification(
            "Niet gelukt",
            "Email adres bestaat al of is niet geldig."
          );
      }
    );
  };

  const uploadPic = (file, callback) => {
    const data = new FormData();
    data.append("custom_image", file);
    fetchPost(
      `/api/upload_image?client_id=${client_id}&image_type=registratiekaart`,
      data,
      (resp) => {
        successNotification("Gelukt.", "Afbeelding opgeslagen");
        getImages();
      },
      (er) => {
        failedNotification("Niet gelukt", "Kon afbeelding niet opslaan");
        callback();
      }
    );
  };

  const deleteImage = (_id) => {
    fetchPost(
      "/api/delete_image",
      { image_id: _id },
      (resp) => {
        successNotification("Gelukt.", "Afbeelding verwijderd");
        getImages();
      },
      (er) => {
        failedNotification("Niet gelukt", "Kon afbeelding niet verwijderen");
      }
    );
  };

  return {
    dataprops: {
      systemImages,
      topics: { desiredTopics },
      installers,
      installers_dict,
      external,
      external_dict,
      full_data,
      version,
      loading: loading && status === "loading",
      loadingChart: loading,
      dates,
      linkClient,
      setDates,
      updateData,
      unlink,
      unlinkManual,
      unlinkManualExternal,
      saveSystem,
      savePw,
      saveExternalLink,
      saveSystemLink,
      storeNote,
      storeAdditionalInfo,
      updateClient,
      uploadPic,
      saveEmail,
      deleteImage,
      fullLiveData,
      coreData: fullSysData ?? {},
    },
  };
};

function getFullSystemInfo(client_id) {
  return queryGet("get_full_system_info", { client_id });
}
