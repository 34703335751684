export const successNotification = (title, text) => {
  console.log(title, text);
};

export const infoNotification = (title, text) => {
  console.log(title, text);
};

export const failedNotification = (title, text) => {
  console.log(title, text);
};
