const default_state = {
    user_type: "",
    name: "",
    initialized: false
};

const userInfoReducer = (state = default_state, action) => {
    switch(action.type) {
        case 'SET_USER_INFO':
            state = {...action.payload, initialized: true}
            return state;
        case 'RESET_USER_INFO':
            return default_state;
        default: 
            return state;
    }
}

export default userInfoReducer;