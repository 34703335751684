import React, { useState, useEffect, useMemo } from "react";
import TableWrapper from "../../assets/components/TableWrapper";
import {
  Stack,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  Container,
  TableSortLabel,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import { CustomIconButton } from "../../assets/components/IconButtons";
import { get_session, store_session } from "../../toolbox/local_storage";
import { getComparator, stableSort } from "../../toolbox/tablesort";
import { removeFromArray } from "../../toolbox/utils";
import { CustomTable } from "../../components/custom_components";
import { monitoringColumns } from "./MonitoringColumns";

const filterItem = (item, filters, installer) => {
  if (
    filters["Installateur"]?.length > 0 &&
    !filters["Installateur"].includes(installer)
  )
    return false;
  if (
    filters["Orientatie"]?.length > 0 &&
    !filters["Orientatie"].includes(item.orientation)
  )
    return false;
  return true;
};

function MonitoringTable(props) {
  const defaultFilters = {
    Installateur: [],
    Orientatie: [],
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState(null);
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    var local_sort = get_session("monitor_sort", {});
    if (local_sort !== null && typeof local_sort !== "undefined") {
      setOrder(local_sort.dir || "desc");
      setOrderBy(local_sort.key || "registered_stamp");
    } else {
      setOrder("desc");
      setOrderBy("registered_stamp");
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    {
      name: "Geregistreerd",
      sortable: true,
      filterable: false,
      sort_value: "registered_stamp",
    },
    { name: "Apparaat ID", sortable: false, filterable: false },
    {
      name: "Software",
      sortable: true,
      filterable: false,
      sort_value: "software",
    },
    {
      name: "Installateur",
      sortable: false,
      filterable: true,
      filter_options: props.installers.map((inst) => inst.name),
    },
    { name: "Plaats", sortable: true, filterable: false, sort_value: "city" },
    { name: "Postcode", sortable: true, filterable: false, sort_value: "zip" },
    {
      name: "Geupdated",
      sortable: true,
      filterable: false,
      sort_value: "last_updated",
    },
    {
      name: "Temperatuur",
      sortable: true,
      filterable: false,
      sort_value: "temp",
    },
    {
      name: "Orientatie",
      sortable: false,
      filterable: true,
      filter_options: ["N", "NW", "W", "ZW", "Z", "ZO", "O", "NO"],
    },
    {
      name: "Opvoerhoogte",
      sortable: true,
      filterable: false,
      sort_value: "height",
    },
  ];

  const sortClick = (key) => {
    if (key === orderBy && order === "asc") {
      setOrder("desc");
      store_session("monitor_sort", { key: key, dir: "desc" });
    } else {
      setOrderBy(key);
      setOrder("asc");
      store_session("monitor_sort", { key: key, dir: "asc" });
    }
  };

  const handleFilterChange = (key, val) => {
    setFilters((prev) => {
      return {
        ...prev,
        [key]: prev[key].includes(val)
          ? removeFromArray(prev[key], val)
          : [...prev[key], val],
      };
    });
  };

  const full_data = props.data.map((dp) => {
    return {
      ...dp,
      height: props.extra_data?.[dp.client_id]?.height,
      orientation: props.extra_data?.[dp.client_id]?.orientation,
      temp: props.extra_data?.[dp.client_id]?.temp,
      software: props.extra_data?.[dp.client_id]?.software,
    };
  });

  return (
    <>
      <CustomTable
        data={stableSort(
          full_data.filter((item) =>
            filterItem(
              item,
              filters,
              props.installers_dict?.[item.installer]?.name
            )
          ),
          getComparator(order, orderBy)
        )?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        columns={monitoringColumns}
        labelRenderProps={{
          sortClick,
          orderBy,
          order,
          installers: props.installers,
          filters,
          filterClick: handleFilterChange,
        }}
        renderProps={{
          installers_dict: props.installers_dict,
          onClick: props.chartData,
        }}
        count={props?.data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
      {/**
      <Stack direction="column" sx={{ width: "100%" }}>
        <TableWrapper
          sx={{ flex: 1 }}
          title="Monitoring prestaties"
          elements={
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setFilters(defaultFilters)}
              >
                Reset filters
              </Button>
            </>
          }
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((cell, i) => (
                  <CustomHeaderCell
                    key={i}
                    cell={cell}
                    order={order}
                    orderBy={orderBy}
                    sortClick={sortClick.bind(this)}
                    filters={filters}
                    filterClick={handleFilterChange.bind(this)}
                  />
                ))}
                <TableCell
                  align="center"
                  width={"120px"}
                  className="table-cell"
                >
                  Acties
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(
                full_data.filter((item) =>
                  filterItem(
                    item,
                    filters,
                    props.installers_dict?.[item.installer]?.name
                  )
                ),
                getComparator(order, orderBy)
              )
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, i) => (
                  <Row
                    key={i}
                    item={item}
                    installer={props.installers_dict?.[item.installer]?.name}
                    onClick={props.chartData.bind(this)}
                  />
                ))}
            </TableBody>
          </Table>
        </TableWrapper>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack> */}
    </>
  );
}

export default MonitoringTable;

const Row = ({ item, height, orientation, installer, onClick }) => {
  return (
    <TableRow>
      <TableCell>
        {new Date(item.registered_stamp * 1000).toLocaleDateString("en-UK")}
      </TableCell>
      <TableCell>{item.client_id}</TableCell>
      <TableCell>
        {item.software !== "" ? ((item.software || 0) / 100).toFixed(2) : ""}
      </TableCell>
      <TableCell>{installer}</TableCell>
      <TableCell>{item.city}</TableCell>
      <TableCell>{item.zip}</TableCell>
      <TableCell>
        {new Date(item.last_updated * 1000).toLocaleDateString("en-UK")}
      </TableCell>
      <TableCell>{item.temp !== "" ? `${item.temp || "-"}°C` : ""}</TableCell>
      <TableCell>{item.orientation}</TableCell>
      <TableCell>{item.height}</TableCell>
      <TableCell align="center" width={"120px"}>
        <CustomIconButton
          type="bar"
          size="small"
          color="primary"
          onClick={() => onClick(item.client_id)}
        />
      </TableCell>
    </TableRow>
  );
};

const CustomHeaderCell = ({
  cell,
  orderBy,
  order,
  sortClick,
  filters,
  filterClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (cell.sortable)
    return (
      <TableCell>
        <TableSortLabel
          active={orderBy === cell.sort_value}
          direction={orderBy === cell.sort_value ? order : "asc"}
          onClick={() => sortClick(cell.sort_value)}
          className="table-cell"
        >
          {cell.name}
        </TableSortLabel>
      </TableCell>
    );
  else if (cell.filterable)
    return (
      <TableCell>
        <Stack className="table-cell" direction="row" alignItems="end">
          {cell.name}
          <CustomIconButton
            type="filter"
            size="small"
            sx={{ marginLeft: "10px" }}
            onClick={handleClick}
            color={filters[cell.name]?.length > 0 ? "primary" : "default"}
          />
        </Stack>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List dense>
            {cell.filter_options.map((value, i) => (
              <ListItem key={i}>
                <ListItemButton
                  role={undefined}
                  onClick={() => filterClick(cell.name, value)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={filters[cell.name].indexOf(value) !== -1}
                      tabIndex={-1}
                      size="small"
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={value} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Popover>
      </TableCell>
    );
  return <TableCell className="table-cell">{cell.name}</TableCell>;
};
