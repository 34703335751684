/**
 client_id
 trace
 name
email
phone_number
street
number
zip
city
verified
 */

import {
  CustomIconButton,
  CustomMenuButton,
} from "../../components/custom_components";

export const ClientsNewColumns = [
  {
    label: "Acties",
    render: ({ row, chartData, editClient, deleteClient }) => (
      <CustomMenuButton
        label="Acties"
        options={[
          {
            label: "Systeeminformatie",
            onClick: () => chartData(row.client_id),
          },
          {
            label: "Klant aanpassen",
            onClick: () => editClient({ ...row, user_id: row.id }),
          },
          {
            label: "Klant verwijderen",
            onClick: () => deleteClient(row.id),
          },
        ]}
      />
    ),
  },
  {
    label: "Client ID",
    render: ({ row }) => row.client_id,
  },
  {
    label: "Trace",
    render: ({ row }) => row.trace,
  },
  {
    label: "Naam",
    render: ({ row }) => row.name,
  },
  {
    label: "E-mail",
    render: ({ row }) => row.email,
  },
  {
    label: "Telefoonnummer",
    render: ({ row }) => row.phone_number,
  },
  {
    label: "Straat",
    render: ({ row }) => row.street + " " + row.number,
  },
  {
    label: "Postcode",
    render: ({ row }) => row.zip,
  },
  {
    label: "Plaats",
    render: ({ row }) => row.city,
  },
  {
    label: "Geverifieerd",
    render: ({ row }) => (row.verified ? "Ja" : "Nee"),
  },
];
