import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import MonitoringTable from "./MonitoringTable";
import MonitoringData from "./MointoringData";
import TabTopBar from "../../assets/components/TabTopBar";
import { useNavigate } from "react-router";

function Monitoring(props) {
  const navigate = useNavigate();

  const { data, installers, installers_dict, extra_data } =
    MonitoringData(props);

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          height: `calc(100% - ${20}px)`,
          overflow: "auto",
          backgroundColor: "#f5f7f9",
          p: 2,
        }}
      >
        <TabTopBar title="Prestaties" subtitle="Monitor systemen" />
        <MonitoringTable
          data={data}
          installers={installers}
          installers_dict={installers_dict}
          extra_data={extra_data}
          chartData={(a, b) => navigate(`/admin/systemen/${a}`)}
          onClick={() => ""}
        />
      </Container>
    </>
  );
}

export default Monitoring;
